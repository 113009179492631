export default {
  /*
  ** Headers of the page
  */
  head: {
    titleTemplate: '%s',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1,maximum-scale=1.0, user-scalable=no' },
      { name: 'referrer', content: 'no-referrer' },
      { hid: 'keywords', name: 'keywords', content: 'jobs in hong kong, job search, hong kong career, hong kong employment' },
      { hid: 'description', name: 'description', content: 'Search, browse, find the top match and apply for jobs in South-east Asia. Top jobs for Accounting, Bank and Finance, Building Construction, IT, Marketing, Design and many more are available here. Search and apply online now at jobs.getlinks.com' }
    ],
    link: [
      { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,700,700i&display=swap' }
    ]
  }
}
