export const state = () => ({
  cplist: [],
  jjlist: []
})

export const mutations = {
  setCPEpaper(state, items) {
    const arr = []
    items.forEach((i, idx) => {
      i.latest = idx === 0
      const date = i.publishdate
      const year = date.split('-')[0]
      i.key = year
      if (arr[year]) {
        arr[year].push(i)
      } else {
        arr[year] = []
        arr[year].push(i)
      }
    })
    state.cplist = arr.reverse()
  },
  setJJEpaper(state, items) {
    const arr = []
    items.forEach((i, idx) => {
      i.latest = idx === 0
      const date = i.publishdate
      const year = date.split('-')[0]
      i.key = year
      if (arr[year]) {
        arr[year].push(i)
      } else {
        arr[year] = []
        arr[year].push(i)
      }
    })
    state.jjlist = arr.reverse()
  }
}

export const actions = {
  fetchCPEpaper: async function ({ commit }) {
    const { data } = await this.$axios.get('epapers/cp')
    commit('setCPEpaper', data) // commit = call mutations
  },
  fetchJJEpaper: async function ({ commit }) {
    const { data } = await this.$axios.get('epapers/jj')
    commit('setJJEpaper', data) // commit = call mutations
  }
}
