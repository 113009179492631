import Vue from 'vue'
import us from '../lang/en-US.json'
import hk from '../lang/zh-HK.json'
import th from '../lang/th.json'
import vi from '../lang/vi.json'
import cn from '../lang/cn.json'
import id from '../lang/id.json'

const mixin = {
  methods: {
    cpLang(key, template) {
      let returnVal = key
      const parseCookie = str =>
        str
          .split(';')
          .map(v => v.split('='))
          .reduce((acc, v) => {
            if (v[0] !== undefined && v[1] !== undefined) {
              acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(
                v[1].trim()
              )
            }
            return acc
          }, {})
      let revampLangVal = 'th'
      if (this.$cpCookie === undefined && Vue.prototype.$nuxt) {
        revampLangVal = Vue.prototype.$nuxt.$cpCookie.get('revampLang')
      } else if (process.browser) {
        revampLangVal = parseCookie(document.cookie).revampLang
      } else {
        revampLangVal = this.$cpCookie.get('revampLang')
      }

      const acceptLang = {
        en: 'en',
        zh: 'zh',
        th: 'th',
        vi: 'vi',
        cn: 'cn',
        id: 'id'
      }
      const file = {
        en: us,
        zh: hk,
        th: th,
        vi: vi,
        cn: cn,
        id: id
      }
      const revampLang = acceptLang[revampLangVal] || 'th'
      const langFile = file[revampLang]

      const keyArr = String(key).split('.')
      if (keyArr.length === 1) {
        // Not Array
        returnVal = langFile[key] || key
      } else {
        // Loop Array
        try {
          let dummy = langFile
          for (const idx in keyArr) {
            dummy = dummy[keyArr[idx]]
          }
          returnVal = dummy || key
        } catch (err) {
          // console.log('Lang Error :', { key, err })
        }
      }
      let result = returnVal
      if (typeof template === 'object') {
        result = this._stringTemplate(returnVal, template)
      }
      return result
    },
    _stringTemplate(template, variables) {
      return template.replace(new RegExp('{([^{]+)}', 'g'), function(
        _unused,
        varName
      ) {
        // eslint-disable-line
        return variables[varName] || `{${varName}}`
      })
    },
    toExpiryDate(timestamp) {
      timestamp = Number(String(timestamp).slice(0, 10))
      const now = Math.ceil(new Date().getTime() / 1000)
      const gap = now - timestamp
      // console.log('cpLang toExipre:', timestamp, now, gap)

      if (gap < 60 * 60) {
        const time = Math.floor(gap / 60)
        const unit =
          time > 0
            ? this.cpLang('global.mins_ago')
            : this.cpLang('global.min_ago')
        return time + ' ' + unit
      }
      if (gap < 60 * 60 * 24) {
        const time = Math.floor(gap / (60 * 60))
        const unit =
          time > 1
            ? this.cpLang('global.hours_ago')
            : this.cpLang('global.hour_ago')
        return time + ' ' + unit
      }
      if (gap < 60 * 60 * 24 * 7) {
        const time = Math.floor(gap / (60 * 60 * 24))
        const unit =
          time > 1
            ? this.cpLang('global.days_ago')
            : this.cpLang('global.day_ago')
        return time + ' ' + unit
      }
      if (gap < 60 * 60 * 24 * 9) {
        const time = 1
        const unit = this.cpLang('global.week_ago')
        return time + ' ' + unit
      }
      const date = new Date(timestamp * 1000)
      /*
       * const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
       * const month = 'recruitmentday.' + monthNames[date.getUTCMonth()].toLowerCase()
       * return date.getDate() + ' ' + this.cpLang(month)
       */
      return date.getDate() + '-' + (date.getUTCMonth() + 1) + '-' + date.getFullYear()
    }
  }
}

Vue.mixin(mixin)

export { mixin }
