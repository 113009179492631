export default function ({ redirect, app, store, route }) {
  const lang = app.$cpCookie.get('revampLang')
  const country = app.$cpCookie.get('default_country')
  const getCountryList = async () => {
    if (store.state.lang.countryList.length === 0) {
      await store.dispatch('lang/fetchCountryList')
    }
    return await store.state.lang.countryList
  }
  if (route.path !== '/selectlang') {
    app.$cpCookie.set('redirectPath', route.fullPath)
    // cookie
  }

  const redirectPath = app.$cpCookie.get('redirectPath')
  getCountryList()
    .then((countries) => {
      if (!countries.find(location => parseInt(location.id) === parseInt(country))) {
        redirect(`/selectlang?redirect=${redirectPath}`)
      }
    })
    .catch((error) => { console.error(error) })
  if (!lang || !country) {
    redirect(`/selectlang?redirect=${redirectPath}`)
  }
}
