<template>
  <div id="error-page" class="container">
    <div v-if="error.statusCode === 404" class="error-content">
      <h2>{{ cpLang("error.oops") }}</h2>
      <p>{{ cpLang("error.apologise_not_exist") }}</p>
      <p>{{ cpLang("error.moved") }}</p>
      <br>
      <div class="row">
        <div class="col col-md-4">
          {{ cpLang("error.meanwhile") }}
        </div>
        <div class="col col-md-8">
          <ul>
            <li>
              <a :href="cpHost + 'SearchJobs'">{{
                cpLang("error.search_dream")
              }}</a>
            </li>
            <!-- <li>
              <a :href="cpHost + 'career-advice'">{{
                cpLang("error.browse_article")
              }}</a>
            </li> -->
            <!-- <li>
              <a :href="cpHost + 'career-advice/career-doctors-hong-kong'">{{
                cpLang("error.see_doctor")
              }}</a>
            </li> -->
            <li>
              <a :href="cpHost">{{ cpLang("error.go_homepage") }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else class="error-content">
      <h2>
        An error occurred
      </h2>
      <p>
        We apologies for the inconvenience as we're experiencing an technical
        problem.
      </p>
      <p>Meanwhile, you may: Check the URL entered to make sure it's correct</p>
      <p><a :href="cpHost">Return to our home page</a>.</p>
      <p>Contact us at <a href="mailto:contact@getlinks.com">contact@getlinks.com</a></p>
      <hr>
      <div class="row">
        <div class="col col-md-4">
          <strong>Request URI:</strong>
        </div>
        <div class="col col-md-8">
          <a :href="url">{{ url }}</a>
        </div>
      </div>
      <div class="row">
        <div class="col col-md-4">
          <strong>Message:</strong>
        </div>
        <client-only>
          <div class="col col-md-8">
            {{ error.statusCode }} {{ error.message || "Server Error" }}
          </div>
        </client-only>
        <br>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    error: {
      type: Object,
      default: () => ({ statusCode: 999, message: 'UnknownError' })
    }
  },
  data: function() {
    return {
      url: '',
      cpHost: process.env.cp_host
    }
  },
  mounted() {
    this.url = location.href || ''
    let reqUrl = location.search.split('req=')[1] || ''
    if (location.protocol === 'http:') {
      // Handle localhost
      reqUrl = reqUrl.replace('https', 'http')
    }
    if (window.history.replaceState && reqUrl !== '') {
      // prevents browser from storing history with each change:
      try {
        window.history.replaceState('', '', decodeURIComponent(reqUrl))
      } catch (e) {
        console.error('ReplaceStateError:' + e)
      }
      this.url = decodeURIComponent(reqUrl)
      this.error.message = 'Server Error'
      this.error.statusCode = 500
    }
  },
  head() {
    return {
      title: 'Error'
    }
  }
}
</script>
<style>
#error-page {
  padding-top: 50px;
  margin: 0px auto;
  padding-bottom:50px;
}
.error-content {
  max-width: 1200px;
  background: #fff;
  margin: 0px auto;
  padding: 20px;
  text-align: center;
}
.error-content h2 {
  font-weight: 700;
  font-size: 35px;
  margin: 20px;
}
.error-content p {
  margin: 8px 0px;
}
.error-content .col-md-8 {
  text-align: left;
}
.error-content .col-md-4 {
  text-align: right;
}
@media screen and (max-width: 1000px) {
  .error-content .col-md-4,
  .error-content .col-md-8 {
    width: 100% !important;
    max-width: 100%;
    flex: none;
    text-align: left;
  }
}
</style>
