export const state = () => ({
  paging: false,
  previewing: false
})

export const mutations = {
  changeLoading(state, payload) {
    const status = payload[0]
    const key = payload[1]
    state[key] = status
  },
  changePreview(state, payload) {
    state.previewing = payload
  }
}

export const actions = {
  setLoading: function ({ commit }, payload) {
    commit('changeLoading', payload) // commit = call mutations
  },
  setPreview: function ({ commit }, payload) {
    commit('changePreview', payload) // commit = call mutations
  }
}
