import https from 'https'
export default function (ctx) {
  const isDev = process.env.NODE_ENV === 'development'
  const md5 = require('js-md5')
  const { $axios, redirect, app } = ctx
  // const rax = require('retry-axios')
  // const interceptorId = rax.attach($axios) // eslint-disable-line
  let lang = 'en-US'
  if (app.$cpCookie.get('revampLang') === 'th') {
    lang = 'TH'
  } else if (app.$cpCookie.get('revampLang') === 'vi') {
    lang = 'VI'
  } else if (app.$cpCookie.get('revampLang') === 'zh') {
    lang = 'zh-HK'
  }
  let userid = ''
  if (app.$cpCookie.get('cpid')) {
    userid = app.$cpCookie.get('cpid')
  } else {
    const req = ctx.req
    if (req !== undefined) {
      const ua = req.headers['user-agent']
      const ip = req.headers['x-forwarded-for'] || // From proxy headers, can be spoofed if you don't have a proxy in front of your app, so drop it if your app is naked.
      req.connection.remoteAddress ||
      req.socket.remoteAddress || // socket is an alias to connection, just delete this line
      req.connection.socket.remoteAddress
      userid = md5(ua + ip)
      app.$cpCookie.set('cpid', userid, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365 * 3
      })
    }
  }
  $axios.onRequest((config) => {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const yyyy = today.getFullYear()
    const key = yyyy + '-' + mm + '-' + dd + '@italent'
    const xApiKey = md5(key)

    const header = config.headers
    header['Accept-Language'] = lang
    header['x-api-key'] = xApiKey
    if (config.Authorization !== undefined) {
      // append Authorization to header from param
      header.Authorization = config.Authorization
    }
    config.baseURL = process.env.BASE_API
    if (process.client) {
      config.baseURL = process.env.BASE_API_URL
    }
    // append cookie to request
    if (config.url.includes('positions?') > -1) {
      const recentSearch = app.$cpCookie.get('recent_search') !== undefined ? encodeURIComponent(app.$cpCookie.get('recent_search')) : ''
      let ck = 'recent_search=' + recentSearch
      const bookmark = app.$cpCookie.get('bookmark') !== undefined ? encodeURIComponent(app.$cpCookie.get('bookmark')) : ''
      ck += ';' + 'bookmark=' + bookmark
      header.clientcookie = ck
    }
    if (config.url.indexOf('recommendation') > -1) {
      const notInterestingJobs = app.$cpCookie.get('notinterestingjob') !== undefined ? encodeURIComponent(app.$cpCookie.get('notinterestingjob')) : ''
      header.clientcookie = 'notinteresting=' + notInterestingJobs
    }
    if (app.$cpCookie.get('cpid')) {
      header.sessionid = app.$cpCookie.get('cpid')
    }
    // rewrite auth path;
    if (config.url.indexOf('/api/auth/') > -1) {
      const url = config.url
      config.url = url.replace('/api/', '/')
      // console.log(config.request)
      if (config.url.indexOf('/logout') > -1) {
        config.method = 'get'
      }
      // config.url = config.url.replace('/api/', '/')
    }
    // // dont verify the ssl certificate in API call
    config.httpsAgent = new https.Agent({
      rejectUnauthorized: false
    })
    config.headers = header
    // API Log
    // console.log('Making request', { to: config.url, config })
  })
  $axios.onError(function (res) {
    throwError(res.response)
  })

  const throwError = function(response) {
    if (isDev) {
      console.log('axios Error', { response })
    }
    if (response === undefined) {
      return
    }
    if (process.client) {
      if (response.status === 401) {
        // app.store.dispatch('user/logout')
      }
      if (response.status === 406) {
        throw new Error(response.data.detail)
      }
      throw new Error(response)
      // const lang = app.$cpCookie.get('i18n_redirected') // Get language
      // console.log('Client Redirect', { response, app, store })
      // app.router.push(
      //   {
      //     name: 'notFound___' + lang,
      //     params: { errorCode: '502', response: response }
      //   }
      // )
    } else {
      const url = process.server ? 'https://' + ctx.req.headers.host + ctx.req._parsedOriginalUrl.href : window.location.href
      redirect('/error?req=' + encodeURIComponent(url))
    }
  }
}
