import commonFunc from '~/assets/js/commonHelper.js'

export default async ({ req, route, $axios, store }) => {
  const endpoint = await commonFunc.getFBcvapiEndpoint()
  const userData = await commonFunc.getFBcvapiUserData(store.$auth.user, req)
  const eventData = await commonFunc.getEventData(route, userData)
  const fbapiData = { endpoint: endpoint, eventData: eventData }
  // console.log(`${new Date()} - fbcvapi`)
  // console.log(`${fbapiData.eventData} - fbcvapi`)
  await $axios.post(fbapiData.endpoint, fbapiData.eventData)
    .catch(err => console.log(err.response))
}
