<template>
  <div class="position-relative zindex200">
    <div id="cp-search-nav" v-click-outside="closePanel" :class="{'panel-on': panelIsOpen,'menu-on': showMenu || showLogout, 'closing' : closing}">
      <div v-if="allowLogin" :class="{'login-on':openLogin,'profile-on':openInfoPanel || isLogin}" class="top-panel">
        <i @click="closePanel()" class="fa fa-times for-mobile" />
        <client-only>
          <LoginBox v-if="!$auth.loggedIn" :open="openLogin" />
          <div v-if="$auth.loggedIn">
            <ProfileInfo v-click-outside="closePanel" is-nav-bar />
          </div>
        </client-only>
      </div>
      <div :class="{'isprofile-page': isProfilePage}" class="menu-bar">
        <a :class="{'col-lg-3 col-md-2': $route.path === '/'}" class="h-100" href="/">
          <div class="cp-logo">
            <img src="asset/image/logo/getlinks_grey.svg" alt="">
          </div>
        </a>
        <SearchBox v-if="showSearchBox" :redirect="searchRedirect" />
        <div
          v-if="isLogin"
          :class="{'flex-grow-0': $route.path !== '/' && isLogin, 'col-xl-3 col-lg-3 col-md-5 flex-grow-1': $route.path === '/'}"
          class="ml-auto text-right cp-user logged-in"
        >
          <li v-show="!panelIsOpen" v-click-outside="function(){showLogout=false}" :class="{'d-none': isMatchPath}" @click.stop="toggleProfileInfo" class="user-info pr-0">
            <client-only>
              <div class="icon">
                <img ref="photo" :src="photoUrl">
              </div>
              <span class="d-none d-lg-inline-block">{{ cpLang('profile.hi') }} {{ username }}</span>
            </client-only>
          </li>
          <li :class="{'d-none': !isMatchPath}" class="logout-li">
            <div id="logout-list">
              <a @mousedown="doLogout">
                <div @click.stop="showLogout=false" class="menu-item">
                  {{ cpLang('menu.logout') }}
                </div>
              </a>
            </div>
          </li>
          <li
            v-click-outside="function(){showNotification=false}"
            @click.stop="showNotification=true,showMenu=false,showLogout=false"
            v-show="!panelIsOpen"
            class="pl-0 cp-alert"
          >
            <i class="icon" />
            <label v-show="notifications.length">{{ notifications.length }}</label>
            <div id="notification-list" v-show="showNotification" class="cp-dropdown-menu">
              <div class="triangle" />
              <div v-for="item in notifications" :key="item.id">
                <a :href="item.url">
                  <div @click.stop="readNotification(item.type)" class="menu-item">
                    {{ cpLang(item.message) }}
                  </div>
                </a>
              </div>
              <div class="notification-footer">
                <div class="notification-wrap">
                  <button v-if="notifications.length" @click="clearNotifications" class="btn btn-primary btn-xs">
                  {{ cpLang('profile.clear_all') }}
                  </button>
                  <a href="/myprofile/setting" class="float-left">
                    <span @click.stop="showNotification=false">{{ cpLang('profile.settings') }}</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </div>
        <div
          v-else
          :class="{'pr-4 pr-lg-3 flex-grow-0': $route.path !== '/', 'col-xl-3 col-md-5 flex-grow-1': $route.path === '/'}"
          class="cp-user text-lg-nowrap ml-auto text-right"
        >
          <span v-if="allowLogin" class="d-md-inline-block">
            <a id="showLoginBtn" @click="showLogin" class="top-btn login-btn d-none d-lg-block">
              {{ cpLang('header.login') }}
            </a>
            <img @click="showLogin" src="/asset/image/icon/header/icon-login-grey.svg" class="grey-icon d-blcok d-lg-none">
            <img @click="showLogin" src="/asset/image/icon/header/icon-login.svg" class="white-icon d-blcok d-lg-none">
          </span>
          <span v-if="allowLogin" class="d-none d-md-none d-lg-inline-block">
            <a @click="toDppRegister" class="top-btn reg-btn-color">
              {{ cpLang('global.register') }}
            </a>
          </span>
          <span class="recur-btn">
            <a href="https://hr.getlinks.com/">
              {{ cpLang('header.recruiter') }}
            </a>
          </span>
        </div>
        <div class="l-flex l-align-center">
          <div
            v-click-outside="function(){showMenu=false}"
            :class="{ 'logged-in': isLogin }"
            @click.stop="showMenu=true,showNotification=false,showLogout=false,showBMenu = false"
            class="cp-menu"
          >
            <div class="h-100">
              <i class="fas fa-globe" />
              <div v-show="showMenu" class="cp-dropdown-menu">
                <div class="triangle" />
                <template v-for="item in menu">
                  <a
                    v-if="item.url && !item.inline"
                    :key="item.display"
                    @click.stop="changeLang(item.url)"
                  >
                    <div class="menu-item">
                      {{ cpLang(item.display) }}
                    </div>
                  </a>
                  <div
                    v-if="item.url && item.inline"
                    :class="{'hideInMobile': item.hideInMobile, 'd-block d-sm-none': item.hideInDesktop}"
                    :key="item.display"
                  >
                    <div class="menu-item inline">
                      <div v-if="item.iconClass" :class="item.iconClass" class="menu-icon" />
                      <a :ref="`lang-${item.url}`" @click="changeLang(item.url)" class="first">
                        <div class="menu-item" />
                        {{ cpLang(item.display) }}
                      </a>
                      <a v-for="(child, idx) in item.child" :ref="`lang-${child.url}`" @click="changeLang(child.url)" :key="idx" class="menu-item">{{ child.display }}</a>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div @click="showBMenu = true" v-click-outside="function() { showBMenu = false } " class="burger-menu">
            <div class="h-100 px-3 l-flex l-align-center">
              <i class="fas fa-bars" />
              <div v-show="showBMenu" class="cp-dropdown-menu b-menu">
                <div class="triangle" />
                <template v-for="item in userMenu">
                  <a :href="item.url" :key="item.url" target="_blank">
                    <div class="menu-item">
                      <div v-if="item.iconClass" :class="item.iconClass" class="menu-icon" />
                      {{ cpLang(item.display) }}
                    </div>
                  </a>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBox from '@/components/search/SearchBox'
import ProfileInfo from '~/components/profile/ProfileInfo'
import LoginBox from '~/components/user/LoginBox'
import commonFunc from '~/assets/js/commonHelper.js'

export default {
  components: {
    LoginBox,
    ProfileInfo,
    SearchBox
  },
  data: function () {
    return {
      isMatchPath: false,
      openLogin: false,
      openInfoPanel: false,
      isProfilePage: false,
      showNotification: false,
      showMenu: false,
      showLogout: false,
      notifications: [],
      bodyClass: '',
      cpHost: process.env.cp_host,
      closing: false,
      isHomePage: false,
      base_dpp_url: process.env.dppHost,
      userMenu: [
        {
          url: 'https://unicampaigns.getlinks.com/',
          display: 'homepage.card_title1',
          iconClass: '',
          hideInMobile: false
        },
        {
          url: 'https://humansoftech.getlinks.com/',
          display: 'homepage.card_title2',
          iconClass: '',
          hideInMobile: false
        },
        {
          url: 'https://blog.getlinks.com',
          display: 'homepage.card_title3',
          iconClass: '',
          hideInMobile: false
        }
      ],
      showBMenu: false,
      menu: [
        {
          display: 'ENGLISH',
          id: 'multi-lang-en',
          inline: false,
          url: 'en'
        },
        {
          display: 'ภาษาไทย',
          id: 'multi-lang-th',
          url: 'th'
        },
        {
          display: 'Tiếng Việt',
          id: 'multi-lang-vi',
          url: 'vi'
        }
      ]
    }
  },
  computed: {
    photoUrl: function () {
      return this.$auth.user.image ? this.$auth.user.image : '/asset/image/photo/avatar.jpg'
    },
    showSearchBox: function () {
      const pattern = /^(\/|\/campaign\/(.*?))$/
      return this.$route.path.match(pattern) === null
    },
    allowLogin: function () {
      const reg = /(\/myjob\/apply\/|\/logout)(.*?)/i
      const matched = this.$route.path.match(reg)
      return matched === null
    },
    searchRedirect: function () {
      const exclude = ['/SearchJobs']
      return !exclude.includes(this.$route.path)
    },
    currentRoute: function () {
      return '?redirecturl=' + encodeURIComponent(this.$route.fullPath)
    },
    isLogin: function () {
      return this.$auth.loggedIn
    },
    panelIsOpen: function () {
      if (this.openLogin || this.openInfoPanel) {
        if (process.client) {
          document.querySelector('#__layout').classList.add('show-panel')
        }
        return true
      }
      if (process.client) {
        const i = document.getElementById('cp-search-nav')
        if (i && i.classList.contains('closing')) {
          // document.querySelector('#__layout').classList.remove('show-panel')
        }
      }
      return false
    },
    username: function () {
      if (this.$auth.loggedIn) {
        return decodeURIComponent(this.$auth.user.firstname ? this.$auth.user.firstname : this.$auth.user.email).replace(/\+/gm, ' ')
      }
      return ''
    }
  },
  watch: {
    $route(to, from) {
      this.openInfoPanel = false
      const matchPath = commonFunc.matchPath(this.$route.path, /myprofile|myjob/)
      if (matchPath) {
        this.isMatchPath = true
      }
    }
  },
  mounted() {
    const matchPath = commonFunc.matchPath(this.$route.path, /myprofile|myjob/)
    if (matchPath) {
      this.isMatchPath = true
    }
    const vm = this
    if (this.isLogin) {
      this.getNotifications()
    }
    window.addEventListener('scroll', () => {
      const nav = document.querySelector('#cp-search-nav')
      if (vm.panelIsOpen) {
        const menuBar = nav.querySelector('.menu-bar')
        if (window.scrollY > nav.clientHeight - menuBar.clientHeight && commonFunc.isPortraitMobile() === false) {
          nav.classList.add('no-transition')
          nav.classList.remove('panel-on')
          setTimeout(
            vm.closePanel, 0
          )
        }
      }
    })
    window.addEventListener('mClosepanel', this.closePanel)
    window.addEventListener('closepanel', this.closePanel)
    window.addEventListener('isProfilePage', this.isProfile)
  },
  methods: {
    doLogout() {
      // this.$auth.logout()
      window.location.href = '/logout'
    },
    changeLang: function (lang) {
      this.$cpCookie.set('revampLang', lang)
      window.location.reload()
    },
    isProfile: function() {
      this.isProfilePage = true
    },
    readNotification: function(type) {
      this.showNotification = false
      const params = { msgtype: type }
      this.$axios.post('member/notifications/read', params)
    },
    clearNotifications: async function() {
      this.notifications = []
      await this.$axios.delete('member/notifications').then(function(response) {
      })
    },
    getNotifications: async function() {
      const vm = this
      await this.$axios.get('member/notifications').then(function(response) {
        const data = JSON.parse(JSON.stringify(response.data))
        vm.notifications = data.notifications
      })
    },
    showLogin: function () {
      this.openLogin = true
      window.scrollTo(0, 0)
    },
    toDppRegister: function () {
      const lang = this.$cpCookie.get('revampLang') || 'th'
      const redirectUrl = process.env.cp_host + this.$route.fullPath.substring(1)
      window.location.href = this.base_dpp_url + 'auth/register?r=' + encodeURIComponent(redirectUrl) + '&?lang=' + lang
    },
    closePanel: function () {
      const vm = this
      if (this.openLogin || this.openInfoPanel) {
        this.closing = true
        setTimeout(() => {
          vm.closing = false
          if (process.client) {
            document.querySelector('#__layout').classList.remove('show-panel')
          }
        }, 300)
      }
      this.openInfoPanel = false
      this.openLogin = false
    },
    toggleProfileInfo: function(e) {
      // check if under candidate profile
      // yes, only show logout menu;
      const profilePaths = ['myjob', 'myprofile']
      let inProfile = false
      profilePaths.forEach((path) => {
        if (this.$route.path.indexOf(path) !== -1) {
          inProfile = true
          return false
        }
      })
      if (inProfile) {
        this.showMenu = false
        this.showNotification = false
        this.showLogout = true
      } else {
        this.openInfoPanel = true
        window.scrollTo(0, 0)
      }
    },
    closeMenu: function(event) {
      if (event && event.target) {
        const { target } = event
        const checkNav = target.className === 'cp-menu' || target.parentElement.className === 'cp-menu'
        const checkUser = this.$auth.loggedIn && (document.getElementsByClassName('cp-user')[0].contains(target) || (new RegExp('cp-user', 'gi')).exec(target.className) !== null || document.querySelector('.cp-user-menu').contains(target))
        const checkMenuList = (target.classList.contains('menu-item')) || (target.parentElement.classList.contains('menu-item'))
        // Check click outside menu
        if (process.browser && !(checkUser || checkNav || checkMenuList)) {
          // document.querySelectorAll('.cp-dropdown-menu').forEach((item) => { item.style.display = 'none' })
          // document.querySelectorAll('.menu-child').forEach((item) => { item.style.display = 'none' })
          // document.querySelectorAll('.menu-triangle').forEach((item) => { item.classList.remove('reverse') })
          // document.querySelector('body').classList.remove('menu-on')
        }
      }
    }
  }
}
</script>

<style lang='scss'>
.login-on{
  position: relative;
  z-index: 1;
  background: transparent;
}

input.form-control{
  background-image: unset !important;
}
input.form-control:focus{
  box-shadow: 0 0 0 0.14rem #74aae4, 0 1px 6px #afafaf !important;
}
.was-validated input.form-control:invalid:focus{
  box-shadow: 0 0 0 0.14rem #ef8791, 0 1px 6px #afafaf !important;
}
.top-panel{
  .completion{
    .strength-tips{
      display: none !important;
    }
  }
}
.fa-globe{
  font-size: 23px;
  color: #333;
}
.fa-bars{
  font-size: 20px;
}
.static-page{
  a{
    color: #fff;
  }
}
.icon-d{
  opacity: 0;
}
.reg-btn-color{
    border: 1px solid #0EA5BF;
    color: #0EA5BF !important;
    padding: 5px 7px;
    border-radius: 5px;;
}
.cp-menu .h-100{
  line-height: 58px;
}
.burger-menu{
  cursor: pointer;
  height: 60px;
  &:hover{
    background: rgba(0, 0, 0, 0.05);
  }
}
</style>
