<template>
  <div id="search-box" :class="{'w-100': isMobile}" class="row justify-content-md-center">
    <div class="col col-sm-12">
      <div>
        <form v-on:submit.prevent autocomplete="off">
        <AutoComplete
          ref="keyword"
          :source="base_api+'suggestion/job?size=5&keyword='"
          :auto-complete-data="recentSearch"
          :concat="false"
          :input-props="{
            id: secondBox ? 'secondKeyword' : 'keyword',
            name:'keyword',
            autocomplete:'off',
            placeholder: redirect ? cpLang('global.search_placeholder') : cpLang('global.search_placeholder', totalJobNumber),
            value: sKeywords
          }"
          @selected="doJobSearch"
          @enter="doJobSearch"
          @open="searchOn"
          @close="searchOff"
          @clear="updateRecentSearch"
          class="search-box-autocomplete"
          search-box
        />
          <div v-if="recentOutside && !isMobile" class="recent-result pt-3">
            <span>{{ cpLang('global.recent_search') }}:</span>
            <template v-if="recentSearch.length > 0">
              <small v-for="(item, idx) in recentSearch" @click="doJobSearch(item.desc)" :key="item.desc+'-'+idx">
                {{ item.desc }}
              </small>
            </template>
          </div>
        </form>
      </div>
    </div>

    <div id="m-seach-btn" v-if="isMobile" class="col-xs-12 col-md-3 col-lg-2 px-0">
      <div @click="$emit('search')" class="search-btn">
        {{ cpLang("global.search") }}
      </div>
      <div v-if="recentOutside" class="recent-result pt-3">
        <span class="d-block">{{ cpLang('global.recent_search') }}:</span>
        <template v-if="recentSearch.length > 0">
          <small v-for="(item, idx) in recentSearch" @click="doJobSearch(item.desc)" :key="item.desc+'-'+idx">
            {{ item.desc }}
          </small>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import AutoComplete from '~/components/global/AutoComplete'
import commonFunc from '~/assets/js/commonHelper.js'
export default {
  components: {
    AutoComplete
  },
  props: {
    keyword: {
      type: String,
      default: ''
    },
    redirect: {
      type: Boolean,
      default: false
    },
    secondBox: {
      type: Boolean,
      default: false
    },
    recentOutside: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isHomePage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      base_api: process.env.BASE_API_URL,
      keyword_search: false,
      broderning: false,
      scrollY: 0,
      totalJobNumber: { total: '' },
      recentSearch: [],
      keywordResult: [],
      sKeywords: ''
    }
  },
  created() {
    if (this.$route.query.keyword !== undefined) {
      this.sKeywords = this.$route.query.keyword
    }
    if (this.$route.query.sopt === '3') {
      this.keyword_search = true
    }
    if (this.$route.query.broderning) {
      this.broderning = true
    }
    this.keyword = commonFunc.solrDecode(this.keyword)
    this.totalJobNumber.total = commonFunc.formatJobNumber(this.$store.state.search.total)
    this.updateRecentSearch()
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandle)
  },
  methods: {
    scrollHandle: function () {
      const scrollPos = Math.max(document.documentElement.scrollTop, document.body.scrollTop)
      const winHeight = window.innerHeight
      const obj = document.activeElement
      const buffer = 20
      const bodyHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight)
      const reachBottom = scrollPos > bodyHeight - winHeight - 100
      const hasFilter = !!document.querySelector('#job-filter')
      if (!hasFilter) {
        // return
      }
      if ((scrollPos > (this.scrollY + buffer) || reachBottom) && (!obj.classList.contains('autocomplete-input') && obj.id !== 'keyowrd')) {
        document.querySelector('body').classList.add('scroll-down')
        this.scrollY = scrollPos
      } else if (scrollPos < (this.scrollY - buffer)) {
        document.querySelector('body').classList.remove('scroll-down')
        this.scrollY = scrollPos
      }
      if (scrollPos > 0) {
        document.querySelector('body').classList.add('scroll')
      } else {
        document.querySelector('body').classList.remove('scroll')
      }
    },
    updateRecentSearch: function () {
      const recentSearchStr = this.$cpCookie.get('recent_search')
      const recentSearch = recentSearchStr === undefined ? [] : recentSearchStr.split(';')
      const keywords = []
      recentSearch.reverse()
      // console.error(recentSearch)
      recentSearch.forEach(function(param, index) {
        const key = commonFunc.getRecentKeyword(param)
        if (key !== null && key !== 'null' && key !== '') {
          keywords.push({ id: index + 1, desc: key })
        }
      })
      if (keywords.length > 0) {
        keywords[0].label = this.cpLang('global.recent_search')
      }
      // console.error(keywords)
      this.recentSearch = Array.from(new Set(keywords))
    },
    searchOn: function () {
      document.querySelector('body').classList.add('search-on')
      document.querySelector('body').classList.add('scroll-down')
    },
    searchOff: function () {
      document.querySelector('body').classList.remove('search-on')
    },
    doJobSearch: function (obj) {
      let value = ''
      const parameters = {}
      if (typeof obj === 'object') {
        value = obj.item.desc
        if (obj.item.type === 2) {
          parameters.sopt = 5 // for company name search
        }
      } else {
        value = obj
      }
      if (value !== undefined && value !== '') {
        parameters.keyword = value
      }
      if (this.$route.query.cri) {
        // filter OFID criteria
        const cri = this.$route.query.cri.replace(/^B(.*?)(-|$)/g, '')
        parameters.cri = cri
      }

      if (this.$route.query.tag) {
        parameters.tag = this.$route.query.tag
      }
      if (this.keyword_search) {
        parameters.sopt = 3
      }
      if (this.broderning) {
        parameters.broderning = true
      }
      if (this.$route.query.engine) {
        parameters.engine = this.$route.query.engine
      }
      // this.$gtm.pushEvent({ event: 'queryJobSearch', 'Job Search Keyword': value })
      const seoQuery = commonFunc.handleAlias(this.$route.path, this.$route.query)
      if (seoQuery.association !== undefined) {
        this.$router.push({
          path: '/association/' + seoQuery.association,
          query: parameters
        })
        return
      }
      if (this.redirect || commonFunc.isSeoAlias(this.$route.path, this.$route.query)) {
        const parameterArr = []
        Object.keys(parameters).forEach(function(key) {
          parameterArr.push(key + '=' + encodeURIComponent(parameters[key]))
        })
        let parameterStr = ''
        if (parameterArr.length) {
          parameterStr += '?' + parameterArr.join('&')
        }
        window.location.href = '/SearchJobs' + parameterStr
        return
      }
      this.$router.push({
        path: '/SearchJobs',
        query: parameters
      })
    },
    formattedSuggestion: function (result) {
      // switch(result.type){
      //   case 1:
      //      return '<i class="fa fa-briefcase"></i> ' + result.suggestion
      //   break
      //   case 2:
      //      return '<i class="fa fa-building"></i> ' + result.suggestion
      //   break
      // }
      return result.suggestion
    }
  }
}
</script>
<style lang="scss">
  #search-box{
    &.w-100{
      background: transparent;
    }
  }
  .recent-result{
    z-index: 1;
    span{
      color:#00CDA7;
    }
    small{
      background:#fff;
      border-radius:20px;
      padding: 3px 10px;
      margin-right: 10px;
      cursor: pointer;
      display: inline-block;
      margin-bottom: 5px;
    }
  }
  .search-box-autocomplete{
    position: relative;
    z-index: 9;
  }
</style>
