const BLOG_API = 'https://getlinks.com/wp-json/wp/v2'

export const state = () => ({
  categories: {
    th: [],
    en: []
  },
  maxPage: 1,
  currentLang: '',
  posts: [],
  post: null,
  page: 1
})

export const mutations = {
  setCategories(state, data) {
    state.categories.th = data.th
    state.categories.en = data.en
    state.currentLang = data.currentlang
  },
  setPosts(state, data) {
    state.posts = data.posts
    state.maxPage = data.header
  },
  setPost(state, data) {
    state.post = data
  },
  setPage(state, data) {
    state.page = data
  }
}

export const actions = {
  async getCategories({ commit }, params) {
    const allCategories = await this.$axios.get(`${BLOG_API}/categories?per_page=50&_fields=id,slug`, { Authorization: '' }).then(function(res) {
      return res.data
    })
    const en = []
    const th = []
    allCategories.forEach(function (e) {
      const idx = e.slug.indexOf('-')
      if (idx !== -1) {
        const len = e.slug.length
        const res = e.slug.substr(idx, len)
        if (res === '-en') {
          en.push(e.id)
        }
        if (res === '-th') {
          th.push(e.id)
        }
      } else if (e) {
        en.push(e.id)
      }
    })
    // const en = [786, 17, 278, 802, 28, 47, 334]
    // const th = [815, 1, 280, 798, 26, 45, 336]
    commit('setCategories', { en: en, th: th, currentlang: params })
  },
  async getPost({ commit }, params) {
    const post = await this.$axios.get(`${BLOG_API}/posts?slug=${params}&_embed`, { Authorization: '' })
      .then(res => res.data)
    // console.log(post)
    commit('setPost', post)
  },
  async getPostByCate({ commit }, params) {
    const page = params.page || 1
    const postsDatails = await this.$axios.get(`${BLOG_API}/posts?categories=${params.cateId}&_embed&per_page=9&page=${page}`, { Authorization: '' })
      .then((res) => {
        const headerObj = {
          total: res.headers['x-wp-total'],
          totalpages: res.headers['x-wp-totalpages']
        }
        return { posts: res.data, header: headerObj }
      })
    commit('setPosts', postsDatails)
  },
  async getPosts({ commit }, params) {
    const page = params.page || 1
    const cates = params.categories.join()
    const postsDatails = await this.$axios.get(`${BLOG_API}/posts?categories=${cates}&_embed=wp:featuredmedia,wp:term&page=${page}&per_page=9`, { Authorization: '' })
      .then((res) => {
        const headerObj = {
          total: res.headers['x-wp-total'],
          totalpages: res.headers['x-wp-totalpages']
        }
        return { posts: res.data, header: headerObj }
      })
    commit('setPosts', postsDatails)
    // const fields = '&_fields=id,link,categories,featured_media,date,slug,status,type,title,content,author,tags'
    // https://getlinks.com/wp-json/wp/v2/posts?categories=786,17,278,802,28,47,334&_embed=wp:featuredmedia
  }
}
