import commonFunc from '~/assets/js/commonHelper.js'
export const state = () => ({
  joblist: [],
  found: 0,
  offset: 0,
  total: '',
  job: false,
  filterQuery: '',
  filter: [],
  mapping: [],
  seo: [],
  morelink: '',
  suggestion: []
})

export const mutations = {
  setFilter(state, data) {
    state.filter = data.filter
  },
  setMappingData(state, items) {
    state.mapping = { ...items }
  },
  setSuggestion(state, data) {
    state.suggestion = data
  },
  setSeo(state, data) {
    state.seo = data
  },
  setJobList(state, data) {
    state.joblist = data.positions
  },
  setSimilarJobList(state, data) {
    state.joblist = data.positions
    state.morelink = data.morelink
  },
  setData(state, data) {
    const pageSize = 25
    const currentPage = Math.ceil(data.offset / pageSize) + 1
    if (data.positions === undefined) {
      return
    }
    if (data.positions.length > pageSize) {
      // pagination
      const list = commonFunc.groupArray(data.positions, pageSize)
      let mergedList = []
      list.forEach(function (item, index) {
        const random = commonFunc.getRandomArrayElements(data.priorityPositions, 5)
        const page = currentPage + index
        random.forEach(function (sub, k) {
          sub.page = page
        })
        item.forEach(function (pos, m) {
          pos.page = page
        })
        mergedList = mergedList.concat(random.concat(item))
      })
      state.joblist = mergedList
    } else {
      const mergedList = data.priorityPositions.concat(data.positions)
      mergedList.forEach(function (item, index) {
        item.page = currentPage
      })
      state.joblist = mergedList
    }
    state.found = data.found
    state.offset = data.offset
    state.filter = data.filter
    state.filterQuery = data.filterQuery
    state.total = data.total
  },
  setDetail(state, data) {
    state.job = data
  }
}

export const actions = {
  validateJob: async function ({ commit }, ids) {
    const { data } = await this.$axios.get('positions?validate=' + ids.join(','))
    commit('setJobList', data)
  },
  fetchSimilarJob: async function ({ commit }, id) {
    const { data } = await this.$axios.get('positions/recommendation?size=6&positionid=' + id)
    commit('setSimilarJobList', data)
  },
  fetchSuggestion: async function ({ commit }, keyword) {
    const { data } = await this.$axios.get('suggestion/job?keyword=' + encodeURIComponent(keyword))
    commit('setSuggestion', data)
  },
  fetchSeo: async function ({ commit }, url) {
    // const hkUrl = url.includes('/') === false ? '/hk' + url : url
    const { data } = await this.$axios.get('seoparam?url=' + encodeURIComponent(url))
    // console.log('seo', data)
    commit('setSeo', data) // commit = call mutations
  },
  fetchJob: async function ({ commit }, query) {
    let hasSort = false
    const result = Object.keys(query).map(function (key) {
      // overwrite search criteria
      if (key === 'sort') {
        hasSort = true
      }
      if (key === 'cri') {
        const criArray = query[key].split('-')
        const paramArray = []
        criArray.forEach(function (item, index) {
          const key = item.charAt(0)
          const value = item.substring(1)
          switch (key) {
            case 'F':
              paramArray.push('field=' + value)
              break
            case 'S':
              paramArray.push('subfield=' + value)
              break
            case 'G':
              paramArray.push('cluster=' + value)
              break
            case 'I':
              paramArray.push('industry=' + value)
              break
            case 'N':
              paramArray.push('country=' + value)
              break
            case 'C':
              paramArray.push('city=' + value)
              break
            case 'E':
              paramArray.push('minexperience=' + value)
              break
            case 'T':
              paramArray.push('employmenttype=' + value)
              break
            case 'B':
              paramArray.push('ofid=' + value)
              break
            case 'L':
              paramArray.push('joblevel=' + value)
              break
            case 'R':
              paramArray.push('minsalary=' + value)
              break
            case 'Q':
              paramArray.push('educationlevel=' + value)
              break
            case 'W':
              paramArray.push('workmode=' + value)
              break
          }
        })
        return paramArray.join('&')
      }
      if (key === 'keyword') {
        // const newKey = encodeURIComponent(query[key]).replace(/[!'()*]/g, function(c) {
        //   return '%' + c.charCodeAt(0).toString(16).toUpperCase();
        // })
        // console.log('#debug158', query)
        let k = ''
        if (query[key] && query[key] !== undefined) {
          k = query[key].replace(/[!'()*]/g, ' ')
        } else {
          k = ''
        }
        const s = k.replace(/[,.*]/g, ' ')
        const newKey = encodeURIComponent(s)

        return key + '=' + newKey
      }
      return key + '=' + query[key]
    })
    if (this.$cpCookie.get('results_sortby') && !hasSort) {
      result.push('sort=' + this.$cpCookie.get('results_sortby'))
    }
    // console.log('#167debug: ', 'positions?' + result.join('&'))
    const { data } = await this.$axios.get('positions?' + result.join('&'))
    try {
      for (const x in data.positions) {
        data.positions[x].blindAd = (data.positions[x].isanonymouscompany === 'Y' && data.positions[x].applythisjobon === 'X' && data.positions[x].dontacceptapplication === 'Y')
      }
      for (const x in data.priorityPositions) {
        data.priorityPositions[x].blindAd = (data.priorityPositions[x].isanonymouscompany === 'Y' && data.priorityPositions[x].applythisjobon === 'X' && data.priorityPositions[x].dontacceptapplication === 'Y')
      }
    } catch (e) {
      console.log('Handle Blind Ad Error', { e })
    }
    commit('setData', data) // commit = call mutations
  },
  fetchFilter: async function ({ commit }, query) {
    query.extrafield = 'caculatedfilter'
    query.withpriority = false
    const result = Object.keys(query).map(function (key) {
      // overwrite search criteria
      if (key === 'cri') {
        const criArray = query[key].split('-')
        const paramArray = []
        criArray.forEach(function (item, index) {
          const key = item.charAt(0)
          const value = item.substring(1)
          switch (key) {
            case 'F':
              paramArray.push('field=' + value)
              break
            case 'S':
              paramArray.push('subfield=' + value)
              break
            case 'I':
              paramArray.push('industry=' + value)
              break
            case 'N':
              paramArray.push('country=' + value)
              break
            case 'C':
              paramArray.push('city=' + value)
              break
            case 'E':
              paramArray.push('minexperience=' + value)
              break
            case 'T':
              paramArray.push('employmenttype=' + value)
              break
            case 'B':
              paramArray.push('ofid=' + value)
              break
            case 'L':
              paramArray.push('joblevel=' + value)
              break
            case 'R':
              paramArray.push('minsalary=' + value)
              break
            case 'Q':
              paramArray.push('educationlevel=' + value)
              break
          }
        })
        return paramArray.join('&')
      }
      return key + '=' + query[key]
    })
    const { data } = await this.$axios.get('positions?' + result.join('&'), {
      withCredentials: true
    })
    commit('setFilter', data) // commit = call mutations
  },
  fetchMapping: async function ({ commit }) {
    const query = 'positions?extrafield=property'
    const { data } = await this.$axios.get(query)
    commit('setMappingData', data) // commit = call mutations
  },
  fetchDetail: async function ({ commit }, id) {
    const { data } = await this.$axios.get('positions/' + id)
    commit('setDetail', data)
  }
}
