export const state = () => ({
  savedList: [],
  appliedList: [],
  invitedList: [],
  recommendedList: []
})

export const mutations = {
  setSavedList(state, data) {
    state.savedList = data
  },
  setAppliedList(state, data) {
    state.appliedList = data
  },
  setRecommendedJob(state, data) {
    state.recommendedList = data
  },
  setInvitedList(state, data) {
    state.invitedList = data
  }
}
export const actions = {
  fetchSavedJob: async function ({ commit }, params) {
    const { data } = await this.$axios.get('member/myjob/saved', {
      params: params
    })
    commit('setSavedList', data)
  },
  fetchRecommendedJob: async function ({ commit }, params) {
    const { data } = await this.$axios.get('positions/recommendation', {
      params: params
    })
    commit('setRecommendedJob', data)
  },
  fetchAppliedJob: async function ({ commit }, params) {
    const { data } = await this.$axios.get('member/myjob/applied', {
      params: params
    })
    commit('setAppliedList', data)
  },
  fetchInvitedJob: async function ({ commit }, params) {
    const { data } = await this.$axios.get('member/myjob/invited', {
      params: params
    })
    commit('setInvitedList', data)
  }
}
