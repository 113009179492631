export const state = () => ({
  list: [],
  posts: [],
  post: []
})

export const mutations = {
  setItems(state, items) {
    state.list = items
  },
  setPosts(state, items) {
    state.posts = items
  },
  setPost(state, items) {
    state.post = items
  }
}

export const actions = {
  fetchItems: async function ({ commit }) {
    const items = await this.$axios.get('https://jsonplaceholder.typicode.com/todos/1')
    commit('setItems', items.data) // commit = call mutations
  },
  fetchPosts: async function ({ commit }) {
    const posts = await this.$axios.get('https://jsonplaceholder.typicode.com/posts')
    commit('setPosts', posts.data) // commit = call mutations
  },
  fetchPostByTitle: async function ({ commit }, title) {
    // console.log(escape(title))
    const posts = await this.$axios.get('https://jsonplaceholder.typicode.com/posts?title=' + escape(title))
    commit('setPost', posts.data[0]) // commit = call mutations
  }
}
