export default async ({ app }) => {
  const dppToken = app.$cpCookie.get(process.env.DPP_AUTH_KEY)
  if (dppToken) {
    const authToken = dppToken.auth_token
    await app.$auth.strategy.token.set(authToken)
    try {
      await app.$auth.fetchUser()
    } catch (err) {
      await app.$cpCookie.remove(process.env.DPP_AUTH_KEY, {
        domain: '.getlinks.com'
      })
      await app.$auth.strategy.token.reset()
    }
  }
}
