<template>
  <div class="col-social">
      <h5 class="text-center">
{{ cpLang('global.or_txt') }}
</h5>
      <a @click="login('facebook')" class="facebook-login" title="Facebook"><span>
        <!-- <i class="fab fa-facebook-f" /> -->
        {{ cpLang('login.facebook') }}</span></a>
      <a @click="login('google')" class="google-login" title="Google"><span>
        <!-- <i class="fab fa-google" /> -->
        {{ cpLang('login.google') }}</span></a>
      <a @click="login('linkedin')" class="linkedin-login" title="Linkedin"><span>
        <!-- <i class="fab fa-linkedin-in" /> -->
        {{ cpLang('login.linkedin') }}</span></a>
      <a @click="login('github')" class="dpp-login" title="DPP"><span>
        Sign In With Github
        </span></a>
  </div>
</template>

<script>
import commonHelper from '~/assets/js/commonHelper.js'
export default {
  props: {
    open: Boolean
  },
  data: function() {
    return {
    }
  },

  computed: {
    redirect() {
      return (
        this.$route.query.redirect &&
        decodeURIComponent(this.$route.query.redirect)
      )
    },
    isCallback() {
      return Boolean(this.$route.query.callback)
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    login: function (platform) {
      // save redirect uri to localstorage
      const currentUrl = process.env.cp_host + this.$route.fullPath.substring(1)
      const failUrl = process.env.cp_host + 'loginErr'
      const uri = window.location.href.indexOf('registration') > -1 ? '/myjob' : window.location.href
      commonHelper.setLocalStorage('redirect_uri', uri)
      switch (platform) {
        case 'facebook': {
          const uri = `${process.env.DPP_AUTH_URL}auth/facebook?success_callback=${currentUrl}&fail_callback=${failUrl}`
          window.location.href = uri
          break
        }
        case 'google': {
          const uri = `${process.env.DPP_AUTH_URL}auth/google?success_callback=${currentUrl}&fail_callback=${failUrl}`
          window.location.href = uri
          break
        }
        case 'linkedin': {
          const uri = `${process.env.DPP_AUTH_URL}auth/linkedin?success_callback=${currentUrl}&fail_callback=${failUrl}`
          window.location.href = uri
          break
        }
        case 'github': {
          const uri = `${process.env.DPP_AUTH_URL}auth/github?success_callback=${currentUrl}&fail_callback=${failUrl}`
          window.location.href = uri
          break
        }
      }
    }
  }
}
</script>
<style lang="scss">
  .dpp-login{
    cursor: pointer;
    width: 90%;
    color: #fff !important;
    font-size: 12px;
    padding: 12px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    display: block;
    border-radius: 4px;
    margin-bottom: 12px;
    border-radius: 5px;
    position: relative;
    text-align: center;
    background: #000;
  }
</style>
