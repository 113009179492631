<template>
  <div class="profile-info">
    <div class="container inner-container">
      <div class="row">
        <div class="col-6">
          <h1 class="welcome-msg">
          {{ cpLang('profile.welcome', {username}) }}
          </h1>
        </div>
        <div class="col-4 notification" />
        <div class="col-2 logout-btn">
          <a @click="doLogout" class="btn">{{ cpLang('menu.logout') }}</a>
        </div>
      </div>
      <div class="clear-both">
        <small class="last-update">{{ cpLang('profile.lastupdate') }} {{ lastupdate }}</small>
        <client-only>
          <div class="col-5">
            <ProfilePic />
            <div class="col-summary">
              <ProfileSummary :is-nav-bar="isNavBar" class="mb-lg-4" />
              <div class="strength-wrap">
                <ProfileStrength :is-nav-bar="isNavBar" />
                <a v-if="completeness < 100" @click="test" class="profile-btn mt-2">
                  {{ cpLang('profile.viewfull') }}
                </a>
              </div>
            </div>
          </div>
        </client-only>
        <client-only>
          <div class="col-7 pb-3">
            <JobNumber is-search-nav />
          </div>
        </client-only>
      </div>
      <div class="clear-both" />
    </div>
  </div>
</template>
<script>
import ProfilePic from '@/components/profile/ProfilePic'
import ProfileStrength from '@/components/profile/ProfileStrength'
import ProfileSummary from '@/components/profile/ProfileSummary'
import JobNumber from '@/components/profile/JobNumber'
export default {
  components: {
    JobNumber,
    ProfilePic,
    ProfileStrength,
    ProfileSummary
  },
  props: {
    isNavBar: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      completeness: 0,
      resume: null
    }
  },
  computed: {
    username: function () {
      if (this.$auth.loggedIn) {
        return decodeURIComponent(this.$auth.user.firstname ? this.$auth.user.firstname : this.$auth.user.email).replace(/\+/gm, ' ')
      }
      return ''
    },
    lastupdate: function() {
      return this.$auth.user.statistics.lastupdate ?? ''
    }
  },
  methods: {
    test() {
      window.location.href = '/myprofile'
    },
    doLogout() {
      window.location.href = '/logout'
      // this.$auth.logout()
    },
    closePanel: function() {
      const event = new Event('mClosepanel')
      window.dispatchEvent(event)
    }
  }
}
</script>
<style lang="scss">
  .profile-btn{
    cursor: pointer;
  }
</style>
