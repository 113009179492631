<template>
  <b-dd
    :html="placeholder"
    no-caret
    variant="none"
    toggle-class="selectlang-dd"
    class="b-dd-wrapper"
  >
    <b-dd-item v-for="option in options" :value="option" :key="option.id" @click="$emit('select-item', option), $emit('auto-set-lang')">
      {{ option.desc || option.name }}
    </b-dd-item>
  </b-dd>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    downSVG: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => `<span class="b-dd-text">Select Country</span> ${this.downSVG}`
    }
  }
}
</script>

<style lang="scss">
.b-dd-wrapper {
  width: 294px;
  @media screen and (min-width: 768px) {
    width: 200px;
  }
  .selectlang-dd {
    width: 100%;
    border: solid 1px #CECECE;
    border-radius: 20px;
    font-size: 14px;
    height: 40px;
    color: #333333;
    box-shadow: 0px 3px 6px #00000029;
    @media screen and (min-width: 768px) {
    }
  }
  .b-dd-text {
    position: absolute;
    top: 9px;
    left: 15px;
  }
  svg {
    position: absolute;
    right: 15px;
    top: 12px;
  }
  .dropdown-menu.show {
    width: 100%;
  }
}

</style>
