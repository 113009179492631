export const state = () => ({
  list: []
})

export const mutations = {
  setData(state, items) {
    state.list = items
  }
}

export const actions = {
  fetch: async function ({ commit }) {
    const { data } = await this.$axios.get('recruitmentday')
    commit('setData', data) // commit = call mutations
  }
}
