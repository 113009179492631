import commonHelper from '~/assets/js/commonHelper.js'
export const state = () => ({
  featuredLogo: [],
  filter: [],
  featuredJob: [],
  careerHubs: [],
  productList: []
})
export const mutations = {
  setFeaturedLogo(state, items) {
    state.featuredLogo = items
  },
  setFeaturedJob(state, items) {
    state.featuredJob = items
  },
  setFilterData(state, items) {
    state.filter = { ...items }
  },
  setCareerHubs(state, items) {
    state.careerHubs = items
  },
  setProductList(state, items) {
    state.productList = items
  }
}

export const actions = {
  fetchFeaturedLogo: async function ({ commit }) {
    const { data } = await this.$axios.get('featuredlogos')
    for (const i in data) {
      data[i].slug = data[i].companyname.replace(/ /g, '-').toLowerCase()
    }
    // For test
    // if (data.length < 8) {
    //   for (const x in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
    //     const temp = {}
    //     Object.assign(temp, data[0])
    //     temp.id = x
    //     data.push(temp)
    //   }
    // }
    commit('setFeaturedLogo', data) // commit = call mutations
  },
  fetchFeaturedJob: async function ({ commit }) {
    const country = await this.$cpCookie.get('default_country')
    const { data } = await this.$axios.get('positions/featuredJobs?country=' + country)
    // for (const i in data) {
    //   data[i].slug = data[i].companyname.replace(/ /g, '-').toLowerCase()
    // }
    commit('setFeaturedJob', data) // commit = call mutations
  },
  fetchFilterData: async function ({ commit }) {
    const query = 'positions?extrafield=caculatedfilter'
    const { data } = await this.$axios.get(query, {
      withCredentials: false
    })

    commit('setFilterData', data) // commit = call mutations
  },
  fetchCareerHubs: function ({ commit }) {
    const lang = this.$cpCookie.get('revampLang')
    // const query = 'positions?extrafield=caculatedfilter&withpriority=false'
    // const { data } = await this.$axios.get(query, {
    //   withCredentials: false
    // })
    const data = [
      // {
      //   name: 'Start up',
      //   desc: 'Startups are revolutionary and increasing in numbers. Learn more about startup jobs, company, technology and skills right for you.',
      //   url: '/career-hubs/startup',
      //   color: '51, 95, 242',
      //   img: '/asset/image/photo/career-hub-1.jpg'
      // },
      {
        name: commonHelper.cpLang('global.fintech', '', lang),
        desc: 'Education are revolutionary and increasing in numbers. Learn more about startup jobs, company, technology and skills right for you.',
        url: '/career-hubs/education',
        color: '71, 145, 17',
        img: '/asset/image/photo/cluster1.png'

      },
      {
        name: commonHelper.cpLang('global.digital', '', lang),
        desc: 'Part-time are revolutionary and increasing in numbers. Learn more about startup jobs, company, technology and skills right for you.',
        url: '/career-hubs/part-time',
        color: '71, 145, 17',
        img: '/asset/image/photo/cluster2.png'
      },
      {
        name: commonHelper.cpLang('global.smart_living', '', lang),
        desc: 'Human Resource are revolutionary and increasing in numbers. Learn more about startup jobs, company, technology and skills right for you.',
        url: '/career-hubs/human-resources',
        color: '71, 145, 17',
        img: '/asset/image/photo/cluster3.png',
        id: 'hr'
      }
    ]
    commit('setCareerHubs', data) // commit = call mutations
  },
  fetchProductList: function ({ commit }) {
    const lang = this.$cpCookie.get('revampLang')
    // const query = 'positions?extrafield=caculatedfilter&withpriority=false'
    // const { data } = await this.$axios.get(query, {
    //   withCredentials: false
    // })
    const data = [
      {
        name: commonHelper.cpLang('global.career_gps', '', lang),
        icon: '/asset/image/icon/logo-GPS.png',
        desc: commonHelper.cpLang('global.career_gps_intro', '', lang),
        img: '/asset/image/photo/gps.png',
        logoClass: 'gps',
        btnId: 'hpp-gps-btn',
        btnText: commonHelper.cpLang('global.explore_now', '', lang),
        btnLink: 'https://www.cpjobs.com/gps'
      }
      // {
      //   name: 'CV Doctor',
      //   icon: '/asset/image/icon/logo - CV Doctor.png',
      //   desc: 'The unique feature you have been waiting for. You needed to find the right words to convince the recruiters. You needed to use the correct format to not be filtered out by an Applicant Tracking System (ATS). Now, we help you one up the competition and land more interviews with an expertly hand-polished and ATS-optimised resume.',
      //   logoClass: 'cv',
      //   img: '/asset/image/photo/Appraise.png',
      //   btnId: 'hpp-cvdoctor-btn',
      //   btnText: 'Try Now',
      //   btnLink: 'https://cvdoctor.cpjobs.com',
      //   extraClass: 'doc'
      // }

    ]
    commit('setProductList', data) // commit = call mutations
  }
}
