// import commonFunc from '~/assets/js/commonHelper.js'
export default function ({ redirect, app, req }) {
  // extract old tag search link;
  if (req) {
    const selectedCountry = app.$cpCookie.get('default_country') || ''
    const stopPreSelectedCountry = app.$cpCookie.get('stop_preSelect')
    const requrl = req.url
    const reqQuery = req.query
    const reqQueryLength = Object.keys(reqQuery).length
    let resultUrl = ''

    // if no "if block", it will infinity loop, dont know why
    if (!stopPreSelectedCountry || stopPreSelectedCountry === 'undefined') {
      if (reqQueryLength === 0) {
        resultUrl = `${requrl}?cri=N${selectedCountry}`
        return redirect(resultUrl)
      }
      if (!Object.keys(reqQuery).includes('cri')) {
        resultUrl = `${requrl}&cri=N${selectedCountry}`
        return redirect(resultUrl)
      }
      // if (Object.keys(reqQuery).includes('cri')) {
      //   const queryValue = reqQuery.cri
      // } else {
      //   resultUrl = `${requrl}&cri=N${selectedCountry}`
      //   return redirect(resultUrl)
      // }
    }
  }
}
