<template>
  <div>
    <div v-click-outside="closeList" :class="{'open':isOpen, 'selected':selected}" class="autocomplete">
      <h3 v-show="label !== ''" class="label">
      {{ label }}
      </h3>
      <i v-show="(search.length > 0 && isOpen)" v-if="!noIcon" @click.prevent.stop="clearInput" class="fa fa-backspace clearBtn" />
      <i v-show="!isOpen && !noArrow" class="fa fa-angle-down" />
      <i v-show="loading" class="fa fa-spinner fa-spin" />
      <div @click="isOpen = true" class="input-cover" />
      <input
        ref="searchBox"
        :id="inputProps && inputProps.id? inputProps.id : ''"
        :placeholder="inputProps && inputProps.placeholder ? inputProps.placeholder : ''"
        @input="onChange"
        @focus="isOpen = true, isFocus = true, isJobalert ? jobalert_emptySearch() : null"
        @blur.prevent="isFocus = false, isJobalert ? jobalert_showSearch() : null"
        @click="isOpen = true"
        @keyup="keyupHandle"
        @keydown="keydownHandle"
        v-model="search"
        :readonly="readOnly"
        :class="{'cursor-pointer': readOnly}"
        autocomplete="off"
        type="text"
        class="autocomplete-input"
        maxlength="150"
      >
      <label v-if="selectedResult.length >= 1 " class="more-label">{{ cpLang('jobalert_selected') }} ({{ selectedResult.length }})</label>
      <button class="btn btn-primary btn-confirm">
        {{ cpLang('global.confirm') }}
      </button>
      <i @click="closeList" class="fa fa-times btn-close" />
      <!-- Single AutoComplete -->
      <div class="autocomplete-results single">
        <ul
          ref="resultList"
          v-if="((results.length >0 || showNoResult) || source) && !isMultiple"
          v-show="isOpen"
          class="my-0 p-0"
        >
          <div v-if="results.length ===0 && showNoResult" class="no-result">
            {{ cpLang('global.no_result') }}
          </div>
          <li
            v-for="(result, i) in results"
            :key="`${i}-${result.id}`"
            :class="{'disabled': (result.count===0 || result.type ==='disabled')}"
            :title="result.title"
            @click.stop="setResult(result)"
            class="autocomplete-result"
          >
            <hr v-if="result.label && i>0">
            <p v-if="result.label">
              {{ cpLang(result.label) }}
            </p>
            <span v-if="result.search" v-html="result.search" class="itemDesc">
              {{ cpLang(result.desc) }}
            </span>
            <span v-else class="itemDesc">
              {{ cpLang(result.desc) }}
            </span>
            <span v-if="showCount">
              ({{ result.count }})
            </span>
          </li>
        </ul>
      </div>
      <!-- Multiple AutoComplete -->
      <ul
        ref="resultList"
        v-if="(results.length >0 || showNoResult) && !isNested && isMultiple"
        v-show="isOpen"
        class="autocomplete-results"
      >
        <div v-if="results.length ===0 && showNoResult" class="no-result">
          {{ cpLang('global.no_result') }}
        </div>
        <div
          v-for="(result) in results"
          :key="generateKey('main',result.id)"
          :data-id="generateKey('main',result.id)"
          :class="{'disabled':!result.count && !initData}"
          class="multiple-select"
        >
<li
            class="autocomplete-result"
          >
<label>
          <input
            :id="generateKey('checkbox',result.id)"
            v-model="result.selected"
            @change="setResult(result)"
            type="checkbox"
            value="Y"
          >
              <span v-if="result.search" v-html="result.search" class="itemDesc">
                {{ cpLang(result.desc) }}
              </span>
              <span v-else class="itemDesc">
                {{ cpLang(result.desc) }}
              </span> <span v-if="showCount">
                ({{ result.count }})
              </span>
            </label>
          </li>
        </div>
      </ul>
      <!-- Nested AutoComplete -->
      <ul
        ref="resultList"
        v-if="(results.length > 0 || showNoResult) && isMultiple && isNested"
        v-show="isOpen"
        class="autocomplete-results"
      >
        <div v-if="results.length ===0 && showNoResult" class="no-result">
          {{ cpLang('global.no_result') }}
        </div>
        <template v-for="(result) in results">
          <li
            v-if="result.sub || result.issub"
            :key="generateKey('main',result.id)"
            :data-id="generateKey('main',result.id)"
            :class="{'sub':result.issub}"
          >
            <div
              :class="{'disabled':!result.count && !initData}"
              class="multiple-select"
            >
              <label>
              <input
                :id="generateKey('checkbox',result.id)"
                v-model="result.selected"
                @change="setResult(result)"
                type="checkbox"
                value="Y"
              >
              <b class="autocomplete-result">
                <span v-if="result.search" v-html="result.search" class="itemDesc">
                  {{ cpLang(result.desc) }}
                </span>
                <span v-else class="itemDesc">
                  {{ cpLang(result.desc) }}
                </span> <span v-if="showCount">({{ result.count }})</span>
              </b>
              </label>
            </div>

            <div v-if="!result.search">
              <div
                v-for="(subItem) in result.sub"
                :key="generateKey('sub',subItem.id)"
                :data-id="generateKey('sub',subItem.id)"
                class="autocomplete-result child"
              >
                <div
                  :class="{'disabled':!subItem.count && !initData}"
                  class="multiple-select"
                >
<label>
                  <input
                    :id="generateKey('checkbox',subItem.id)"
                    v-model="subItem.selected"
                    @change="setResult(subItem,true)"
                    type="checkbox"
                    value="Y"
                  >
                  <span class="itemDesc">
                    {{ cpLang(subItem.desc) }}
                  </span> <span v-if="showCount">({{ subItem.count }})</span>
                  </label>
                </div>
              </div>
            </div>
          </li>
        </template>
      </ul>
      <!-- 3D array -->
      <ul
        ref="resultList"
        v-if="threeDArray"
        v-show="isOpen"
        class="autocomplete-results location"
      >
        <div v-if="results.length === 0 && showNoResult" class="no-result">
          {{ cpLang('global.no_result') }}
        </div>
        <template
          v-for="(result, i) in results"
        >
          <li
            v-if="result.sub || result.issub"
            :key="i"
            :class="{'sub':result.issub}"
          >
            <div :class="{'multiple-select': isMultiple, 'single-select': !isMultiple }">
              <label
                @click.stop="((search && result.sub) || result.sub && !isMultiple ) ? null : setResult(result)"
                :class="{'firstSelectable' : search }"
              >
              <input
                v-if="isMultiple"
                :id="generateKey('checkbox',result.id)"
                v-model="result.selected"
                @change="setResult(result)"
                type="checkbox"
                value="Y"
              >
              <b class="autocomplete-result">
                <span v-if="result.search" v-html="result.search" class="itemDesc">
                  {{ cpLang(result.desc) }}
                </span>
                <span v-else class="itemDesc">
                  {{ cpLang(result.desc) }}
                </span>
              </b>
              </label>
            </div>
            <div v-if="!result.search" :class="{'multiple-select' : isMultiple, 'single-select': !isMultiple}">
              <ul
                v-for="(subItem) in result.sub"
                :key="generateKey('sub',subItem.id)"
                :data-id="generateKey('sub',subItem.id)"
                class="autocomplete-result child"
              >
                <li
                  :class="{'multiple-select' : isMultiple && !subItem.sub, 'has-child': subItem.sub}"
                  class="ary-2d"
                >
<label
                    @click.stop="subItem.sub && !isMultiple ? null : setResult(subItem,true)"
                    :class="{'selectable': !subItem.sub }"
                    class="second-label"
                  >
                  <input
                    @change="subItem.sub && !isMultiple ? null : setResult(subItem,true)"
                    v-if="isMultiple"
                    :id="generateKey('checkbox',subItem.id)"
                    v-model="subItem.selected"
                    type="checkbox"
                    value="Y"
                  >
                    <span class="itemDesc">{{ cpLang(subItem.desc) }}</span>
                  </label>
                  <ul :key="idx3d" v-for="(sub3d,idx3d) in subItem.sub" :class="{'arr-3d' : subItem.sub}">
                    <li>
<label
                        @click.stop="setResult(sub3d,true)"
                        class="third-label selectable"
                      >
                      <input
                        @change="setResult(sub3d,true)"
                        v-if="isMultiple"
                        :id="generateKey('checkbox',sub3d.id)"
                        v-model="sub3d.selected"
                        type="checkbox"
                        value="Y"
                      >
                        <span class="itemDesc">{{ cpLang(sub3d.desc) }}</span>
                      </label>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </template>
      </ul>
      <!-- end 3d ary -->
      <!-- only Nested-->
      <ul
        ref="resultList"
        v-if="((results.length >0 || showNoResult) || source) && !isMultiple && isNested"
        v-show="isOpen"
        class="autocomplete-results single nested"
      >
        <div v-if="results.length ===0 && showNoResult" class="no-result">
          {{ cpLang('global.no_result') }}
        </div>
        <template
          v-for="(result) in results"
        >
          <li
            v-if="result.sub || result.issub"
            :key="generateKey('main',result.id)"
            :data-id="generateKey('main',result.id)"
            :class="{'sub':result.issub}"
          >
            <div class="result">
              <label
                @click.stop="((search && result.sub) || result.sub) ? null : setResult(result,true)"
                :class="{'firstSelectable' : search }"
              >
              <b class="autocomplete-result">
                <span v-if="result.search" v-html="result.search" class="itemDesc">
                  {{ cpLang(result.desc) }}
                </span>
                <span v-else class="itemDesc">
                  {{ cpLang(result.desc) }}
                </span> <span v-if="showCount">({{ result.count }})</span>
              </b>
              </label>
            </div>
            <div v-if="!result.search" class="ary-2d">
              <ul
                v-for="(subItem) in result.sub"
                :key="generateKey('sub',subItem.id)"
                :data-id="generateKey('sub',subItem.id)"
                :data-set="subItem.id"
                :data-set-desc="subItem.desc"
                class="autocomplete-result child"
              >
                <li class="">
                  <!-- <input :id="`checkbox-${subItem.id}`" v-model="subItem.selected" type="checkbox" value="Y" @change="setResult(subItem,true)"> -->
                  <label
                    :class="{'selectable': !subItem.sub }"
                    @click.stop="subItem.sub ? null : setResult(subItem,true)"
                    class="second-label"
                  >
                  <span class="itemDesc">
                    {{ cpLang(subItem.desc) }}
                  </span> <span v-if="showCount">({{ subItem.count }})</span>
                  </label>
                </li>
              </ul>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  props: {
    inputProps: {
      default: () => ({ id: '', placeholder: '', value: '' }),
      type: Object
    },
    autoCompleteData: {
      default: () => [],
      type: Array || Object
    },
    isMultiple: {
      default: false,
      type: Boolean
    },
    label: {
      default: '',
      type: String
    },
    isNested: {
      default: false,
      type: Boolean
    },
    showCount: {
      default: false,
      type: Boolean
    },
    showNoResult: {
      default: false,
      type: Boolean
    },
    selectOnly: {
      default: false,
      type: Boolean
    },
    matchOnly: {
      default: false,
      type: Boolean
    },
    limit: {
      default: 5,
      type: Number
    },
    source: {
      default: '',
      type: String
    },
    method: {
      type: String,
      default: 'get'
    },
    checkboxKey: {
      type: String,
      default: ''
    },
    concat: {
      type: Boolean, // concat default list and search result list
      default: true
    },
    threeDArray: {
      type: Boolean,
      default: false
    },
    initData: {
      type: [Number, String, Array, Object, Boolean],
      default: 0
    },
    stringId: {
      type: Boolean,
      default: false
    },
    originId: {
      type: Boolean,
      default: false
    },
    isJobalert: {
      type: Boolean,
      default: false
    },
    jobalertLocation: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    test: {
      type: Boolean,
      default: false
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    noArrow: {
      type: Boolean,
      default: false
    },
    searchBox: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      originalList: [],
      searchList: [],
      search: '',
      results: [],
      selected: false,
      isOpen: false,
      selectedIndex: -1,
      loading: false,
      isFocus: false,
      currentLabel: '',
      error: '',
      selectedResult: [],
      tempList: []
    }
  },
  watch: {
    autoCompleteData: function () {
      this.originalList = this.autoCompleteData
      this.isJobalert ? this.jobalertFilterResults() : this.filterResults()
    },
    isOpen: function () {
      if (this.isJobalert && !this.isOpen) {
        this.search = this.selectedResult.length !== 0 ? this.selectedResult[0].desc : ''
      }
      if (this.isOpen) {
        this.$emit('open')
        const vm = this
        this.selected = false
        this.results.forEach(function(item, index) {
          if (item.selected) {
            vm.selected = true
            return false
          }
        })
      } else {
        this.validate()
        this.$emit('close')
      }
    },
    isFocus: function () {
      if (this.isFocus) {
        this.$emit('focus')
      } else {
        this.$emit('blur')
      }
    },
    selectedIndex: function () {
      const itemClass = this.isMultiple ? '.multiple-select' : '.autocomplete-result'
      if (this.$refs.resultList.querySelectorAll(itemClass).length === 0) {
        return
      }
      // if (this.isNested && this.search.trim() !== '') {
      //   itemClass = 'li>.multiple-select'
      // }

      if (this.$refs.resultList.querySelectorAll('.selected').length === 0) {
        this.$refs.resultList.querySelectorAll(itemClass)[0].classList.add('selected')
      } else {
        this.$refs.resultList.querySelectorAll('.selected')[0].classList.remove('selected')
        if (this.$refs.resultList.querySelectorAll(itemClass)[this.selectedIndex]) {
          const selectedItem = this.$refs.resultList.querySelectorAll(itemClass)[this.selectedIndex]
          selectedItem.classList.add('selected')
          // update scoll bar
          let itemTop = selectedItem.offsetTop

          if (selectedItem.parentElement.classList.contains('child')) {
            itemTop = selectedItem.parentElement.offsetTop
          }
          const scrollTop = this.$refs.resultList.scrollTop
          const listHeight = this.$refs.resultList.clientHeight
          const itemHeight = selectedItem.clientHeight
          if (itemTop < scrollTop || itemTop + itemHeight > scrollTop + listHeight) {
            this.$refs.resultList.scrollTo(0, itemTop)
          }
        }
      }
    },
    initData: function(v, ov) {
      this.initInputData()
      if (!v) {
        this.selectedResult = []
      }
    }
  },
  created() {
    this.clearList()
    this.search = this.inputProps && this.inputProps.value ? this.inputProps.value : ''
  },
  methods: {
    generateKey(prefix, id) {
      let key = prefix + '-' + this.inputProps.id + '-' + id
      if (this.search !== '') {
        key += '-searched'
      }
      return key
    },
    initInputData: async function() {
      const vm = this
      const inputVal = this.inputProps.value
      if (process.client && inputVal !== null) {
        if (inputVal && this.isJobalert) {
          // match multiple + not nested
          this.selectedResult = []
          if (!vm.isNested) {
            this.autoCompleteData.forEach(function(e1, i1, a1) {
              const idx = inputVal.findIndex(i => i.toString() === e1.id.toString()) > -1
              if (idx) {
                e1.selected = true
                if (vm.selectedResult.length === 0) {
                  vm.selectedResult.push(e1)
                }
                if (vm.selectedResult.length > 0) {
                  const idx2 = vm.selectedResult.findIndex(i => i.id === e1.id) === -1
                  if (idx2) {
                    vm.selectedResult.push(e1)
                  }
                }
              }
            })
            vm.search = inputVal.length === 0 ? '' : await vm.selectedResult[0].desc
            return
          }
          // match multiple + nested
          if (vm.isNested) {
            this.autoCompleteData.forEach(function(e1) {
              const idx = inputVal.findIndex(i => i === e1.id) > -1
              if (idx) {
                e1.selected = true
                vm.selectedResult.push(e1)
                // vm.forceUpdate(e1.id, true)
              } else {
                e1.sub.forEach(function(e2) {
                  const idx2 = inputVal.findIndex(i => i === e2.id) > -1
                  if (idx2) {
                    e2.selected = true
                    vm.selectedResult.push(e2)
                    // if (vm.selectedResult.length === 0) {
                    //   vm.selectedResult.push(e2)
                    // }
                    // if (vm.selectedResult.length > 0) {
                    //   idx = vm.selectedResult.findIndex(i => i.id === e2.id) === -1
                    //   if (idx) {
                    //     vm.selectedResult.push(e2)
                    //   }
                    // }
                  }
                })
              }
            })
            vm.search = inputVal.length === 0 || vm.selectedResult.length === 0 ? '' : await vm.selectedResult[0].desc
          }
          return
        }
        // data = location / 3d array
        if (vm.threeDArray) {
          vm.autoCompleteData.forEach(function(i) {
            i.sub.forEach(function(i2) {
              if (!i2.sub && inputVal === i2.id) {
                console.log(i2)
                vm.search = i2.desc
              } else if (i2.sub) {
                i2.sub.forEach(function(i3) {
                  if (inputVal === i3.id) {
                    vm.search = i3.desc
                  }
                })
              }
            })
          })
          return
        }
        // data = match string id
        if (vm.stringId) {
          vm.autoCompleteData.forEach(function(i) {
            if (!i.sub) {
              if (inputVal.toString() === i.id.toString()) {
                vm.search = i.desc
              }
              return
            }
            if (i.sub) {
              i.sub.forEach(function(i2) {
                if (inputVal.toString() === i2.id.toString()) {
                  vm.search = i2.desc
                }
              })
            }
          })
          return
        }
        // data = match number id
        if (typeof inputVal === 'number') {
          vm.autoCompleteData.forEach(function(i) {
            if (inputVal === i.id) {
              vm.search = i.desc
            }
          })
          return
        }
        // data = String
        if (typeof inputVal === 'string') {
          vm.search = inputVal
        }
      }
    },
    replaceStr: function(v) {
      if (v !== null && v !== '') {
        return v.replace(/\D/g, '')
      }
    },
    validate: function () {
      if (this.matchOnly && !this.threeDArray && !this.isNested) {
        const vm = this
        // clear not matched search keyword
        let matched = false
        this.originalList.forEach(function(item, index) {
          if (vm.search.toString().toLowerCase() === item.desc.toLowerCase()) {
            matched = item
            return true
          }
          if (item.selected) {
            matched = item
            return true
          }
        })
        if (!matched) {
          this.search = ''
        } else {
          this.search = matched.desc
        }
      }
    },
    forceUpdate: function(id, checked) {
      const cb = document.querySelector('#checkbox-' + this.inputProps.id + '-' + id)
      if (cb !== null) {
        cb.checked = checked
      }
      const cbs = document.querySelector('#checkbox-' + this.inputProps.id + '-' + id + '-searched')
      if (cbs !== null) {
        cbs.checked = checked
      }
    },
    clearList: function() {
      const vm = this
      this.originalList = []
      if (this.autoCompleteData.length > 0 && !this.searchBox) { // Set Default
        this.autoCompleteData.forEach(function(item, index) {
          item.search = false
          vm.originalList.push(item)
        })
      }
      if (!this.isJobalert) {
        this.results = this.originalList
      }
    },
    confirmHandle: function() {
      if (!this.selected) {
        return
      }
      this.isOpen = false
      this.isFocus = false
    },
    showLabel: function(label) {
      if (label === undefined) { return false }
      if (label !== this.currentLabel) {
        this.currentLabel = label
        return true
      }
      return false
    },
    keydownHandle: function (event) {
      if (event.key === 'Tab') {
        this.closeList()
      }
    },
    keyupHandle: function (event) {
      if (event.key === 'Enter') {
        if (this.selectedIndex < 0 && !this.isMultiple) {
          this.$emit('enter', this.search.trim())
          this.closeList()
          return
        }
        if (this.isMultiple && this.selectedIndex >= 0 && (this.results[this.selectedIndex] || this.isNested)) {
          const vm = this
          if (this.isNested) {
            if (this.search.trim() === '') {
              const orderedList = []
              this.results.forEach(function(item, i) {
                orderedList.push(item)
                item.sub.forEach(function(sub, j) {
                  orderedList.push(sub)
                })
              })
              orderedList.forEach(function(item, i) {
                if (i === vm.selectedIndex) {
                  item.selected = item.selected === undefined ? true : !item.selected
                  if (item.sub === undefined) {
                    vm.setResult(item, true)
                  } else {
                    vm.setResult(item)
                  }
                  return true
                }
              })
            } else {
              this.results.forEach(function(item, i) {
                if (i === vm.selectedIndex) {
                  item.selected = item.selected === undefined ? true : !item.selected
                  if (item.sub === undefined) {
                    vm.setResult(item, true)
                  } else {
                    vm.setResult(item)
                  }
                  return true
                }
              })
            }
            return
          }
          const item = this.results[this.selectedIndex]
          item.selected = !item.selected
          this.setResult(item)
          return
        }
        if (this.selectedIndex >= 0 && this.results[this.selectedIndex]) {
          // add by nick 7/8/2021 {
          if (!this.isNested) {
            this.search = this.results[this.selectedIndex].desc
            this.$emit('selected', { item: this.results[this.selectedIndex] })
            this.closeList()
            return
          }
          if (this.isNested) {
            const getResEle = this.$refs.resultList.querySelector('.selected')
            const id = getResEle.getAttribute('data-set')
            const desc = getResEle.getAttribute('data-set-desc')
            if (id === null || desc === null) {
              return
            }
            this.search = desc
            this.$emit('selected', { item: { id: id, desc: desc } })
            this.closeList()
            return
          }
          // }
          if (this.selectOnly) {
            const item = Object.assign({}, this.results[this.selectedIndex])
            item.selected = true
            const obj = {}
            obj.item = item
            this.$emit('selected', obj)
          } else {
            this.search = this.results[this.selectedIndex].desc
            this.$emit('enter', this.search.trim())
          }
          this.closeList()
        }
      }
      if (event.key === 'ArrowUp' && this.isOpen) {
        if (this.selectedIndex >= 0) {
          this.selectedIndex -= 1
        }
      }
      if (event.key === 'ArrowDown' && this.isOpen) {
        let maxLength = this.results.length
        if (this.isNested && this.search.trim() === '') {
          maxLength = 0
          this.results.forEach(function(item, index) {
            maxLength += 1
            if (item.sub) {
              item.sub.forEach(function(sub, k) {
                maxLength += 1
              })
            }
          })
        }
        if (this.selectedIndex >= -1 && this.selectedIndex < maxLength - 1) {
          this.selectedIndex += 1
        }
      }
    },
    jobalert_emptySearch: function() {
      if (this.isJobalert) {
        this.search = ''
        this.results = JSON.parse(JSON.stringify(this.autoCompleteData))
        // const vm = this
        const inputVal = this.inputProps.value
        this.results.forEach(function(e1) {
          if (inputVal) {
            const idx = inputVal.findIndex(i => i === e1.id.toString()) > -1
            if (idx) {
              e1.selected = true
            } else if (e1.sub !== undefined) {
              e1.sub.forEach(function(e2) {
                const idx2 = inputVal.findIndex(i => i === e2.id) > -1
                if (idx2) {
                  e2.selected = true
                }
              })
            }
          }
        })
        // const vm = this
        // if (!this.isNested) {
        //   this.results.forEach(function(i, k, a) {
        //     const idx = vm.selectedResult.find(e => e.id === i.id)
        //     if (idx !== undefined) {
        //       if (idx.id === i.id) {
        //         i.selected = true
        //       }
        //     }
        //   })
        // } else {
        //   // this.results.forEach(function(i, k, a) {
        //   //   if (i.sub) {
        //   //     i.sub.forEach(function(i2, k2, a2) {
        //   //       const idx = vm.selectedResult.find(e => e.id === i2.id)
        //   //       if (idx) i2.selected = true
        //   //     })
        //   //   }
        //   // })
        // }
      }
    },
    jobalert_showSearch: function() {
      if (this.isJobalert) {
        if (this.selectedResult.length > 0) {
          this.search = this.selectedResult[0].desc
        }
      }
    },
    closeList() {
      this.isFocus = false
      this.selectedIndex = -1
      this.isOpen = false
    },
    onChange() {
      this.isOpen = true
      this.$emit('input', this.search)
      if (this.search === '' && !this.isJobalert) {
        this.$emit('clear')
      }
      if (this.source !== '') {
        this.resourceSearch(this.source + this.search)
      } else {
        this.selectedIndex = -1
        if (!this.isJobalert) {
          this.filterResults()
        } else {
          this.jobalertFilterResults('change')
        }
      }
    },
    filterResults() {
      const vm = this
      if (!this.isMultiple) {
        this.selectedIndex = -1
      }
      // reset for label
      let label = ''
      if (this.search.toString().trim() === '') {
        if (this.source !== '') {
          this.clearList()
          return
        }
        this.originalList.forEach(function(item, index) {
          item.search = false
          if (item.label !== undefined && item.label !== label) {
            label = item.label
          } else {
            item.label = false
          }
        })
        this.results = this.originalList
        return
      }
      const search = this.search.toString().toLowerCase().trim().replace(/\\/g, '')
      const reg = new RegExp('(^|\\s|,)' + search, 'i')
      const list = []
      const finalList = (this.source === '' || this.concat) ? this.originalList : []
      // validate and concat search list
      this.searchList.forEach(function(item, index) {
        let exist = false
        finalList.forEach(function(existItem) {
          if (existItem.desc === item.desc) {
            existItem.alias = item.alias
            exist = true
            return false
          }
        })
        if (!exist) {
          finalList.push(item)
        }
      })
      finalList.forEach(function(item, index) {
        if (reg.test(item.desc)) {
          // hide main item when search
          if (vm.searchBox) {
            let desc = item.desc
            const matched = item.desc.match(reg)
            let remain = 0
            if (matched.length > 0) {
              const remainStr = desc.replace(matched[0].trim(), '')
              const next = desc.indexOf(matched[0].trim()) + matched[0].trim().length
              if (next === desc.length || desc.charAt(next) === ' ') {
                remain = 0
              } else {
                remain = remainStr.length
              }
              desc = desc.replace(matched[0].trim(), '<u>' + matched[0].trim() + '</u>')
            }
            item.index = remain
            if (item.label !== undefined && item.label !== label) {
              label = item.label
            } else {
              item.label = false
            }
            list.push(item)
          }
        } else if (item.alias !== undefined) {
          if (reg.test(item.alias)) {
            item.search = item.desc
            list.push(item)
          } else {
            item.search = false
          }
        } else {
          item.search = false
        }
        if (typeof (item.sub) === 'object') {
          const subList = item.sub
          Object.keys(subList).forEach(function (key) {
            const sub = subList[key]
            if (reg.test(sub.desc)) {
              sub.issub = true
              let desc = sub.desc
              let remain = 0
              const matched = sub.desc.match(reg)
              if (matched.length > 0) {
                const remainStr = desc.replace(matched[0].trim(), '')
                const next = desc.indexOf(matched[0].trim()) + matched[0].trim().length

                if (next === desc.length || desc.charAt(next) === ' ') {
                  remain = 0
                } else {
                  remain = remainStr.length
                }
                desc = desc.replace(matched[0].trim(), '<u>' + matched[0].trim() + '</u>')
              }
              sub.search = desc
              sub.index = remain
              list.push(sub)
            } else {
              sub.issub = false
              sub.search = false
            }
            if (typeof (sub.sub) === 'object') {
              const subList2 = sub.sub
              Object.keys(subList2).forEach(function (key) {
                const sub2 = subList2[key]
                if (reg.test(sub2.desc)) {
                  sub2.issub = true
                  let desc = sub2.desc
                  let remain = 0
                  const matched = sub2.desc.match(reg)
                  if (matched.length > 0) {
                    const remainStr = desc.replace(matched[0].trim(), '')
                    const next = desc.indexOf(matched[0].trim()) + matched[0].trim().length

                    if (next === desc.length || desc.charAt(next) === ' ') {
                      remain = 0
                    } else {
                      remain = remainStr.length
                    }
                    desc = desc.replace(matched[0].trim(), '<u>' + matched[0].trim() + '</u>')
                  }
                  sub2.search = desc
                  sub2.index = remain
                  list.push(sub2)
                } else {
                  sub2.issub = false
                  sub2.search = false
                }
              })
            }
          })
        }
      })
      if (!this.isJobalert) {
        this.results = list
      }
    },
    jobalertFilterResults(change) {
      if (!this.isMultiple) {
        this.selectedIndex = -1
      }
      // reset for label
      let label = ''
      if (!change) {
        if (this.search.toString().trim() === '') {
          if (this.source !== '') {
            this.clearList()
            return
          }
          this.results.forEach(function(item, index) {
            item.search = false
            if (item.label !== undefined && item.label !== label) {
              label = item.label
            } else {
              item.label = false
            }
          })
          this.results = this.originalList
          return
        }
      }
      const search = this.search.toString().toLowerCase().trim().replace(/\\/g, '')
      const reg = new RegExp('(^|\\s|,)' + search, 'i')
      const list = []
      const finalList = (this.source === '' || this.concat) ? this.results : []
      // validate and concat search list
      this.searchList.forEach(function(item, index) {
        let exist = false
        finalList.forEach(function(existItem) {
          if (existItem.desc === item.desc) {
            existItem.alias = item.alias
            exist = true
            return false
          }
        })
        if (!exist) {
          finalList.push(item)
        }
      })
      finalList.forEach(function(item, index) {
        if (reg.test(item.desc)) {
          let desc = item.desc
          const matched = item.desc.match(reg)
          let remain = 0
          if (matched.length > 0) {
            const remainStr = desc.replace(matched[0].trim(), '')
            const next = desc.indexOf(matched[0].trim()) + matched[0].trim().length
            if (next === desc.length || desc.charAt(next) === ' ') {
              remain = 0
            } else {
              remain = remainStr.length
            }
            desc = desc.replace(matched[0].trim(), '<u>' + matched[0].trim() + '</u>')
          }
          item.search = desc
          item.index = remain
          if (item.label !== undefined && item.label !== label) {
            label = item.label
          } else {
            item.label = false
          }
          list.push(item)
        } else if (item.alias !== undefined) {
          if (reg.test(item.alias)) {
            item.search = item.desc
            list.push(item)
          } else {
            item.search = false
          }
        } else {
          item.search = false
        }
        if (typeof (item.sub) === 'object') {
          const subList = item.sub
          Object.keys(subList).forEach(function (key) {
            const sub = subList[key]
            if (reg.test(sub.desc)) {
              sub.issub = true
              let desc = sub.desc
              let remain = 0
              const matched = sub.desc.match(reg)
              if (matched.length > 0) {
                const remainStr = desc.replace(matched[0].trim(), '')
                const next = desc.indexOf(matched[0].trim()) + matched[0].trim().length

                if (next === desc.length || desc.charAt(next) === ' ') {
                  remain = 0
                } else {
                  remain = remainStr.length
                }
                desc = desc.replace(matched[0].trim(), '<u>' + matched[0].trim() + '</u>')
              }
              sub.search = desc
              sub.index = remain
              list.push(sub)
            } else {
              sub.issub = false
              sub.search = false
            }
            if (typeof (sub.sub) === 'object') {
              const subList2 = sub.sub
              Object.keys(subList2).forEach(function (key) {
                const sub2 = subList2[key]
                if (reg.test(sub2.desc)) {
                  sub2.issub = true
                  let desc = sub2.desc
                  let remain = 0
                  const matched = sub2.desc.match(reg)
                  if (matched.length > 0) {
                    const remainStr = desc.replace(matched[0].trim(), '')
                    const next = desc.indexOf(matched[0].trim()) + matched[0].trim().length

                    if (next === desc.length || desc.charAt(next) === ' ') {
                      remain = 0
                    } else {
                      remain = remainStr.length
                    }
                    desc = desc.replace(matched[0].trim(), '<u>' + matched[0].trim() + '</u>')
                  }
                  sub2.search = desc
                  sub2.index = remain
                  list.push(sub2)
                } else {
                  sub2.issub = false
                  sub2.search = false
                }
              })
            }
          })
        }
      })
      // if (!this.isJobalert) {
      this.results = list
      // }
    },
    clearInput() {
      const vm = this
      this.search = ''
      this.selectedResult = []
      if (this.source) {
        this.searchList = []
        this.$refs.searchBox.focus()
      }
      if (this.isMultiple) {
        this.results.forEach(function(i) {
          i.selected = false
          if (vm.isNested) {
            if (i.sub) {
              i.sub.forEach(function(i2) {
                i2.selected = false
              })
            }
          }
        })
      }
      this.$emit('clear')
      this.isJobalert ? this.jobalertFilterResults() : this.filterResults()
    },
    resourceSearch: debounce(function (url) {
      if (!this.search) {
        this.clearList()
        return
      }
      this.loading = true
      this.request(url)
    }, 200),
    request (url) {
      const vm = this
      const promise = fetch(url, {
        method: this.method,
        headers: new Headers({
          Referrer: window.location.href
        })
      })
      return promise
        .then((response) => {
          if (response.ok) {
            this.error = null
            return response.json()
          }
          throw new Error('Network response was not ok.')
        })
        .then((response) => {
          response.forEach(function(item, index) {
            if (!vm.originId) {
              item.id = Math.floor(Date.now()) - item.id
            }
            item.desc = item.suggestion
            // item.id = Math.floor(Date.now()) + item.id
            // for no result tooltips
            if (item.type === 'disabled') {
              item.title = 'No jobs found under this tag with your current criteria'
            }
          })
          vm.searchList = response
          vm.loading = false
          if (!vm.isJobalert) vm.filterResults()
        })
        .catch((error) => {
          vm.error = error.message
          vm.loading = false
        })
    },
    setResult(result, isChild) {
      // unique parent / child
      const vm = this
      if (this.isMultiple) {
        if (!this.isJobalert) {
          if (isChild) {
            if (result.selected) {
              Object.keys(vm.results).forEach(function (key) {
                const childList = vm.results[key].sub
                if (childList === undefined) {
                  return false
                }
                Object.keys(childList).forEach(function (k) {
                  if (childList[k].id === result.id) {
                    vm.results[key].selected = false
                    return false
                  }
                })
              })
            }
          }
        }
        // for normal input select
        if (this.isJobalert) {
          if (!this.isNested) {
            if (result.selected) {
              this.selectedResult.push(result)
            } else {
              const removeItem = this.selectedResult.findIndex(i => i.id === result.id)
              this.selectedResult.splice(removeItem, 1)
            }
          }
          // for nested input select
          if (this.isNested) {
            if (result.sub !== undefined) {
              this.results.forEach(function(item, i) {
                if (item.id === result.id) {
                  item.sub.forEach(function(sub) {
                    sub.selected = false
                    // dont know why source change cannot affect checkbox, force update
                    vm.forceUpdate(sub.id, false)
                  })
                }
              })
            }
            if (result.sub === undefined) {
              // const parent = false
              vm.results.forEach(function(item, i) {
                if (item.sub !== undefined) {
                  item.sub.forEach(function(sub) {
                    if (sub.id === result.id) {
                      item.selected = false
                      // dont know why source change cannot affect checkbox, force update
                      vm.forceUpdate(item.id, false)
                      return false
                    }
                  })
                }
              })
              // console.error(parent)
              // if (parent) {
              //   parent.selected = false
              // }
            }
          }
          this.selectedResult = []
          this.results.forEach(function(item, i) {
            if (item.selected) {
              vm.selectedResult.push(item)
            } else if (item.sub !== undefined) {
              item.sub.forEach(function(sub, j) {
                if (sub.selected) {
                  vm.selectedResult.push(sub)
                }
              })
            }
          })
          this.$emit('selected', this.selectedResult)
          return
        }
      }
      let count = 0
      Object.keys(vm.autoCompleteData).forEach(function (key) {
        if (vm.autoCompleteData[key].selected) {
          count++
        } else if (vm.autoCompleteData[key].sub !== undefined) {
          vm.autoCompleteData[key].sub.forEach(function (item, index) {
            if (item.selected) {
              count++
            }
          })
        }
      })
      this.selected = count > 0
      if (!this.initData) {
        if (count >= this.limit && !this.isJobalert) {
          vm.isOpen = false
          this.closeList()
        }
      }
      const item = Object.assign({}, result)
      if (!this.isMultiple) {
        item.selected = true
      }
      const obj = {}
      obj.item = item
      // this.$gtm.pushEvent({ event: 'inputFilter', queryString: JSON.stringify(Object.assign(obj, { mainKey: vm.checkboxKey })) })
      this.$emit('selected', obj)
      if (!this.selectOnly) {
        this.search = item.desc
      }
      if (!this.isMultiple) {
        this.closeList()
      } else {
        // this.$refs.searchBox.focus()
      }
    }
  }
}
</script>
<style lang="scss">
  label.more-label {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: #f0f0f0;
    padding: 0 10px;
    border-radius: 5px;
    color: #808080;
    box-shadow: -4px 0px 4px #fff;
  }
  .fa-angle-down {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 17px;
    color: #999;
  }
</style>
