<template>
  <div :class="[page ? `page-${page}` : '', 'lang-' + lang]">
    <SearchNavBar />
    <div id="menu-padding" />
    <div :style="wrapperStyle" class="cpj-wrapper">
      <nuxt />
    </div>
    <div id="cookie-consent" v-if="showcookie">
      <i class="fas fa-exclamation-circle" />
      <p v-html="cpLang('global.cookieprompt')" class="cookie-msg" />
      <button @click="removeCookieMsg" class="btn">
        {{ cpLang('global.accept') }}
      </button>
    </div>
    <Footer />
    <img v-if="tracker" :src="tracker" width="1" height="1" style="displayloggedIn:none">
  </div>
</template>

<script>
import SEO from './seo'
import Footer from '~/components/layout/Footer'
import SearchNavBar from '~/components/global/SearchNavBar'
// import commonFunc from '~/assets/js/commonHelper.js'

export default {
  components: {
    SearchNavBar,
    Footer
  },
  data: function() {
    return {
      tracker: false,
      page: '',
      lang: this.$cpCookie.get('revampLang') ? this.$cpCookie.get('revampLang') : 'th',
      wrapperStyle: {
        minHeight: 'auto'
      },
      showcookie: false,
      mainhost: process.env.MAINHOST
    }
  },
  watch: {
    $route() {
      this.page = this.$route.name
      this.trackfbapi()
    }
  },
  head() {
    return {
      ...SEO.head,
      title: this.cpLang('global.default_title')
    }
  },
  beforeMount() {
    this.$fb.enable()
  },
  // middleware: ['block'],
  mounted: function () {
    this.initCookiePrompt()
    this.page = this.$route.name
    // exclude pages
    const elements = ['.page-position-search']
    elements.forEach(function (item) {
      if (document.querySelectorAll(item).length > 0) {
        return false
      }
    })
    window.addEventListener('resizeDefaultWrapper', this.resizeWrapper)
    window.addEventListener('resize', this.resizeWrapper)
    this.resizeWrapper()
    /* eslint-disable */
    var useSSL = 'https:' == document.location.protocol
    var src = (useSSL ? 'https:' : 'http:') + '//securepubads.g.doubleclick.net/tag/js/gpt.js'
    var script = document.createElement('script')
    script.src = src
    script.async = 'async'
    document.head.appendChild(script)
    //add cp tracker
    if(this.$route.query.utm_source && this.$route.query.utm_medium && this.$route.query.utm_campaign && this.$route.query.utm_term){
      this.tracker = process.env.BASE_API_URL + 'track?utm_source=' + this.$route.query.utm_source + '&utm_medium=' + this.$route.query.utm_medium + '&utm_campaign=' + this.$route.query.utm_campaign + '&utm_term=' + this.$route.query.utm_term + '&utm_content=' + this.$route.query.utm_content
    }
  },
  methods: {
    async trackfbapi() {
      const params = {
        route: this.$route,
        userData: this.$store.$auth.user
      }

      await this.$axios.post(`${this.mainhost}fbcv`, params)
      console.log('done')
    },
    initCookiePrompt: function() {
      if (!this.$cpCookie.get('cookie_consent')) {
        this.showcookie = true
      }
    },
    removeCookieMsg: function() {
      this.$cpCookie.set('cookie_consent', 'show', {
        maxAge: 60 * 60
      })
      this.showcookie = false
    },
    resizeWrapper: function () {
      const vm = this
      // resize cpj-wrapper make footer at the page bottom
      const windowHeight = window.innerHeight
      if (windowHeight > document.body.scrollHeight && document.querySelector('#menu-padding') !== undefined && document.querySelector('.cpj-footer') !== undefined) {
        const wrapperHeight = windowHeight - document.querySelector('#menu-padding').offsetHeight - document.querySelector('.cpj-footer').offsetHeight
        vm.wrapperStyle.minHeight = wrapperHeight + 'px!important'
        document.querySelector('body').classList.remove('scroll-down')
      } else {
        vm.wrapperStyle.minHeight = 'auto!important'
      }
    }
  }
}
</script>

<style lang="scss">
  #cookie-consent{
    display: flex;
    .cookie-msg{
      a{
        color: #fff;
        text-decoration: underline;
      }
    }
    .btn{
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50px;
      font-size: 14px;
      width: 200px;
      /* padding: 0 50px; */
    }
  }
</style>