export default function (context) {
  const userAgent = process.server ? context.req.headers['user-agent'] : navigator.userAgent
  // redirect mobile to mdot
  if (/iPhone|iPod|Android|kindle|Windows Phone/i.test(userAgent)) {
    let mobileHost = 'https://m.cpjobs.com/hk'
    if (!/www/i.test(process.env.cp_host)) {
      mobileHost = 'https://alidevm-ts.cpjobs.com/hk'
    }
    if (context.req !== undefined) {
      const query = Object.keys(context.req.query).map(function (key) {
        return key + '=' + context.req.query[key]
      })
      let url = mobileHost + context.req.path
      if (query.length) {
        url += '?' + query.join('&')
      }
      if (/www/i.test(process.env.cp_host)) {
        // only effects on DEV
        context.redirect(url)
      }
    }
  }
}
