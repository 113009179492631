<template>
  <div class="row job-number">
    <div :class="{'selected':selected ==='recommended_jobs'}" class="cs-col-2">
      <div class="m-auto bg-white">
        <router-link to="/myjob">
        <h4>{{ $auth.user.statistics.recommendedjobcount }}</h4>
        <span class="min-content">{{ cpLang('profile.recommended_jobs') }}</span>
        </router-link>
      </div>
    </div>
    <!-- <div id="job-invitation" :class="{'selected':selected ==='job_invitations'}" class="cs-col-2">
      <div class="m-auto bg-white">
        <router-link to="/myjob/invitation">
        <h4 v-html="statistics.jobinvitationcount > 99 ? `99<span class='plus-99'>+</span>` : statistics.jobinvitationcount" />
        <span>{{ cpLang('profile.invited_jobs') }}</span>
        </router-link>
      </div>
    </div> -->
    <div :class="{'selected':selected ==='job_alert'}" class="cs-col-2">
      <div class="m-auto bg-white">
        <router-link to="/myjob/jobalert">
        <h4 v-html="$auth.user.statistics.jobalertcount > 99 ? `99<span class='plus-99'>+</span>` : $auth.user.statistics.jobalertcount" />
        <span>{{ cpLang('profile.job_alerts') }}</span>
        </router-link>
      </div>
    </div>
    <div :class="{'selected':selected ==='saved_jobs'}" class="cs-col-2">
      <div class="m-auto bg-white">
        <router-link to="/myjob/savedjob">
        <h4 v-html="$auth.user.statistics.bookmarkcount > 99 ? `99<span class='plus-99'>+</span>` : $auth.user.statistics.bookmarkcount" />
        <span>{{ cpLang('profile.saved_jobs') }}</span>
        </router-link>
      </div>
    </div>
    <div :class="{'selected':selected ==='applied_jobs'}" class="cs-col-2">
      <div class="m-auto bg-white">
        <router-link to="/myjob/appliedjob">
        <h4 v-html="statistics.applicationcount > 99 ? `99<span class='plus-99'>+</span>` : statistics.applicationcount" />
        <span>{{ cpLang('profile.applied_jobs') }}</span>
        </router-link>
      </div>
    </div>
    <div id="settingSvg" v-if="isSearchNav" class="cs-col-2">
      <div class="m-auto bg-white">
      <router-link to="/myprofile/setting">
        <svg xmlns="http://www.w3.org/2000/svg" width="53.418" height="42.733" viewBox="0 0 53.418 42.733">
          <path class="a" d="M529.112,728.3a9.745,9.745,0,0,0,0-3.555l2.154-1.244a.6.6,0,0,0,.276-.709A12.486,12.486,0,0,0,528.764,718a.611.611,0,0,0-.752-.117l-2.154,1.243a9.787,9.787,0,0,0-3.081-1.778v-2.489a.608.608,0,0,0-.476-.592,12.626,12.626,0,0,0-5.526,0,.61.61,0,0,0-.476.592v2.487a9.811,9.811,0,0,0-3.081,1.778l-2.154-1.243a.611.611,0,0,0-.752.118,12.537,12.537,0,0,0-2.778,4.79.618.618,0,0,0,.276.709l2.154,1.244a9.846,9.846,0,0,0,0,3.555l-2.154,1.243a.608.608,0,0,0-.276.709,12.589,12.589,0,0,0,2.778,4.79.606.606,0,0,0,.752.118l2.154-1.244a9.806,9.806,0,0,0,3.081,1.778v2.487a.608.608,0,0,0,.476.592,12.557,12.557,0,0,0,5.526,0,.606.606,0,0,0,.476-.592v-2.485a9.783,9.783,0,0,0,3.081-1.778l2.154,1.244a.611.611,0,0,0,.752-.118,12.484,12.484,0,0,0,2.778-4.79.614.614,0,0,0-.276-.709Zm-9.557,2.271a4.049,4.049,0,1,1,4.049-4.049,4.049,4.049,0,0,1-4.049,4.049Z" transform="translate(-478.153 -697.135)" /><path class="b" d="M512.466,727.452a10.683,10.683,0,1,0-10.683-10.683A10.682,10.682,0,0,0,512.466,727.452Zm16.794,18.907c-.192-.1-.385-.214-.568-.325l-.66.385a3.276,3.276,0,0,1-4.049-.609,15.229,15.229,0,0,1-3.354-5.81,3.267,3.267,0,0,1,1.5-3.814l.66-.385q-.013-.323,0-.641l-.66-.384a3.263,3.263,0,0,1-1.5-3.814c.075-.241.184-.485.267-.726-.316-.026-.626-.1-.951-.1h-1.391a14.525,14.525,0,0,1-12.179,0h-1.387a11.224,11.224,0,0,0-11.22,11.2v3.472a4.008,4.008,0,0,0,4.006,4.006h29.383a3.991,3.991,0,0,0,2.271-.71,3.27,3.27,0,0,1-.167-.985Z" transform="translate(-493.768 -706.085)" />
        </svg>
        <span>{{ cpLang('profile.settings') }}</span>
      </router-link>
      </div>
      <a @click="$auth.logout()" class="btn show-on-m">{{ cpLang('menu.logout') }}</a>
    </div>
&nbsp;
</div>
  </div>
</template>
<script>
export default {
  props: {
    selected: {
      type: String,
      default: ''
    },
    isSearchNav: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      statistics: []
    }
  },
  mounted() {
    if (this.$auth.loggedIn) {
      this.statistics = this.$auth.user.statistics
    }
    window.addEventListener('loadStat', this.$auth.fetchUser())
  },
  methods: {
  }
}
</script>
<style lang='scss'>
svg{
  .a{
    fill:#009ae0;
  }
  .b{
    fill:#003c8b;
  }
}

.job-number{
  clear: both;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  div{
    h4{
      font-size: 35px;
      color:#0081BE;
      font-weight: 300;
      position: relative;
      display: flex;
      width: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      font-weight: 500;
      span{
          display: flex;
        align-items: center;
        // position: absolute;
        // bottom: 40%;
        // left: 50%;
        // margin-left: 35px !important;
        font-size: 40px !important;
        color: #2b59c3 !important;
      }
    }
    span{
      line-height: 1.2;
      font-size: 12px;
      display: inline-block;
      vertical-align: top;
      font-weight: bold;
      color:#0081BE!important;
      // height: 32px;
    }
  }
  .cs-col-2{
    flex:0 0 10%;
    margin: 10px 10px;
    width: 30%;
    &.selected{
      .m-auto.bg-white{
        box-shadow: 0 0 10px #0EA5BF;
      }
    }
    .m-auto.bg-white{
      width:112px;
      height:112px;
      display: flex;
      align-items: center;
      justify-content: center;
      // border:1px solid #ccc;
      box-shadow: 0 0 10px #0081BE27;
      border-radius:999px;
    }
    .min-content{
      width:min-content;
    }
  }
}
.profile-info{
  .job-number{
    #job-invitation{
      display: none;
    }
    .col-1{
      // display: none;
    }
    .col-2{
      width: 20%;
      max-width: 20%;
      a{
        background:#fff;
        border-radius:50%;
        margin:0 15px 15px 0;
        text-align:center;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        height: 134px;
        width: 134px;
        transition: all .3s;
        flex-flow: column;
        padding:20px;
        &:hover{
          box-shadow:0px 0px 10px #00000029;
        }
      }
    }
  }
}
#settingSvg{
  svg{
    width: 40px;
    display: block;
    margin: auto;
  }
}
</style>
