export const state = () => ({
  sync: false
})

export const mutations = {
  syncBookmark(state, payload) {
    console.log('syncBookmark', payload)
    state.sync = true
  },
  finishUpdate(payload) {
    console.log('bookmark save/ unsaved', { payload })
  }
}

export const actions = {
  getBookmark: async function ({ commit }) {
    const items = await this.$axios.get('/member/bookmark')
    commit('syncBookmark', items) // commit = call mutations
  },
  saveBookmark: async function ({ commit }, payload) {
    const id = payload.id || '1'
    const items = await this.$axios.post('/member/bookmark/save', { posid: id })
    commit('finishUpdate', items) // commit = call mutations
  },
  unsaveBookmark: async function ({ commit }, payload) {
    const id = payload.id || '1'
    const items = await this.$axios.delete('/member/bookmark/unsave/' + id)
    commit('finishUpdate', items) // commit = call mutations
  }
}
