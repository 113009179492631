window.getCookie = function (cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
window.setCookie = function (cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}
window.showCookieMsg = function () {
  const div = document.createElement('div')
  const content = '<div id="cookie-consent"><i class="fas fa-exclamation-circle"></i><p>Getlinks uses cookies to recommend suitable content for you and anlayse user behaviour. Cookies may be placed by third parties. By closing this message and continuing to use the site you agree to cookie use by Getlinks.</p><i class="close-btn" onclick="removeCookieMsg()"></i></div>'
  div.innerHTML = content
  document.body.appendChild(div)
}
window.removeCookieMsg = function () {
  document.getElementById('cookie-consent').remove()
  window.setCookie('cookie_consent', 'show', 3600)
}
