<template>
  <div
    :class="[page ? `page-${page}` : '', 'lang-' + lang]"
    style="background-color: #FFF; height: 100vh;"
  >
    <div class="upper-bg">
      <img src="/asset/image/gl-bg/upperlang.svg">
    </div>
    <div id="menu-padding" />
    <nuxt />
    <div class="lower-bg">
      <img src="/asset/image/gl-bg/lowerlang.svg">
    </div>
  </div>
</template>

<script>
import SEO from './seo'

export default {
  data: function() {
    return {
      tracker: false,
      page: '',
      lang: this.$cpCookie.get('revampLang') ? this.$cpCookie.get('revampLang') : 'th',
      wrapperStyle: {
        minHeight: 'auto'
      },
      showcookie: false,
      mainhost: process.env.MAINHOST
    }
  },
  watch: {
    $route() {
      this.page = this.$route.name
      this.trackfbapi()
    }
  },
  head() {
    return {
      ...SEO.head,
      title: this.cpLang('global.default_title')
    }
  },
  beforeMount() {
    this.$fb.enable()
  }
}
</script>

<style lang="scss">
  .upper-bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 139px;
    img {
      object-fit: contain;
      width: 100%;
    }
    @media screen and (min-width: 768px) {
      width: 255px;
    }
    @media screen and (min-width: 1024px) {
      width: 286px;
    }
    @media screen and (min-width: 1200px) {
      width: 445px;
    }
  }
  .lower-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 187px;
    img {
      object-fit: contain;
      width: 100%;
    }
    @media screen and (min-width: 768px) {
      width: 255px;
    }
    @media screen and (min-width: 1024px) {
      width: 326px;
    }
    @media screen and (min-width: 1200px) {
      width: 645px;
    }
  }

</style>
