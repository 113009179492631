/* eslint-disable no-console */
// import axios from 'axios'

export default async ({ res, store, app, axios }) => {
  if (process.server) {
    const cookie = app.$cpCookie.getAll()
    // Object.assign(cookie, { candidateName: 'Peter' }) // Force Login
    await store.dispatch('user/fetchUser', cookie)
    // const token = app.$cpCookie.get('token')
    // if (token) {
    //   await console.log('Token detected')
    // }
    // Get Token
    // const { data } = await axios.get(process.env.get_token, { token })
    // Get User Data
    // if (data) {
    // Todo: Set Cookie
    // Save User Info
    // }
  }
}
