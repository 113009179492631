/* eslint-disable */

import Vue from 'vue'
import us from '../../lang/en-US'
import hk from '../../lang/zh-HK'
import th from '../../lang/th'
import vi from '../../lang/vi'
import cn from '../../lang/cn'
import id from '../../lang/id'
import crypto from 'crypto'

const commmonFunc = {
  cpLang(key, template, revampLangVal) {
    let returnVal = key

    const acceptLang = {
      en: 'en',
      zh: 'zh',
      th: 'th',
      vi: 'vi',
      cn: 'cn',
      id: 'id'
    }
    const file = {
      en: us,
      zh: hk,
      th: th,
      vi: vi,
      cn: cn,
      id: id
    }
    const revampLang = acceptLang[revampLangVal] || 'th'
    const langFile = file[revampLang]

    const keyArr = String(key).split('.')
    if (keyArr.length === 1) {
      // Not Array
      returnVal = langFile[key] || key
    } else {
      // Loop Array
      try {
        let dummy = langFile
        for (const idx in keyArr) {
          dummy = dummy[keyArr[idx]]
        }
        returnVal = dummy || key
      } catch (err) {
        console.log('Lang Error :', { key, err })
      }
    }
    let result = returnVal
    if (typeof (template) === 'object') {
      result = this._stringTemplate(returnVal, template)
    }
    return result
  },
  getRandomArrayElements: function (array, limit) {
    if (array.length <= limit) {
      const returnArray = JSON.parse(JSON.stringify(array))
      return returnArray
    } else {
      const tempArray = JSON.parse(JSON.stringify(array))
      const returnArray = []
      for (let i = 0; i < limit; i++) {
        const randomIndex = Math.floor(Math.random() * tempArray.length)
        returnArray.push(tempArray[randomIndex])
        tempArray.splice(randomIndex, 1)
      }
      return returnArray
    }
  },
  groupArray: function (array, subGroupLength) {
    let index = 0
    const newArray = []
    while (index < array.length) {
      newArray.push(array.slice(index, index += subGroupLength))
    }
    return newArray
  },
  validation: function(obj) {
    if (obj.validate) {
      let result = []
      var v = obj.validate
      v.forEach(function(e) {
        const field = obj[e.name]
        const vf_empty = e.validates.includes('empty') ? e.validates.includes('empty') : false
        const vf_null = e.validates.includes('null') ? e.validates.includes('null') : false
        // check empty string
        if (vf_empty) {
          if (typeof field === 'string') {
            if (field.trim() === '' || field === null ) {
              result.push({ err: `${e.name} is required, '' value`, field: e.name })
            } else {
              result.push({ err: false, field: e.name })
            }
          }
        }
        // check null object && check object[property] null / 0 / '0'
        if (vf_null) {
          if (typeof field === 'object') {
            // check property
            if (e.hasOwnProperty('property')) {
              const prop = e['property']
              if (!field[prop] || field[prop] === null || field[prop] === 0 || field[prop] === '0') {
                result.push({ err: `${e.name} is required, got null`, field: e.name })
                return
              }
            }
            if (field === null) {
              result.push({ err: `${e.name} is required, got null`, field: e.name })
            } else {
              result.push({ err: false, field: e.name })
            }
          }
        }
      })
      return result
    }
  },
  getMixpanelProperty(jobObject){
    const mpFunctions = jobObject.field ? jobObject.field.map((field) => { return field.en }) : []
    const mpSubFunctions = jobObject.field ? jobObject.field.map((field) => { if (field.children) return Object.keys(field.children).map(function(child, idx) { return field.children[child].en }) }).flat() :false
    const mpJobObject = {
      'job id': jobObject.id,
      'job level': (jobObject.manageriallevel && jobObject.manageriallevel.en) || '',
      industry: jobObject.industrydesc ? jobObject.industrydesc.en : '',
      industryId: jobObject.industry ? jobObject.industry : '',
      cluster: jobObject.clusterdesc ? jobObject.clusterdesc.en : '',
      clusterId: jobObject.cluster ? jobObject.cluster : '',
      function: mpFunctions[0] || '',
      functions: mpFunctions || '',
      'sub function': mpSubFunctions[0] || '',
      'sub functions': mpSubFunctions || '',
      'post type': jobObject.posttype,
      template: jobObject.customtemplate.use_template ? 'customized' : 'regular'
    }
    // console.warn(jobObject)
    return mpJobObject
  },
  solrDecode: function (str) {
    if(str === null){
      return ''
    }
    str = str.replace('&#039;', '')
    str = str.replace('&amp;;', '&')
    str = str.replace('\&quot;;', '"')
    str = str.replace('%23', '#')
    str = str.replace('%3d;', '=')
    str = str.replace('_n_', '&')
    str = str.replace('_p_', '+')
    str = str.replace('%25;', '%')
    str = str.replace('%2B', '+')
    str = str.replace('%27', '\'')
    return str
  },
  matchPath: function(path, matchStr) {
    return matchStr.test(path)
  },
  isObject: function (mixedVar) {
    if (Array.isArray(mixedVar)) {
      return false
    } else {
      return (mixedVar !== null) && (typeof (mixedVar) === 'object')
    }
  },
  objectToArray: function objectToArray(obj) {
    const array = []
    let tempObject
    for (const key in obj) {
      tempObject = obj[key]
      if (this.isObject(obj[key])) {
        tempObject = this.objectToArray(obj[key])
      }
      array[key] = tempObject
    }
    return array
  },
  hash: function (str) {
    let hash = 0
    if (str.length === 0) {
      return hash
    }
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      hash = ((hash << 5) - hash) + char
      hash = hash & hash
    }
    return hash
  },
  isMobile: function () {
    const windowWidth = window.innerWidth
    if (windowWidth < 743) {
      return true
    }
    return false
  },
  convertCur(curNum) {
    let res = ''
    switch (curNum) {
      case '101': res = 'THB'
        break
      case '44': res = 'HKD'
        break
      case '2': res = 'USD'
        break
      case '110': res = 'VND'
        break
      case '92': res = 'SDG'
        break
      default: res = 'THD'
    }
    return res
  },
  isPortraitMobile: function () {
    const windowWidth = window.innerWidth
    if (windowWidth < 576) {
      return true
    }
    return false
  },
  getHash: function (name) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      const r = window.location.hash.substr(1).match(reg);
      if (r != null)
          return unescape(r[2]);
      return '';
  },
  cursorWait: function (show) {
    if (show || show === undefined) {
      if (!document.querySelector('#wait-overlay')) {
        const overlay = document.createElement('DIV')
        overlay.id = 'wait-overlay'
        document.body.appendChild(overlay)
      }
    } else if (document.querySelector('#wait-overlay')) {
      const overlay = document.querySelector('#wait-overlay')
      document.body.removeChild(overlay)
    }
  },
  ucwords: function (str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  },
  ucfirst: function (str) {
    if (typeof str !== 'string') {
      return ''
    }
    return str.charAt(0).toUpperCase() + str.slice(1)
  },
  extractTagSearch: function (keyword) {
    if (keyword === undefined || keyword === '') {
      return false
    }
    const reg = new RegExp(/tag:"(.*?)"/g)
    const matches = keyword.match(reg)
    if (matches) {
      const tags = []
      let originKeyword = keyword
      matches.forEach(function (item) {
        originKeyword = originKeyword.replace(item, '')
        let tag = item.replace(/^tag:"/i, '')
        tag = tag.replace(/"$/i, '')
        tags.push(encodeURIComponent(tag))
      })
      if (tags.length) {
        return {
          tag: tags.join(','),
          keyword: originKeyword.trim()
        }
      }
    }
    return false
  },
  formatJobNumber: function (number) {
    if (number <= 1000) {
      return number
    }
    return Math.floor(number / 1000) + ',000+'
  },
  slugToWords: function (slug) {
    slug = decodeURIComponent(slug)
    slug = this.solrDecode(slug)
    return slug.replace(/-/g, ' ')
  },
  showResponseError: function () {
    const ComponentClass = Vue.extend(Modal)
    const instance = new ComponentClass({
      propsData: {
        id: 'response-error',
        title: 'Unable to connect',
        showClose: false,
        content: 'The web server is temporary overloaded and can\'t process your request. Please accept our apologies for the inconveniences this might cause to you.<br>Please try to refresh the page.',
        button: [{
          label: 'Refresh this page',
          callback: this.reload
        }]
      }
    })
    instance.$mount() // pass nothing
    document.querySelector('body').appendChild(instance.$el)
  },
  reload: function () {
    window.location.reload()
  },
  isSafari: function (){
    // const ua = navigator.userAgent.toLowerCase();
    // if (ua.indexOf('safari') != -1) {
    //   if (ua.indexOf('chrome') > -1) {
    //     return false
    //   } else {
    //     return true
    //   }
    // }
    // return false
    if (navigator.userAgent.indexOf("Chrome") != -1 ) {
      return false
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      true
    }
    return false
  },
  getRecentKeyword: function (url) {
    let name = 'keyword'
    if (url !== undefined) {
      name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]')
      const regexS = '[\\?&@]' + name + '=([^&#]*)'
      const regex = new RegExp(regexS)
      const results = regex.exec(url)
      const keyword = results == null ? null : results[1].replace(/\+/g, ' ')
      if(keyword !== null && keyword.trim() ==''){
        return null
      }
      return decodeURIComponent(keyword)
    }
  },
  isSeoAlias: function (path, query) {
    const searched = this.handleAlias(path, query)
    if (searched.industry !== undefined || searched.cluster !== undefined || searched.sopt === 5 || searched.field !== undefined || searched.country !== undefined || searched.education !== undefined || searched.joblevel !== undefined || searched.employmenttype !== undefined) {
      return true
    }
    return false
  },
  checkLastItem: function(obj, fieldname, ary) {
    const last = obj[obj.length - 1]
    const resAry = []
    for (let i = 0; i < fieldname.length; i++) {
      const item = fieldname[i]
      if (last[item] !== '' || last[item]) {
        resAry.push(true)
      } else {
        resAry.push(false)
      }
    }
    if (ary) {
      return resAry
    }
    if (!ary) {
      const result = resAry.includes(false)
      if (result) {
        return false
      } else {
        return true
      }
    }
  },
  handleAlias: function (path, query) {
    let reg = new RegExp('^/industry/([^&]*)-jobs/')
    let matches = path.match(reg)
    query.search = ''
    if (matches && matches.length > 1) {
      query.industry = matches[1]
    }
    reg = new RegExp('^/cluster/([^&]*)(|/)')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.cluster = matches[1]
    }
    reg = new RegExp('^/jobs/([^&]*)/')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.field = matches[1]
    }
    reg = new RegExp('^/location/([^&]*)-jobs/')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.country = matches[1]
    }
    reg = new RegExp('^/job-level/([^&]*)-level-jobs/')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.joblevel = matches[1]
    }
    reg = new RegExp('^/work-type/([^&]*)-jobs/')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.employmenttype = matches[1]
    }
    reg = new RegExp('^/search-([^&]*)-jobs/')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.keyword = matches[1]
      query.search = matches[1].replace(/-/g, ' ')
    }
    reg = new RegExp('^/company-([^&]*)-jobs/')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.keyword = matches[1]
      query.search = matches[1].replace(/-/g, ' ')
      query.sopt = 5
    }
    reg = new RegExp('^/campaign/(.*?)?$')
    matches = path.match(reg)
    if (matches && matches.length > 1) {
      query.miscellaneous = matches[1].toUpperCase()
      query.campaign = matches[1]
    }
    return query
  },
  getParameter: function (name, url) {
    if (url !== undefined) {
      name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]')
      const regexS = '[\\?&]' + name + '=([^&#]*)'
      const regex = new RegExp(regexS)
      const results = regex.exec(url)
      return results == null ? null : results[1]
    }
  },
  getQueryFromUrl: function (url) {
    let query = url.split('?')
    if (query.length > 1) {
      query = query[query.length - 1]
    } else {
      query = url
    }
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=')
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
                    return params;
                }, {}
            )
        : {}
  },
  facebookLogin: function () {
    let loginUrl = 'social/facebooklogin'
    const event = this.getParameter('event', window.location.href)
    const joinrsvp = this.getParameter('joinrsvp', window.location.href)
    const programme = this.getParameter('programme', window.location.href)
    if (event !== null) {
      loginUrl = 'social/facebooklogin?event=' + event
    }
    if (joinrsvp !== null && event !== null && programme !== null) {
      loginUrl = 'social/facebooklogin?joinrsvp=1&event=' + event + '&programme=' + programme
    }
    loginUrl = process.env.cp_host + loginUrl
    const signWin = window.open(loginUrl, 'SignIn', 'width=620,height=400,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0')
    signWin.focus()
    return false
  },
  googleLogin: function () {
    let loginUrl = 'social/googlelogin'
    const event = this.getParameter('event', window.location.href)
    const joinrsvp = this.getParameter('joinrsvp', window.location.href)
    const programme = this.getParameter('programme', window.location.href)
    if (event !== null) {
      loginUrl = 'social/googlelogin?event=' + event
    }
    if (joinrsvp !== null && event !== null && programme !== null) {
      loginUrl = 'social/googlelogin?joinrsvp=1&event=' + event + '&programme=' + programme
    }
    loginUrl = process.env.cp_host + loginUrl
    const signWin = window.open(loginUrl, 'SignIn', 'width=620,height=400,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0')
    signWin.focus()
    return false
  },
  linkedinLogin: function () {
    let loginUrl = 'social/linkedinlogin'
    const event = this.getParameter('event', window.location.href)
    const joinrsvp = this.getParameter('joinrsvp', window.location.href)
    const programme = this.getParameter('programme', window.location.href)
    if (event !== null) {
      loginUrl = 'social/linkedinlogin?event=' + event
    }
    if (joinrsvp !== null && event !== null && programme !== null) {
      loginUrl = 'social/linkedinlogin?joinrsvp=1&event=' + event + '&programme=' + programme
    }
    loginUrl = process.env.cp_host + loginUrl
    const signWin = window.open(loginUrl, 'SignIn', 'width=620,height=400,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0')
    signWin.focus()
    return false
  },
  br2nl: function (str) {
    const regex = /<br\s*[\/]?>/gi
    if (str === null) {
      return ''
    }
    if (str !== '' ) {
      return str.replace(regex, '\n')
    } else {
      return ''
    }
  },
  isProduction: function (){
    return process.env.cp_host.indexOf('www') === -1 ? false : true
  },
  socialLogin: function (medium) {
    let loginUrl = 'social/'
    switch (medium) {
      case 'facebook':
        loginUrl += 'facebooklogin'
        break
      case 'google':
        loginUrl += 'googlelogin'
        break
      case 'linkedin':
        loginUrl += 'linkedinlogin'
        break
    }
    const event = this.getParameter('event', window.location.href)
    const joinrsvp = this.getParameter('joinrsvp', window.location.href)
    const programme = this.getParameter('programme', window.location.href)
    if (joinrsvp !== null && event !== null && programme !== null) {
      loginUrl += '?joinrsvp=1&event=' + event + '&programme=' + programme
    } else if (event !== null) {
      loginUrl += '?event=' + event
    }
    loginUrl = process.env.cp_host + loginUrl
    const signWin = window.open(loginUrl, 'SignIn', 'width=620,height=400,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0')
    signWin.focus()
    return false
  },
  languageHandler(item) {
    // return
    if (item) {
      if (typeof (item) === 'object') {
        return Object.prototype.hasOwnProperty.call(item, 'default') ? item['default'] : item['en']
      }
    }
    return item
  },
  isValidEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  parseSalary(min, max, type) {
    let result
    min = this.formatSalary(min)
    max = this.formatSalary(max)
    if (min < 0 || max < 0) {
      min = null
      max = null
    }
    if (min && !max) {
      result = `Minimum $${min}`
    } else if (!min && max) {
      result = `Maximun $${min}`
    } else if (min && max) {
      result = `$${min} - $${max}`
    } else {
      return 0
    }
    return type ? `${result} / ${type}` : result
  },
  formatSalary(number) {
    return number ? String(number).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : number
  },
  validObj: function(obj, k) {
    if (obj === null || obj === undefined) {
      return ''
    }
    for (const i in obj) {
      if (i === k) {
        return obj[k]
      }
    }
    return ''
  },
  checkStrEmpty(str) {
    if (typeof str === 'string') {
      return str.replace(/^\s+|\s+$/gm,'')
    } else if (str === null) {
      return ''
    } else {
      return str
    }
  },
  toExpiryDate(timestamp) {
    const currentTime = Math.floor(Date.now() / 1000)
    const difference = timestamp - currentTime
    const daysDifference = Math.floor(difference / 60 / 60 / 24)
    return 'Expires in ' + daysDifference + ' days'
  },
  toExiprationDate(timestamp, curLang){
    if (isNaN(timestamp)) {
      return ''
    }
    timestamp = Number(String(timestamp).slice(0, 10))
      const now = Math.ceil(new Date().getTime() / 1000)
      const gap = now - timestamp
      // console.log('cpLang toExipre:', timestamp, now, gap)

      if (gap < 60 * 60) {
        const time = Math.floor(gap / 60)
        const unit =
          time > 0
            ? this.cpLang('global.mins_ago', '', curLang)
            : this.cpLang('global.min_ago', '', curLang)
        return time + ' ' + unit
      }
      if (gap < 60 * 60 * 24) {
        const time = Math.floor(gap / (60 * 60))
        const unit =
          time > 1
            ? this.cpLang('global.hours_ago', '', curLang)
            : this.cpLang('global.hour_ago', '', curLang)
        return time + ' ' + unit
      }
      if (gap < 60 * 60 * 24 * 7) {
        const time = Math.floor(gap / (60 * 60 * 24))
        const unit =
          time > 1
            ? this.cpLang('global.days_ago', '', curLang)
            : this.cpLang('global.day_ago', '', curLang)
        return time + ' ' + unit
      }
      if (gap < 60 * 60 * 24 * 9) {
        const time = 1
        const unit = this.cpLang('global.week_ago', '', curLang)
        return time + ' ' + unit
      }
      const date = new Date(timestamp * 1000)
      /*
       * const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
       * const month = 'recruitmentday.' + monthNames[date.getUTCMonth()].toLowerCase()
       * return date.getDate() + ' ' + this.cpLang(month, '', curLang)
       */
      return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
  },
  parseExp(min, max, minKey, maxKey) {
    min = minKey ? this.languageHandler(min) : ''
    max = maxKey ? this.languageHandler(max) : ''
    if (minKey === maxKey) {
      return min
    }
    if (!minKey && maxKey) {
      return maxKey > 1 && maxKey < 16 ? `${max} or below` : max
    }
    if (minKey && (!maxKey || maxKey ==='0')) {
      return minKey > 1 ? `Minimum ${min}` : min
    }
    if (minKey >= 0 && maxKey <= 16) {
      return `${min} - ${max}`
    }
  },
  getSlug(input) {
    let val
    val = String(input).trim().toLowerCase()
    val = val.replace(/[\!@#\|\-.$%^&\/\*\)]/g,' ')
    val  = val.replace(/\s\s+/g, ' ');

    // val = val.replace(/&([a-z0-9#]{2,8});/i, '-')
    // let matches = val.replace(/[^(\u4e00-\u9fa5A-Za-z0-9)]/g, ' ').replace(/\W+/g, ' ').split(' ')
    // let res = ''
    // for (const index in matches) {
    //   if (index === 0) {
    //     res += matches[index]
    //   } else {
    //     if (matches[index] !== '') {
    //       res += matches[index] + '-'
    //     }
    //   }
    // }
    const res = val.replace(/\s+/g, '-').toLowerCase()
    return res
  },
  convertArrayToURLParam(data) {
    var out = []
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        out.push(key + '=' + encodeURIComponent(data[key]))
      }
    }
    return out.join('&')
  },
  formatDate(timestamp, format) {
    const now = Math.ceil(new Date().getTime() / 1000)
    if(isNaN(timestamp)){
      const date = new Date()
      timestamp = Math.ceil(date.getTime() /1000)
    }
    const gap = now >= timestamp ? now - timestamp : timestamp - now
    if (gap < 60 * 60) {
      const time = Math.floor(gap / 60)
      const unit = time > 0 ? this.cpLang('global.mins_ago') : this.cpLang('global.min_ago')
      return time + ' ' + unit
    }
    if (gap < 60 * 60 * 24) {
      const time = Math.floor(gap / (60 * 60))
      const unit = time > 1 ? this.cpLang('global.hours_ago') : this.cpLang('global.hour_ago')
      return time + ' ' + unit
    }
    if (gap < 60 * 60 * 24 * 7) {
      const time = Math.floor(gap / (60 * 60 * 24))
      const unit = time > 1 ? this.cpLang('global.days_ago') : this.cpLang('global.day_ago')
      return time + ' ' + unit
    }
    if (gap < 60 * 60 * 24 * 9) {
      const time = 1
      const unit = this.cpLang('global.week_ago')
      return time + ' ' + unit
    }
    const date = new Date(timestamp * 1000)
    /*
     * const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
     * const month = 'recruitmentday.' + monthNames[date.getUTCMonth()].toLowerCase()
     * if(format !== undefined){
     *   switch(format){
     *     case 'M D Y':
     *     return monthNames[date.getUTCMonth()] + ' ' + date.getDay() + ', ' + date.getFullYear()
     *     case 'M Y':
     *     return monthNames[date.getUTCMonth()] + ' ' + date.getFullYear()
     *     case 'zh':
     *     const m = date.getUTCMonth() + 1
     *     return date.getFullYear() + '年' + m + '月'
     *   }
     * }
     * return date.getDate() + ' ' + this.cpLang(month)
     */
    return date.getDate() + '-' + (date.getUTCMonth() + 1) + '-' + date.getFullYear()
  },
  calcDay(timestamp) {
    const now = Math.ceil(new Date().getTime() / 1000)
    if(isNaN(timestamp)){
      const date = new Date(timestamp)
      timestamp = Math.ceil(date.getTime() /1000)
    } else {
      const gap = now >= timestamp ? now - timestamp : timestamp - now
      const time = Math.floor(gap / (60 * 60 * 24))
      return time
    }
  },
  setLocalStorage(key,value,duration) {
    if(duration === undefined){
      duration = 300
    }
    duration = duration * 1000
    const now = new Date()
    const item = {
      value: JSON.stringify(value),
      expiry: now.getTime() + duration
    }
    localStorage.setItem(key, JSON.stringify(item))
  },
  getLocalStorage(key){
    const now = new Date()
    const item = localStorage.getItem(key)
    if (!item || item === undefined) {
      return false
    }
    const data = JSON.parse(item)
    if (data.expiry < now.getTime()) {
      // expired, clear it
      localStorage.removeItem(key)
      return false
    }
    return JSON.parse(data.value)
  },
  removeLocalStorage(key){
    localStorage.removeItem(key)
  },
  toast(msg,priority='primary'){
    if (document.getElementById('toast') !== null){
      document.getElementById('toast').remove()
    }
    clearTimeout(this.toastTimeout)
    // let html = '<div class="toast-header bg-' + priority + '">'
    // html +='<strong class="mr-auto">' + subject + '</strong>'
    // html +='<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">'
    // html +='<span aria-hidden="true" id="toast-close">&times;</span></button></div>'
    const html ='<div class="toast-body bg-' + priority + '"><i class="fas fa-info-circle"></i>' + msg + '</div>'
    const toast = document.createElement('DIV')
    toast.classList.add('toast')
    toast.id = 'toast'
    toast.innerHTML = html
    document.body.appendChild(toast)
    // document.getElementById('toast-close').onclick = this.hideToast
    const vm = this
    setTimeout(function(){
      toast.classList.add('show')
      vm.toastTimeout = setTimeout(vm.hideToast, 5000)
    },0)
  },
  hideToast(){
    const toast = document.getElementById('toast')
    if (toast !== null){
      toast.classList.remove('show')
      this.toastTimeout = setTimeout(function(){
        toast.remove()
      },300)
    }
  },
  hideMessage(){
    if (document.getElementById('prompt-message') !== null){
      document.getElementById('prompt-message').remove()
    }
  },
  message(title,content,showClose=true,type='text',callback=function(){}){
    if (document.getElementById('prompt-message') !== null){
      document.getElementById('prompt-message').remove()
    }
    let html = '<div role="document" class="modal-dialog"><div class="modal-content">'
    if (showClose) {
      html +='<button type="button" data-dismiss="modal" aria-label="Close" class="prompt-close close"><span aria-hidden="true">×</span></button>'
    }
    if (title !== false && title !== '') {
      html +='<div class="modal-header">' + title + '</div>'
    }
    if (type ==='loading') {
      content = '<div class="progress"><div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div></div>'
    }
    html +='<div class="modal-body">' + content + '</div>'
    if (showClose) {
      html +='<div class="modal-footer"><button type="button" class="btn btn-defaul prompt-close">' + this.cpLang('jd.close') + '</button></div>'
    }
    const message = document.createElement('DIV')
    message.classList.add('modal')
    message.id = 'prompt-message'
    message.innerHTML = html
    document.body.appendChild(message)
    if (showClose) {
      const vm = this
      document.querySelectorAll('.prompt-close').forEach((item) => {
        item.onclick = function (e) {
          callback()
          vm.hideMessage()
        }
      })
    }
  },
  isEmpty: function (val) {
    return (!val.trim() || val.trim().length === 0)
  },
  eleFocus: function(e) {
    document.querySelector(e).focus()
  },
  emptyFoucs: function(field, errMsg, focusEle) {
    if (field.length === 0 || field.trim() == '' ) {
      document.querySelector(`#${focusEle}`).focus()
      return {response: false, err: errMsg}
    }
    return {response: true, err: ''}
  },
  checkVal: function(v, int) {
    if (v === '0' || v === null || v === undefined) {
      return ''
    }
    if (int) {
      return parseInt(v)
    } else {
      return v
    }
  },
  getFBcvapiUserData(authUser, req ) {
    // console.log('#commonfunc-getFBcvapiUserData: ', authUser)
    const userData = {
      em: authUser ? this.hashFbCvapiData(authUser.email) : '', // hashing required
      ph: authUser ? this.hashFbCvapiData('') : '', // hashing required
      fn: authUser ? this.hashFbCvapiData(authUser.firstname) : '', // hashing required
      ln: authUser ? this.hashFbCvapiData(authUser.lastname) : '', // hashing required
      ct: authUser ? this.hashFbCvapiData('') : '', // hashing required
      st: authUser ? this.hashFbCvapiData('') : '', // hashing required
      country: authUser ? this.hashFbCvapiData('') : '', // hashing required
      client_ip_address: req && req.connection ? req.connection.remoteAddress : '',
      client_user_agent: req && req.headers ? req.headers['user-agent'] : ''
    }
    // console.log('#commonfunc-getFBcvapiUserData: ', userData)
    return userData
  },
  getFBcvapiEndpoint() {
    const PIXEL_ID = '472494684001182'
    const API_VERSION = 'v18.0'
    const TOKEN = 'EAAFNqatLooIBALHzsU3VRomU5wEUJL5JKT3wPmMFbueLB5oAMu9TGddlkGwD67pCouqZAEnINyDJqZAVklj9hydpXSY2rwNZAUzjx5qhjaqbIZCu5RaOGiPyPf3JdTgMNyKDpXo4TqAC9mnoIQ3rj4ZBU2joIqJUaDX3dUEvBIDciJUHn8MyF'
    return `https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events?access_token=${TOKEN}`
  },
  getEventData(route, userData, isSer) { // this function for facebook conversion api - ~/middleware/fb-cvapi
    // define the event name
    let evtname = 'PageView'
    if (route && route.name) {
      switch(route.name) {
        case 'position-search':
          evtname = 'Search Result Page'
          break
        case 'myjob-apply-id':
          evtname = 'Add to Cart'
          break
        case 'myjob-applicationsubmitted-id':
          evtname = 'Purchase'
          break
        default:
          break
      }
    }
    // define param details
    const param = {
      data: [
        {
          action_source: 'website',
          event_name: process.env.NODE_ENV === 'production' ? evtname : 'dev testing-' + evtname,
          event_time: Math.floor((new Date()).getTime() / 1000),
          user_data: userData,
          currency: 'USD',
          value: 0
        }
      ]
    }
    // for event name !== 'PageView'
    const extraData = {
      action_source: 'website',
      event_name: process.env.NODE_ENV === 'production' ? 'PageView' : 'dev testing-' + 'PageView',
      event_time: Math.floor((new Date()).getTime() / 1000),
      user_data: userData
    }
    if (evtname !== 'PageView') {
      param.data.push(extraData)
    }
    return param
  },
  hashFbCvapiData(data) {
    if (data) {
      const hash = crypto.createHash('sha256').update(data).digest('hex')
      return hash
    }
    return ''
  },
  validateImage(url) {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onerror = () => resolve(false);
      img.onload = () => resolve(true);
    })
  }
}

export default commmonFunc
