<template>
  <div :class="[page?`page-${page}`:'','lang-'+lang]">
    <div :style="wrapperStyle" class="cpj-wrapper">
      <nuxt />
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data: function() {
    return {
      tracker: false,
      page: this.$route.name || 'default',
      lang: this.$cpCookie.get('revampLang') ? this.$cpCookie.get('revampLang') : 'en',
      wrapperStyle: {
        minHeight: 'auto'
      }
    }
  },
  head() {
    return {
    }
  },
  mounted: function () {
  },
  methods: {
  }
}
</script>
