export const state = () => ({
  langList: [],
  countryList: []
})

export const mutations = {
  setLangList(state, items) {
    state.langList = items
  },
  setCountryList(state, items) {
    state.countryList = items
  }
}

export const actions = {
  fetchLangList: async function ({ commit }) {
    const { data } = await this.$axios.get('languages')
    commit('setLangList', data)
  },
  fetchCountryList: async function ({ commit }) {
    const { data } = await this.$axios.get('positions?extrafield=supportedcountry')
    commit('setCountryList', data.supported_country)
  }
}
