<template>
  <div class="completion-wrap">
    <client-only>
      <div v-if="$auth.user.statistics.strength !== null" class="completion">
        <div v-if="$auth.user.statistics.strength === 'done'" :class="{'card profile-card': !isNavBar}" class="done">
          <p v-if="!isNavBar" v-html="cpLang('profile.done')" class="mb-0" />
          <div v-else class="strength-info">
            <p>
              <b>{{ cpLang('profile.nav_strength_complete') }}</b>
            </p>
            <div class="progress">
              <div class="progress-bar" role="progressbar" />
            </div>
            <div :style="'left:' + strength.score + '%'" class="handle" />
          </div>
        </div>
        <div v-else-if="$auth.user.statistics.strength === 'complete'" class="strength-info">
          <img src="asset/image/logo/profile-complete.svg">
          <p v-html="strength.desc" />
        </div>
        <div v-else-if="$auth.user.statistics.strength !== 'complete' && $auth.user.statistics.strength !== 'done'" class="strength-info">
          <p>
            <b>{{ cpLang('profile.strength') }}</b>
            <b :class="$auth.user.statistics.strength" class="strength">{{ strength.name }}</b>
          </p>
          <p class="desc">
            {{ cpLang('profile.strength_desc') }}
          </p>
          <div class="progress">
            <div class="progress-bar" role="progressbar" />
          </div>
          <div :style="'left:' + strength.score + '%'" class="handle">
            <div :class="$auth.user.statistics.strength" class="strength-tips">
              <div class="triangle" />
              <p>{{ strength.desc }}</p>
              <div class="s-wrap">
                <ul>
                  <li>{{ cpLang('profile.idx_current_status') }}</li>
                  <li>{{ cpLang('profile.expertise') }}</li>
                  <li>{{ cpLang('profile.experience') }}</li>
                  <li>{{ cpLang('profile.education_history') }}</li>
                  <li>{{ cpLang('profile.expectation') }}</li>
                  <li>{{ cpLang('profile.contact_info') }}</li>
                  <li>{{ cpLang('profile.your_cvs') }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </client-only>
  </div>
</template>

<script>
export default {
  props: {
    isNavBar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    strength: function () {
      switch (this.$auth.user.statistics.strength) {
        case 'done':
          return { name: this.cpLang('profile.nav_strength_complete'), score: 80, desc: this.cpLang('profile.nav_strength_complete') }
        case 'complete':
          return { name: this.cpLang('profile.strength_complete'), score: 80, desc: this.cpLang('profile.strength_complete_desc') }
        case 'strong':
          return { name: this.cpLang('profile.strength_strong'), score: 58, desc: this.cpLang('profile.strength_strong_desc') }
        case 'medium':
          return { name: this.cpLang('profile.strength_medium'), score: 45, desc: this.cpLang('profile.strength_medium_desc') }
        case 'weak':
          return { name: this.cpLang('profile.strength_weak'), score: 10, desc: this.cpLang('profile.strength_weak_desc') }
        default:
          return { name: '', score: 10, desc: '' }
      }
    }
  }
}
</script>
<style lang="scss">
.completion{
  position: relative;
  text-align: center;
  h3{
    text-align: center;
    color:#0081BE;
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0px;
  }
  img{
    width: 132px;
  }
  .strength{
    &.weak{
      color:#E1848D !important;
    }
    &.medium{
      color:#6E6E6E !important;
    }
    &.strong{
      color:#0EA5BF !important;
    }
  }
  .handle{
    position: absolute;
    bottom:-5px;
    background-color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 0 5px #00000029;
    .strength-tips{
      display: none;
      position: absolute;
      width: 216px;
      border-radius: 20px;
      background-color: #fff;
      z-index: 100;
      left: -100px;
      top:40px;
      text-align: left;
      border: 1px solid #E1848D;
      box-shadow: 0px 3px 6px #00000029;
      &.strong{
        background: #0EA5BF;
      }
      &.medium{
        background: #6e6e6e;
      }
      .s-wrap{
        border-radius: 20px;
        background: #fff;
      }
      p{
        background-color: #E1848D ;
        width: 215px;
        margin: 0px;
        padding: 8px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        color:#fff;
        text-align: left;
        font-size: 14px;
        margin-top: -1px;
      }
      ul{
        list-style: disc;
        font-size: 12px;
        padding: 9px 8px 4px 22px;
        margin: 0px;
        li{
          font-size: 14px;
          color:#333;
          margin-bottom: 5px;
        }
      }
      .triangle{
        background: #E1848D;
        height: 18px;
        width: 18px;
        position: absolute;
        left: 50%;
        margin-left: -8px;
        top: -6px;
        transform: rotate(45deg);
        // border-left: 1px solid #d9d9d9;
        // border-top: 1px solid #d9d9d9;
      }
      &.medium{
        border: 1px solid #6e6e6e;
        p{
          background-color: #6e6e6e;
        }
        .triangle{
          background: #6e6e6e;
        }
      }
      &.strong{
        border: 1px solid #0EA5BF;
        p{
          background-color: #0EA5BF;
        }
        .triangle{
          background: #0EA5BF;
        }
      }
    }
    &:hover{
      .strength-tips{
        display: block;
      }
    }
  }
  p{
    text-align:center;
    color:#6e6e6e;
    b{
      color:#0081BE;
    }
  }
  .profile-card.w-100{
    width: 100%;
  }
}
</style>
