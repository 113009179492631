import commonFunc from '~/assets/js/commonHelper.js'

export const state = () => ({
  detail: {},
  company: {},
  companyOther: {}
})

export const mutations = {
  setDetail(state, items) {
    state.detail = items
  },
  setCompany(state, items) {
    state.company = items
  },
  setCompanyOther(state, items) {
    state.companyOther = items
  }

}

export const actions = {
  fetchDetail: async function (ctx, param) { // eslint-disable-line\
    let curLang = ''
    if (this.$cpCookie) {
      curLang = this.$cpCookie.get('revampLang')
    }
    let id = 0
    let customized = 0
    if (typeof param === 'object' && param !== null) {
      id = param.id
      customized = param.customized
    } else {
      id = param
    }
    const {
      commit
    } = ctx
    const {
      data
    } = await this.$axios.get(`positions/${id}?customized=` + customized)
    const additional = [

    ]
    additional.push({
      key: 'jd.work_exp',
      table: 'left',
      value: commonFunc.parseExp(data.minexperiencedesc, data.maxexperiencedesc, data.minexperience, data.maxexperience)
    })
    additional.push({
      key: 'jd.ref',
      table: 'right',
      value: data.referenceno || null
    })
    additional.push({
      key: 'jd.education',
      table: 'left',
      value: data.educationdesc || null
    })
    additional.push({
      key: 'jd.skill',
      table: 'right',
      value: data.skills || null
    })
    additional.push({
      key: 'jd.employment_type',
      table: 'left',
      value: data.employmentstatusdesc || null
    })

    additional.push({
      key: 'jd.qualification',
      table: 'right',
      value: data.qualification || null
    })
    additional.push({
      key: 'jd.location',
      table: 'left',
      value: data.locationdesc || null
    })
    additional.push({
      key: 'jd.benefits',
      table: 'right',
      value: data.benefits || null
    })
    additional.push({
      key: 'jd.job_level',
      table: 'left',
      value: data.manageriallevel || null
    })
    additional.push({
      key: 'jd.languages',
      table: 'right',
      value: data.languages || null
    })
    additional.push({
      key: 'jd.pd',
      table: 'left',
      value: commonFunc.toExiprationDate(data.activationdate ? data.activationdate.timestamp : Date.now(), curLang) || ''
    })
    additional.push({
      key: 'jd.workmode',
      table: 'right',
      value: data.workmodedesc
    })
    if (data.showsalary === null || data.showsalary === 'Y') {
      additional.push({
        key: 'jd.salary',
        table: 'right',
        value: commonFunc.parseSalary(data.minsalary, data.maxsalary, data.salarytype) || null
      })
    }
    data.additional = additional
    data.redirect = (data.externalapplyurl && data.dontacceptapplication === 'Y')
    data.blindAd = (data.isanonymouscompany === 'Y' && data.applythisjobon === 'X' && data.dontacceptapplication === 'Y')
    data.recruitmentDay = (!data.isanonymouscompany && data.applythisjobon === 'X' && data.dontacceptapplication === 'Y')
    data.customTemplateUsed = Boolean(data.customtemplate && data.customtemplate.use_template && data.customtemplate.template)
    data.expired = data.expirationdate ? new Date(data.expirationdate.timestamp * 1000) < new Date() : false
    data.notAvailable = data.expirationdate ? new Date(data.expirationdate.timestamp * 1000) < new Date(new Date().setFullYear(new Date().getFullYear() - 1)) : false
    data.description = data.description.replace(/\[Apply_button\]/g, '<a class="content-apply-now" href="myjob/apply/' + data.id + '" >Apply Now</a>')
    data.companyintro = data.companyintro.replace(/\[Apply_button\]/g, '<a class="content-apply-now" href="myjob/apply/' + data.id + '">Apply Now</a>')
    data.replyinfo = data.replyinfo.replace(/\[Apply_button\]/g, '<a class="content-apply-now" href="myjob/apply/' + data.id + '" >Apply Now</a>')

    if (data.customTemplateUsed) {
      let template = String(data.customtemplate.template)

      // Replaace tempalte start
      // parse string
      const hiddenElement = '<span class="tr_hidden"/>'
      const fieldArr = []
      const cityArr = []
      const skillArr = []
      const langArr = []
      const benefitArr = []
      if (data.field) Object.values(data.field).map((item) => { fieldArr.push(commonFunc.languageHandler(item)) })
      if (data.locationdesc) Object.values(data.locationdesc).map((item) => { cityArr.push(commonFunc.languageHandler(item)) })
      if (data.skills) Object.values(data.skills).map((item) => { skillArr.push(commonFunc.languageHandler(item)) })
      if (data.languages) Object.values(data.languages).map((item) => { langArr.push(commonFunc.languageHandler(item)) })
      if (data.benefits) Object.values(data.benefits).map((item) => { benefitArr.push(commonFunc.languageHandler(item)) })

      const field = data.field ? fieldArr.join('<br/>') : hiddenElement
      const city = data.locationdesc ? cityArr.join(',') : hiddenElement
      const skill = data.field ? skillArr.join(',') : hiddenElement
      const lang = data.languages ? langArr.join(',') : hiddenElement
      const bene = data.benefits ? benefitArr.join(',') : hiddenElement
      // replace template
      template = template.replace('<@CP:Replyinfo>', data.replyinfo || '')
      template = template.replace('<@CP:JobTitle>', data.jobtitletext || '')
      template = template.replace('<@CP:PositionID>', data.id || '')
      template = template.replace('<@CP:ReferenceNo>', data.referenceno || hiddenElement)
      template = template.replace('<@CP:CompanyDisplayName>', data.displayname || '')
      template = template.replace('<@CP:PostingDate>', commonFunc.toExpiryDate(data.publisheddate ? data.publisheddate.timestamp : '') || '')
      template = template.replace('<@CP:CompanyIntroduction>', data.companyintro || '')
      template = template.replace('<@CP:JobDescription>', data.description || '')
      template = template.replace('<@CP:ReqFieldOfWork>', field)
      template = template.replace('<@CP:ReqManagerialLevel>', commonFunc.languageHandler(data.manageriallevel) || hiddenElement)
      template = template.replace('<@CP:MinExperience>', commonFunc.languageHandler(data.minexperiencedesc) || hiddenElement)
      template = template.replace('<@CP:MaxExperience>', commonFunc.languageHandler(data.maxexperiencedesc) || hiddenElement)
      template = template.replace('<@CP:ReqIndustries>', commonFunc.languageHandler(data.industrydesc) || hiddenElement)
      template = template.replace('<@CP:ReqJobType>', commonFunc.languageHandler(data.employmentstatusdesc) || hiddenElement)
      template = template.replace('<@CP:ReqLocation>', city)
      template = template.replace('<@CP:ReqShiftType>', '' || hiddenElement)
      template = template.replace('<@CP:Skill>', skill)
      template = template.replace('<@CP:ReqLanguage>', lang)
      template = template.replace('<@CP:ReqBenefit>', bene)

      template = template.replace('<@CP:Qualification>', commonFunc.languageHandler(data.educationdesc) || hiddenElement)
      template = template.replace('<@CP:EmploymentType>', commonFunc.languageHandler(data.employmentstatusdesc) || hiddenElement)
      if (data.showsalary === null || data.showsalary === 'Y') {
        template = template.replace('<@CP:MinSalary>', data.minsalary)
        template = template.replace('<@CP:MaxSalary>', data.maxsalary)
      } else {
        template = template.replace('<@CP:MinSalary>', '')
        template = template.replace('<@CP:MaxSalary>', '')
      }
      // Replace template end

      data.template = template
    }
    commit('setDetail', data) // commit = call mutations
  },
  fetchCompany: async function ({ commit }, param) {
    // console.log('jobs.js', param)
    let curLang = ''
    if (this.$cpCookie) {
      curLang = this.$cpCookie.get('revampLang')
    }

    let id = 0
    let customized = 0
    if (typeof param === 'object' && param !== null) {
      id = param.id
      customized = param.customized
    } else {
      id = param
    }
    const { data } = await this.$axios.get(`companies/${id}?customized=` + customized)
    const companyInfo = []
    const dn = data.companyname
    const cdata = await this.$axios.get(`positions?keyword=${encodeURIComponent(dn)}&sopt=5&withsalary=false&withpriority=false&size=9&offset=0&sort=ActivationDate&order=desc`)
    companyInfo.push({
      key: commonFunc.cpLang('jd.location'),
      value: data.locationdesc || null
    })

    // companyInfo.push({
    //   key: 'Size',
    //   value: data.sizeCompany || null
    // })
    companyInfo.push({
      key: commonFunc.cpLang('jd.founded', '', curLang),
      value: data.referenceno || null
    })
    companyInfo.push({
      key: commonFunc.cpLang('global.industry', '', curLang),
      value: data.industrydesc || null
    })
    companyInfo.push({
      key: commonFunc.cpLang('jd.website', '', curLang),
      value: data.url || null
    })
    companyInfo.push({
      key: commonFunc.cpLang('jd.perks', '', curLang),
      value: data.benefits || null
    })
    data.companyInfo = companyInfo
    commit('setCompany', data) // commit = call mutations
    commit('setCompanyOther', cdata.data) // commit = call mutations
  }
}
