import Vue from 'vue'
import axios from 'axios'
import VueAuthImage from 'vue-auth-image'

export default function (ctx) {
  const { app } = ctx
  // set Authorization header used by axios
  const token = app.$cpCookie.get('auth._token.local') ? app.$cpCookie.get('auth._token.local') : app.$cpCookie.get('auth._token.remote')
  if (token) {
    axios.defaults.headers.common.Authorization = token
  }
  Vue.use(VueAuthImage)
}
