export default function ({ redirect, req, error, app }) {
  if (req) {
    const ip = req.connection.remoteAddress || req.socket.remoteAddress
    const headers = (req && req.headers) ? Object.assign({}, req.headers) : {}
    const xForwardedFor = headers['x-forwarded-for']
    // const xRealIp = headers['x-real-ip']
    const allows = ['183.178.105.204', '127.0.0.1', '61.91.248.170', '202.83.247.109', '219.77.242.251', '47.242.133.162', '161.81.84.114']
    if (process.server) {
      if (xForwardedFor !== undefined) {
        const clients = xForwardedFor.split(',')
        if (clients[0] !== undefined) {
          if (allows.includes(clients[0]) === false) {
            error({ statusCode: 404, message: 'Page not found' })
          }
        }
      } else if (allows.includes(ip) === false) {
        error({ statusCode: 404, message: 'Page not found' })
      }
    }
  }
}
