<template>
  <div
    :class="{'d-sm-none': workStatus.jobtitle === '' && isNavBar, 'export-cv': isExportCv}"
    class="summary-wrap"
  >
    <div
      class="profile-summary"
    >
      <div :class="{'m-wrap l-flex' : !isNavBar && !isExportCv}">
        <client-only>
          <div v-if="!isNavBar && !isExportCv" class="d-inline-block d-md-none m-p-pic">
            <ProfilePic />
          </div>
        </client-only>
        <div :class="{'m-p-summary': !isNavBar && !isExportCv}">
          <h3 :class="{'mb-3': isExportCv}">
            <label v-if="!isExportCv">{{ firstname }} {{ lastname }}</label>
            <div v-else class="l-flex l-align-center">
              <label class="pr-3 m-0">{{ firstname }} {{ lastname }}</label> {{ workStatus.jobtitle }} <span>{{ workStatus.experience >= 1 ? '(' + workStatus.experience + ' ' + cpLang('profile.years') + ')' : '' }}</span>
            </div>
            <template v-if="workStatus">
              <b v-if="workStatus.jobtitle !== '' && !isExportCv">
                  {{ workStatus.jobtitle }}
                <span>{{ workStatus.experience >= 1 ? '(' + workStatus.experience + ' ' + cpLang('profile.years') + ')' : '' }}</span>
              </b>
            </template>
          </h3>
          <div v-show="dataLoaded || !isNavBar" class="mb-3">
            <h4 v-if="workStatus" :class="{'mb-2': isExportCv}" class="mb-1">
              <span v-show="workStatus.company !== ''">{{ cpLang('profile.work_at',{'company':workStatus.company}) }}</span>
              <span v-show="workStatus.company !== ''">{{ cpLang('profile.exp_period',{'from':workStatus.from,'to':workStatus.iscurrent ? cpLang('profile.exp_present') : workStatus.to}) }}</span>
            </h4>
            <div class="l-flex">
              <div v-show="!isNavBar && checkEmpty(dob) !== ''" class="addition-info mr-2">
                  {{ cpLang('profile.born_on') }} {{ dob }}
              </div>
              <span v-show="location !== ''" class="location-text mb-0">
                <i class="fas fa-map-marker-alt" />{{ location }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <section v-if="!isNavBar && !isExportCv">
        <div v-show="!isPreviewProfile" class="row">
          <div class="col-sm-6 l-flex">
            <span class="field-title w-50">
              {{ cpLang('profile.visibility_short') }} :
            </span>
            {{ expectation.visibility && expectation.visibility.desc ? expectation.visibility.desc : '' }}
          </div>
          <div class="col-sm-6 l-flex">
            <span class="field-title w-45">{{ cpLang('profile.current_salary') }} : </span>
            <span v-html="currentsalary" />
            <i v-show="currentsalary === ''" @click="open" class="fas fa-pen" />
          </div>
          <div class="clear-both" />
        </div>
        <div class="row">
          <div class="col-sm-6 l-flex">
            <span
              :class="isMobile ? 'w-45' : 'w-50'"
              class="field-title"
            >{{ cpLang('profile.looking_for_ajob') }} :</span>
            <span>{{ jobtype }}</span>
            <i v-show="jobtype === ''" @click="open" class="fas fa-pen" />
          </div>
          <div class="col-sm-6 l-flex">
            <span class="field-title w-45">{{ cpLang('profile.expected_salary') }} : </span>
            <span v-html="expectedsalary" />
            <i v-show="expectedsalary === ''" @click="open" class="fas fa-pen" />
            <div v-show="expectation.negotiable === 'Y'" class="nego-tab">
                {{ cpLang('jd.salary_negotiable') }}
              </div>
          </div>
          <div class="clear-both" />
        </div>
        <div class="row pt-3">
          <div v-show="checkEmpty(overview) !== ''" class="col-sm-12">
            <b>{{ cpLang('jd.overview') }} :</b>
            <p v-html="strNl2br(overview)" />
          </div>
          <div v-show="checkEmpty(summary) !== ''" class="col-sm-12">
            <b>{{ cpLang('jd.executive_summary') }} :</b>
            <p v-html="strNl2br(summary)" />
          </div>
          <div class="clear-both" />
        </div>
      </section>
      <section v-if="isExportCv">
        <div class="row">
          <div class="col-sm-5 l-flex">
            <span v-show=" expectation.currentsalary > 0">
              <span class="field-title">{{ cpLang('profile.current_salary') }} : &nbsp;</span>
              THB{{ expectation.currentsalary >= 0 && expectation.currentsalary ? formatSal(expectation.currentsalary) : '' }}
            </span>
          </div>
          <div class="col-sm-7 l-flex">
            <span v-show="expectation.expectedsalary > 0 || expectation.negotiable === 'Y'">
              <span class="field-title">{{ cpLang('profile.expected_salary') }} : &nbsp;</span>
              <span v-if="expectation.expectedsalary > 0">THB{{ expectation.expectedsalary >= 0 && expectation.expectedsalary ? formatSal(expectation.expectedsalary) : '' }}</span>
              <div v-show="expectation.negotiable === 'Y'" class="nego-tab">
                {{ cpLang('jd.salary_negotiable') }}
              </div>
            </span>
          </div>
          <div class="clear-both" />
        </div>
        <div class="row pt-3">
          <div v-if="checkEmpty(overview) !== ''" class="col-sm-12">
            <b>{{ cpLang('jd.overview') }} :</b>
            <p v-html="strNl2br(overview)" />
          </div>
          <div v-if="checkEmpty(summary) !== ''" class="col-sm-12">
            <b>{{ cpLang('jd.executive_summary') }} :</b>
            <p v-html="strNl2br(summary)" />
          </div>
          <div class="clear-both" />
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import ProfilePic from '@/components/profile/ProfilePic'
import commonFun from '~/assets/js/commonHelper.js'

export default {
  components: {
    ProfilePic
  },
  props: {
    isNavBar: {
      type: Boolean,
      default: false
    },
    isExportCv: {
      type: Boolean,
      default: false
    },
    memberInfo: {
      type: Object,
      default: null
    },
    expectationInfo: {
      type: [Array, Object],
      default: null
    },
    loadData: {
      type: Boolean,
      default: false
    },
    isPreviewProfile: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      dataLoaded: false,
      // location: '',
      dob: '',
      summary: '',
      overview: '',
      expectation: {
        visibility: '',
        currentsalary: '',
        expectedsalary: '',
        currentsalarycurrency: '',
        expectedsalarycurrency: '',
        negotiable: '',
        locationtext: '',
        jobtype: ''
      },
      month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      loading: true
    }
  },
  computed: {
    firstname: function () {
      if (this.$auth.loggedIn) {
        return decodeURIComponent(this.$auth.user.firstname ? this.$auth.user.firstname : this.$auth.user.email).replace(/\+/gm, ' ')
      }
      if (this.isExportCv) {
        return this.memberInfo.firstname
      }
      return ''
    },
    lastname: function () {
      if (this.$auth.loggedIn) {
        return decodeURIComponent(this.$auth.user.lastname && this.$auth.user.firstname !== '' ? this.$auth.user.lastname : '')
      }
      if (this.isExportCv) {
        return this.memberInfo.lastname
      }
      return ''
    },
    workStatus: function() {
      if (this.$auth.loggedIn) {
        const res = this.$auth.user.statistics.workstatus
        return res
      }
      if (this.memberInfo.statistics) {
        return this.memberInfo.statistics.workstatus
      }
      return []
    },
    currentsalary() {
      return this.expectation.currentsalary > 0 ? `<b>${commonFun.convertCur(this.expectation.currentsalarycurrency)}</b> ${this.formatSal(this.expectation.currentsalary)}` : ''
    },
    expectedsalary() {
      return this.expectation.expectedsalary > 0 ? `<b>${commonFun.convertCur(this.expectation.expectedsalarycurrency)}</b> ${this.formatSal(this.expectation.expectedsalary)}` : ''
    },
    jobtype() {
      return this.expectation.jobtype && this.expectation.jobtype.desc ? this.expectation.jobtype.desc : ''
    },
    location: function() {
      console.log()
      return this.expectation && this.expectation.locationtext ? this.expectation.locationtext : ''
    },
    isMobile() {
      if (process.client) {
        return commonFun.isPortraitMobile()
      }
      return false
    }
  },
  watch: {
    workStatus(v, ov) {
      if (v !== null) {
        if (v.company !== '') {
          this.dataLoaded = true
        } else {
          this.dataLoaded = true
        }
      }
    },
    loadData() {
      this.loadSummary()
      this.loadExpectation()
    },
    expectationInfo() {
      this.loadSummary()
      this.loadExpectation()
    },
    memberInfo() {
      this.loadSummary()
      this.loadExpectation()
    }
  },
  mounted () {
    window.addEventListener('reloadProfileSummary', this.reload)
    this.loadSummary()
    this.loadExpectation()
    console.log(commonFun.isPortraitMobile())
  },
  methods: {
    open() {
      this.$emit('open')
    },
    strNl2br: function (str) {
      if (!str) {
        return ''
      }
      return str.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
    checkEmpty: function(str) {
      return commonFun.checkStrEmpty(str)
    },
    formatSal: function(e) {
      return commonFun.formatSalary(e)
    },
    reload: function() {
      this.$auth.fetchUser()
    },
    loadSummary: async function() {
      if (this.memberInfo) {
        const data = await this.memberInfo
        this.summary = data.summary
        this.overview = data.overview
        if (data.birthdate) {
          const getDob = new Date(data.birthdate.date.replace(/\s/, 'T'))
          const d = getDob.getDate()
          const m = this.month[getDob.getMonth()]
          const y = getDob.getFullYear()
          const res = `${d} ${m} ${y}`
          this.dob = res
        }
      }
    },
    loadExpectation: async function() {
      if (this.expectation) {
        const data = await this.expectationInfo
        if (data) {
          this.expectation.visibility = data.visibility ? data.visibility : ''
          this.expectation.currentsalary = data.currentsalary
          this.expectation.expectedsalary = data.expectedsalary
          this.expectation.currentsalarycurrency = data.currentsalarycurrency
          this.expectation.expectedsalarycurrency = data.expectedsalarycurrency
          this.expectation.negotiable = data.negotiable
          this.expectation.locationtext = data.locationtext
          this.expectation.jobtype = data.jobtype
        }
      }
    }
  }
}
</script>
<style lang="scss">
.summary-wrap{
  min-height:100px;
  .profile-summary{
    text-align: left;
    h3{
      font-size: 16px;
      label{
        display: none;
      }
    }
    h4{
      font-size: 14px;
    }
    .location-text{
      font-size:12px;
      i{
        margin-right: 5px;
        color:#0081BE;
      }
    }
    small{
      font-size:10px;
      display: block;
    }
  }
  .exp_sal{
    display: inline-flex;
  }
  .nego-tab{
      font-weight: 400;
      font-size: 10px;
      display: inline-flex;
      color: white;
      background: #707070;
      padding: 0px 4px;
      border-radius: 4px;
      margin-left: 5px;
      align-items: center;
    }
  .addition-info{
    font-size: 12px;
    .fa-map-marker-alt{
      color: #0081BE !important;
    }
  }
}
.fas{
  &.fa-pen{
    cursor: pointer;
  }
}
.export-cv{
  .profile-summary{
    label{
      display: block !important;
    }
  }
}
.m-p-pic{
  width: 30%;
}
.m-p-summary{
  width: 70%;
}
</style>
