const middleware = {}

middleware['block'] = require('../middleware/block.js')
middleware['block'] = middleware['block'].default || middleware['block']

middleware['dpp'] = require('../middleware/dpp.js')
middleware['dpp'] = middleware['dpp'].default || middleware['dpp']

middleware['fbcvapi'] = require('../middleware/fbcvapi.js')
middleware['fbcvapi'] = middleware['fbcvapi'].default || middleware['fbcvapi']

middleware['getToken'] = require('../middleware/getToken.js')
middleware['getToken'] = middleware['getToken'].default || middleware['getToken']

middleware['langCookies'] = require('../middleware/langCookies.js')
middleware['langCookies'] = middleware['langCookies'].default || middleware['langCookies']

middleware['search'] = require('../middleware/search.js')
middleware['search'] = middleware['search'].default || middleware['search']

middleware['switchLang'] = require('../middleware/switchLang.js')
middleware['switchLang'] = middleware['switchLang'].default || middleware['switchLang']

middleware['userAgent'] = require('../middleware/userAgent.js')
middleware['userAgent'] = middleware['userAgent'].default || middleware['userAgent']

export default middleware
