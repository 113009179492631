<template>
  <b-modal id="selectlang-modal" modal-class="lang-modal" hide-header hide-footer>
    <div class="lang-modal-content">
      <h4 class="lang-modal-title">
        Choose your Country and Language
      </h4>
      <p class="lang-modal-subtitle">
        Select the country and language to see the specific content to your location.
      </p>
      <div class="lang-dd-container l-flex">
        <LangDropdown
          :options="countries"
          :countries="countries"
          :downSVG="downSVG"
          @select-item="(e) => selectedCountry = e.desc"
          @auto-set-lang="autoSetLang()"
          :placeholder="countryPlaceholder"
        />
        <LangDropdown
          :options="languages"
          :languages="languages"
          :downSVG="downSVG"
          @select-item="(e) => selectedLang = e.name"
          :placeholder="langPlaceholder"
        />
      </div>
      <button @click="updateCookie()" class="update-btn">
        Update
      </button>
    </div>
  </b-modal>
</template>

<script>
import LangDropdown from './LangDropdown.vue'
import json from './lang.json'

export default {
  components: {
    LangDropdown
  },
  data() {
    return {
      downSVG: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
        </svg>`,
      countries: [],
      languages: json,
      selectedCountry: 'Select Country/Region',
      selectedLang: 'Select Language'
    }
  },
  computed: {
    countryPlaceholder: function() {
      return `<span class="b-dd-text">${this.selectedCountry}</span> ${this.downSVG}`
    },
    langPlaceholder: function() {
      return `<span class="b-dd-text">${this.selectedLang}</span> ${this.downSVG}`
    }
  },
  mounted() {
    this.getCountryList().then(() => {
      this.getCookie()
    })
  },
  methods: {
    getCountryList: async function() {
      await this.$store.dispatch('lang/fetchCountryList')
      const countryList = JSON.parse(JSON.stringify(this.$store.state.lang.countryList))
      this.countries = countryList
    },
    updateCookie: function() {
      if (this.selectedCountry !== 'Select Country' && this.selectedLang !== 'Select Language') {
        const countryId = this.countries.find((country) => {
          return country.desc === this.selectedCountry
        }).id
        const langCookie = this.languages.find(lang => lang.name === this.selectedLang).code
        this.$cpCookie.set('revampLang', langCookie)
        this.$cpCookie.set('default_country', countryId)
        window.location.href = '/'
      } else {
        alert('Please select country / language')
      }
    },
    getCookie: function() {
      const langCookie = this.$cpCookie.get('revampLang')
      const countryCookie = this.$cpCookie.get('default_country')
      this.selectedLang = this.languages.find(lang => lang.code === langCookie).name
      this.selectedCountry = this.countries.find(country => parseInt(country.id) === parseInt(countryCookie)).desc
    },
    autoSetLang: function() {
      const country = this.countries.find(country => country.desc === this.selectedCountry)
      if (!country) {
        this.selectedLang = 'English'
      }
      const lang = this.languages.find(language => language.code === country.defaultLocale)
      if (!lang) {
        this.selectedLang = 'English'
      }
      this.selectedLang = lang.name
    }
  }
}
</script>

<style lang="scss">
  .lang-modal {
    .modal-dialog {
      margin: auto auto auto 15px;
      @media screen and (min-width: 768px) {
        margin: auto;
      }
    }
    .modal-content {
      border-radius: 20px;
      margin: auto;
      width: 345px;
      @media screen and (min-width: 768px) {
        width: 515px;
      }
    }
  }
  .lang-modal-content {
    padding: 25px 0px;
    height: 360px;
    @media screen and (min-width: 768px) {
      height: 282px;
    }
    .lang-modal-title {
      font-size: 18px;
      font-weight: bold;
      color: #000000;
      text-align: center;
      @media screen and (min-width: 768px) {
        font-size: 24px;
      }
    }
    .lang-modal-subtitle {
      font-size: 16px;
      color: #707070;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 30px;
    }
    .lang-dd-container {
      gap: 25px;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: center;
      }
      .lang-dd {
        width: 100%;
        @media screen and (min-width: 768px) {
          width: 200px;
        }
      }
    }
    .update-btn {
      width: 100%;
      display: block;
      border-radius: 20px;
      margin-top: 30px;
      border: none;
      color: white;
      font-size: 14px;
      background-color: #0EA5BF;
      height: 40px;
      @media screen and (min-width: 768px) {
        width: 176px;
        margin: 30px auto 0 auto;
      }
    }
  }
</style>
