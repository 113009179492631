<template>
  <div :class="{'mt-0' : isHomePage}" class="cpj-footer">
    <div
      v-if="!$store.state.loading.previewing"
      class="cpj-footer_menu bg-footerA"
    >
      <div class="nav-list-container">
        <div
          v-for="item in pageList"
          :key="item.pageTitle"
          class="cpj-nav-list"
        >
          <div class="green-underline">
            <b>{{ item.pageTitle }}</b>
          </div>
          <div v-for="subItem in item.list" :key="subItem.title">
            <a
              :href="subItem.url"
              :rel="!isSameHost(subItem.url) ? 'noopener noreferrer' : ''"
              :target="subItem.internal ? '' : '_blank'"
              class="opacity-white"
            >
              {{ subItem.title }}
            </a>
          </div>
        </div>
      </div>
      <div class="logos-sns">
        <div>
          <img src="asset/image/logo/getlinks_grey.svg" alt="">
          <p class="green-underline">
            {{ cpLang('footer.logo_desc') }}
          </p>
        </div>
        <div class="sns-list">
          <p>
            {{ cpLang('footer.follow_us') }}
          </p>
          <div class="sns-item-gp">
            <a :href="sns.fb" target="_blank">
              <div class="sns-item">
                <div class="fab fa-facebook-f" />
              </div>
            </a>
            <a :href="sns.ln" target="_blank">
              <div class="sns-item">
                <div class="fab fa-linkedin" />
              </div>
            </a>
            <a :href="sns.ig" target="_blank">
              <div class="sns-item">
                <div class="fab fa-instagram" />
              </div>
            </a>
            <a :href="sns.yt" target="_blank">
              <div class="sns-item">
                <div class="fab fa-youtube" />
              </div>
            </a>
          </div>
        </div>
        <button
          @click="$bvModal.show('selectlang-modal')"
          class="selectlang-box"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            class="bi bi-geo-alt-fill"
            viewBox="0 0 16 20"
          >
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
          </svg>
          <span class="selectlang-country">{{ country }}</span>
          <span class="lang-divide">|</span>
          <span class="selectlang-lang">{{ language }}</span>
        </button>
      </div>
    </div>
    <div class="cpj-footer_copyright bg-footerB">
      <div class="copyright-text">
        <span>
          {{ cpLang("footer.copyright", { year }) }}
        </span>
      </div>
      <div class="cpj-footer_list">
        <a href="/terms-conditions">
          <span class="cpj-footer_listItem">
            {{ cpLang("global.tnc") }}
          </span>
        </a>
        |
        <a href="/privacy-policy">
          <span class="cpj-footer_listItem">
            {{ cpLang("footer.privacy_policy") }}
          </span>
        </a>
        |
        <a href="/site-map">
          <span class="cpj-footer_listItem">
            {{ cpLang("footer.sitemap") }}
          </span>
        </a>
      </div>
    </div>
    <LangModal />
  </div>
</template>

<script>
import LangModal from '@/components/lang/LangModal.vue'
import json from '@/components/lang/lang.json'

export default {
  components: {
    LangModal
  },
  data: function() {
    return {
      isHomePage: false,
      year: new Date().getFullYear(),
      pageList: [
        {
          pageTitle: this.cpLang('global.getlinks'),
          list: [
            {
              title: this.cpLang('footer.about_us'),
              url: 'https://getlinks.com/aboutus'
            },
            {
              title: this.cpLang('footer.contact_us'),
              url: 'https://getlinks.co/contactus'
            }
          ]
        },
        {
          pageTitle: this.cpLang('footer.job_seekers'),
          list: [
            {
              title: this.cpLang('footer.find_a_job'),
              url: 'https://jobs.getlinks.com'
            },
            {
              title: this.cpLang('global.match_me'),
              url: 'https://jobs.getlinks.com/myjob/'
            }
          ]
        },
        {
          pageTitle: this.cpLang('global.employers'),
          list: [
            {
              title: this.cpLang('global.post_a_job'),
              url:
                'https://hr.getlinks.com/'
            },
            {
              title: this.cpLang('global.advertise_with_us'),
              url:
                'https://hr.getlinks.com/?_ga=2.48912678.1217550181.1568010936-409796288.1564375602'
            }
          ]
        },
        {
          pageTitle: this.cpLang('footer.community'),
          list: [
            {
              title: this.cpLang('homepage.card_title2'),
              url: 'https://humansoftech.getlinks.com/'
            },
            {
              title: this.cpLang('homepage.card_title3'),
              url: 'https://blog.getlinks.com/news/'
            }
          ]
        }
      ],
      enquiryList: [
        {
          title: this.cpLang('global.job_posting'),
          phone: '+ 852 3619 9600 (M-F, 9am - 6pm) ',
          email: 'info@cpjobs.com'
        },
        {
          title: this.cpLang('global.classified_post'),
          phone: '+ 852 2565 8822 (M-F, 9am - 6pm) ',
          email: 'classified@scmp.com'
        },
        {
          title: this.cpLang('global.jiujik'),
          phone: '+ 852 2565 8811 (M-F, 9am - 6pm) ',
          email: 'jiujik@scmp.com'
        }
      ],
      sns: {
        fb: 'https://www.facebook.com/GetLinksInc',
        ln: 'https://www.linkedin.com/company/getlinks/',
        ig: 'https://www.instagram.com/getlinksinc/',
        yt: 'https://www.youtube.com/channel/UCsQ4fUZyyzoNkBbCLkprkow'
      },
      country: '',
      language: '',
      languages: json,
      countries: []
    }
  },
  watch: {
    $route(n) {
      if (n.fullPath === '/') {
        this.isHomePage = true
      } else {
        this.isHomePage = false
      }
    }
  },
  mounted() {
    if (this.$route.fullPath === '/') {
      this.isHomePage = true
    }
    this.checkCookie()
  },
  methods: {
    getCountryList: async function() {
      if (this.$store.state.lang.countryList.length === 0) {
        await this.$store.dispatch('lang/fetchCountryList')
      }
      return await this.$store.state.lang.countryList
    },
    isSameHost(url) {
      return String(url).includes('cpjobs.com')
    },
    async checkCookie() {
      this.countries = await this.getCountryList()
      const countryCookie = this.$cpCookie.get('default_country')
      const country = this.countries.find(location => parseInt(location.id) === parseInt(countryCookie))
      this.country = country ? country.desc : 'Unknown'

      const langCookie = this.$cpCookie.get('revampLang')
      const language = this.languages.find(lang => lang.code === langCookie)
      this.language = language ? language.localName : 'Unknown'
    }
  }
}
</script>
