<template>
      <div :class="{'skeleton-avatar':!loaded}" class="profile-picture">
        <i id="edit-avatar-btn2" v-show="loaded" @click="toggleShow" class="fas fa-pen" />
        <div>
          <img ref="photo" :src="photoUrl" @load="loaded=true">
          <client-only>
            <Avatar
              :width="150"
              :height="150"
              :lang-type="'cn'"
              :passwidth="width"
              @crop-success="cropSuccess"
              @crop-upload-fail="logErrMsg"
              v-model="show"
              field="img"
              img-format="png"
            />
          </client-only>
          <!-- <i id="edit-avatar-btn">{{ cpLang('profile.upload') }}</i> -->
        </div>
      </div>
</template>

<script>
import commonFunc from '~/assets/js/commonHelper.js'

export default {
  components: {
    Avatar: () => {
      if (process.client) {
        return import('vue-image-crop-upload')
      }
    }
  },
  data() {
    return {
      photoReloaded: false,
      imgDataUrl: '',
      loaded: false,
      show: false,
      noSquare: true,
      langType: this.checkLang(),
      field: '',
      width: 0
    }
  },
  computed: {
    photoUrl: function () {
      return this.$auth.user.image ? this.$auth.user.image : '/asset/image/photo/avatar.jpg'
    }
  },
  mounted() {
    if (commonFunc.isPortraitMobile()) {
      this.width = 320
    } else {
      this.width = 440
    }
  },
  methods: {
    logErrMsg: function(e) {
      console.log(e)
    },
    toggleShow: function() {
      this.show = !this.show
    },
    cropSuccess: function(imgDataUrl) {
      this.photoReloaded = false
      this.imgDataUrl = imgDataUrl
      const vm = this
      try {
        this.$axios.post('/member/', { image: imgDataUrl })
          .then(function(res) {
            vm.$refs.photo.src = res.data.image + '?t=' + Date.now()
            vm.$auth.fetchUser()
          })
      } catch (err) {
        console.log(err.response)
      }
    },
    checkLang: function() {
      const curLang = this.$cpCookie.get('revampLang')
      // console.log(curLang)
      return curLang === 'en' || curLang === undefined ? 'en' : 'zh-tw'
    }
  }
}
</script>

<style lang="scss">
// .vue-image-crop-upload .vicp-wrap{
//   width:450px
// }
.profile-picture{
    width:100px;
    height: 100px;
    position: absolute;
    left: 0px;
    border-radius: 50%;
    img{
      width: 100%;
    }
    >div{
      border-radius: 50%;
      overflow: hidden;
    }
    i#edit-avatar-btn{
      position: absolute;
      text-align: center;
      position: absolute;
      text-align: center;
      z-index: 2;
      bottom: -40px;
      left: 0;
      background: #57c5f7;
      color:white;
      display: block;
      width: 100%;
      padding: 5px 0px 8px 0px;
      border-radius: 50%;
      opacity: 0.8;
      cursor: pointer;
      font-size: 12px;
      font-style: normal;
      transition: all .4s ease;
    }
    &:hover i#edit-avatar-btn{
      bottom:-1px
    }
    #edit-avatar-btn2{
      position: absolute;
      bottom: 0;
      right: 0;
      background: #1567c4;
      padding: 4px;
      border-radius: 50%;
      border: 2px solid #fff;
      color: #fff;
      font-size: 11px;
      z-index: 99;
      cursor: pointer;
    }
  }
//   .vicp-img{
//     max-width: unset;
//   }
// .vue-image-crop-upload .vicp-wrap {
//   width: 100%;
//   height: 320px;
//   max-width: 400px;
//   .vicp-drop-area{
//     height: 80% !important;
//   }
//   .vicp-step1{
//     height: 100%;
//     display: flex;
//     width: 100%;
//     justify-content: center;
//   }
//   .vicp-step2{
//     width:100%;
//     .vicp-crop{
//       .vicp-crop-left{
//         float: unset;
//         width: 100%;
//         .vicp-img-container{
//           margin: auto;
//           // width: 100%;
//           // img{
//           //   left: 50% !important;
//           //   transform: translateX(-50%);
//           // }
//         }
//         .vicp-range{
//           margin: 30px auto 10px;
//         }
//       }
//     }
//   }
// }

</style>
