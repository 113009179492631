<template>
  <div id="login-wrapper" :class="{'open': open}">
    <div id="login-box" class="container inner-container">
      <h3>{{ cpLang('login.candidate_login') }}</h3>
      <div class="row overflow-visible">
        <div v-html="cpLang('login.tips')" class="col-xl-4 col-lg-12 col-xs-12 text-left login-tips d-none d-xl-block" />
        <div v-html="cpLang('login.tips-md')" class="col-xl-4 col-lg-12 col-xs-12 text-left login-tips d-block d-xl-none" />
        <div class="col-1 d-none d-lg-block d-xl-none">
          &nbsp;
        </div>
        <div class="col-xl-3 col-lg-5 col-sm-6 col-xs-12">
          <form @keyup.enter="login()" @submit.prevent="toggleValClass" novalidate>
          <div id="email">
            <input
              id="email_box"
              v-model="username"
              :placeholder="cpLang('placeholder.email')"
              type="text"
              class="form-control"
              required
            >
          </div>
          <div id="password">
            <input
              id="password_box"
              v-model="password"
              :placeholder="cpLang('placeholder.password')"
              type="password"
              class="form-control"
              required
            >
          </div>
          <div id="more-option" class="form-check pl-0">
            <div class="l-flex l-align-center">
              <input type="checkbox">
              <span class="pl-2">Keep me logged in</span>
            </div>
            <span>
              <a @click="goToForgot"> {{ cpLang('login.forgot') }}</a>
            </span>
          </div>
          <div v-show="error" class="text-danger">
            <small>{{ error }}</small>
          </div>
          <button @click="login()" type="submit" class="btn profile-btn border-0 w-100 my-2">
              <i v-show="waitingLogin" class="fa fa-spinner fa-spin" />
              <p v-show="!waitingLogin" class="line-height-normal m-0">
                      {{ cpLang('login.login') }}
              </p>
          </button>
          <div>
            <p class="to-reg">
            {{ cpLang('login.notyet') }}<b @click="goToRegister" class="text-primary">{{ cpLang('login.signhere') }}</b>
            </p>
          </div>
        </form>
        </div>
        <div class="col-1 d-none d-xl-block">
          &nbsp;
        </div>
        <div class="col-xl-3 col-lg-5 col-sm-6 col-xs-12 social-login-wrap">
          <AuthBox />
        </div>
      </div>
    <div class="clear-both" />
    </div>
  </div>
</template>
<script>
import AuthBox from '../user/AuthBox'
import commonHelper from '~/assets/js/commonHelper.js'

export default {
  components: { AuthBox },
  props: {
    open: Boolean
  },
  data: function() {
    return {
      username: '',
      password: '',
      keepLogin: false,
      error: '',
      waitingLogin: false,
      cpHost: process.env.cp_host,
      base_dpp_url: process.env.dppHost
    }
  },
  computed: {
    redirect() {
      return (
        this.$route.query.redirect &&
        decodeURIComponent(this.$route.query.redirect)
      )
    }
  },
  methods: {
    goToRegister: function() {
      const redirectUrl = process.env.cp_host + this.$route.fullPath.substring(1)
      window.location.href = this.base_dpp_url + 'auth/register?r=' + encodeURIComponent(redirectUrl)
    },
    goToForgot: function() {
      const redirectUrl = process.env.cp_host + this.$route.fullPath.substring(1)
      window.location.href = this.base_dpp_url + 'auth/forgotpassword?r=' + encodeURIComponent(redirectUrl)
    },
    login: function() {
      this.error = ''
      const vm = this
      const valid = [
        {
          field: this.username,
          errMsg: this.cpLang('login.email_required'),
          eleId: 'email_box'
        },
        {
          field: this.password,
          errMsg: this.cpLang('login.password_required'),
          eleId: 'password_box'
        }
      ]
      const res = valid.every(function(e) {
        const res = commonHelper.emptyFoucs(e.field, e.errMsg, e.eleId)
        if (!res.response) {
          vm.error = res.err
          return false
        }
        return true
      })
      if (res) {
        vm.waiting(1)
        return this.$auth
          .loginWith('local', {
            data: {
              email: this.username,
              password: this.password,
              keeplogin: this.keepLogin
            }
          }).then((ressponse) => {
            vm.waiting(0)
            vm.$store.dispatch('user/syncBookmark')
            if (!vm.$route.path.includes('/login')) {
              const url = vm.$route.path
              vm.$router.push({ url, redirect: 'actualRoute' })
            }
          }).catch((err) => {
            vm.waiting(0)
            vm.error = err.message
            if (err.message.includes('password')) {
              commonHelper.eleFocus('#password_box')
            } else {
              commonHelper.eleFocus('#email_box')
            }
          })
      }
    },
    waiting: function(status) {
      this.waitingLogin = status
      commonHelper.cursorWait(status)
    },
    toggleValClass: function(e) {
      e.target.classList.add('was-validated')
    }
  }
}
</script>

<style>
  .keep-login{
    font-size: 12px;
  }
  .to-reg{
    font-size: 14px;
  }
  .text-danger:first-letter{
    text-transform: capitalize;
  }
</style>
