// import * as R from 'ramda'

export const state = () => ({
  employerList: [],
  agencyList: [],
  employer: [],
  agency: []
})

export const mutations = {
  setEmployerList(state, items) {
    const { featuredlogo } = items
    items.featuredLogoList = featuredlogo
    state.employerList = items
  },
  setAgencyList(state, items) {
    const { featuredlogo } = items
    items.featuredLogoList = featuredlogo

    state.agencyList = items
  },
  setEmployer(state, items) {
    state.employer = items
  },
  setAgency(state, items) {
    state.agency = items
  }
}

export const actions = {
  fetchEmployerList: async function ({ commit }) {
    const { data } = await this.$axios.get('directories/employer')
    // console.log('emppli', data)
    commit('setEmployerList', data) // commit = call mutations
  },
  fetchAgencyList: async function ({ commit }) {
    const { data } = await this.$axios.get('directories/agency')
    commit('setAgencyList', data) // commit = call mutations
  },
  fetchEmployer: async function ({ commit }, initial) {
    commit('loading/changeLoading', [true, 'paging'], { root: true })
    const { data } = await this.$axios.get('directories/employer/' + initial)
    await commit('setEmployer', data) // commit = call mutations
    commit('loading/changeLoading', [false, 'paging'], { root: true })
  },
  fetchAgency: async function ({ commit }, initial) {
    const { data } = await this.$axios.get('directories/agency/' + initial)
    await commit('setAgency', data) // commit = call mutations
  }
}
