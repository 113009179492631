import commonFunc from '~/assets/js/commonHelper.js'

export const state = () => ({
  sectionList: [],
  articleList: [],
  articleResultList: [],
  article: {}
})

export const mutations = {
  setSectionList(state, items) {
    state.sectionList = items
  },
  setArticleList(state, items) {
    state.articleList = items
  },
  setArticleResultList(state, items) {
    state.articleResultList = items
  },
  setArticle(state, items) {
    state.article = items
  }
}

export const actions = {
  fetchSectionList: async function ({ commit }, item) {
    /*
      {
        section:'career-advice',
        page:1,
        pageSize:10
      }
     */
    const param = commonFunc.convertArrayToURLParam(item)
    const { data } = await this.$axios.get('articles?' + param)
    commit('setSectionList', data) // commit = call mutations
  },
  fetchArticleList: async function ({ commit }, item) {
    /*
      {
        section:'hottest-jobs-this-week',
        page:1,
        pageSize:10
      }
     */
    const param = commonFunc.convertArrayToURLParam(item)
    console.log(param)
    const { data } = await this.$axios.get('articles?' + param)
    commit('setArticleList', data) // commit = call mutations
  },
  fetchArticle: async function ({ commit }, item) {
    /*
      {
        slug:'test',
      }
     */
    const param = commonFunc.convertArrayToURLParam(item)
    const { data } = await this.$axios.get('articles?' + param)
    commit('setArticle', data) // commit = call mutations
  },
  fetchArticleSearchResult: async function ({ commit }, item) {
    /*
      {
        keyword:'test',
        page:1,
        pageSize:10
      }
     */
    const param = commonFunc.convertArrayToURLParam(item)
    const { data } = await this.$axios.get('articles?' + param)
    commit('setArticleResultList', data) // commit = call mutations
  }
}
