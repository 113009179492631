const initialState = {
  bookmarkSynced: false,
  recommendedJob: [],
  status: false,
  previewedJob: []
}
export const state = () => (initialState)

export const mutations = {
  setStatistics(state, items) {
    state.statistics = items
  },
  setPreview(state, items) {
    state.previewedJob.push(items)
  },
  setLogin(state, items) {
    if ((items.candidateLoginsuccess && items.candidateUsername && items.token)) {
      state.login = true
      state.userInfo = { ...items }
      // set expiration time
      const today = new Date()
      const time = today.getTime()
      const expiredIn = items.keepLogin ? 3600 * 24 * 7 : 1800 // seconds
      state.expiredIn = time + expiredIn * 1000
      const date = new Date(state.expiredIn)
      state.expiration = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
      this.$cpCookie.set('JWT_TOKEN', items.token, {
        path: '/',
        maxAge: expiredIn
      })
    } else {
      state.error = items.message
      state.status = items.status
    }
  },
  setStatus(state, items) {
    state.status = items.status
  },
  setRecommendedJob(state, data) {
    const list = data.positions
    const countPerPage = 3
    list.forEach(function (item, index) {
      // console.log(item.score, item.score <= 7)
      if (item.score <= 7) {
        delete (list[index])
      }
    })
    const flist = list.filter(function (item) {
      return item !== undefined
    })
    // pagination
    flist.forEach(function (item, index) {
      item.page = Math.floor(index / countPerPage) + 1
    })
    // console.log('Last List', flist)
    state.recommendedJob = flist
  },
  setBookmark(state, data) {
    const bookmark = []
    const bookmarkCookie = this.$cpCookie.get('bookmark')
    if (bookmarkCookie !== undefined) {
      const bookmarkArr = bookmarkCookie.toString().split(',')
      bookmarkArr.forEach(function (item, index) {
        if (item !== '') {
          bookmark.push(item)
        }
      })
    }
    data.result.forEach(function(item, index) {
      bookmark.push(item.positionid.toString())
    })
    const unique = Array.from(new Set(bookmark))
    this.$cpCookie.set('bookmark', unique.join(','), {
      path: '/',
      maxAge: 60 * 60 * 24 * 3
    })
  },
  setRecentSearch(state, data) {
    if (data.result.length === 0) {
      return
    }
    const recentSearchStr = this.$cpCookie.get('recent_search')
    const recentSearch = recentSearchStr === undefined ? [] : recentSearchStr.split(';')
    const list = recentSearch.reverse()
    if (list.length < 5) {
      data.result.forEach(function(item, index) {
        if (list.length < 5) {
          list.push(item.value + '@' + item.level)
        }
      })
    }
    this.$cpCookie.set('recent_search', list.reverse().join(';'), {
      path: '/',
      maxAge: 60 * 60 * 24 * 30
    })
  },
  setUserInfo(state, data) {
    state.info = data
    state.userInfo.candidateFirstname = data.firstname
  },
  setBookmarkSync(state, data) {
    state.syncBookmark = data
  }
}

export const actions = {
  autoFetchUser: async function() {
    console.log('user - auto fetch')
    const dppToken = await this.$cpCookie.get(process.env.DPP_AUTH_KEY)
    if (dppToken && dppToken !== 0) {
      const authToken = dppToken.auth_token
      try {
        this.$auth.setUserToken(authToken)
        // .then((res) => {
        //   console.log(res.data)
        // })
        // .catch((err) => {
        //   console.log(err)
        // })
      } catch (e) {
        // console.log(e)
      }
    } else {
      console.log('no auth token')
    }
  },
  fetchFeatureLogo: async function ({ commit }) {
    const { data } = await this.$axios.get('featuredlogos')
    commit('setFeatureLogo', data) // commit = call mutations
  },
  fetchRecommendedJob: async function ({ commit }) {
    const { data } = await this.$axios.get('positions/recommendation')
    commit('setRecommendedJob', data) // commit = call mutations
  },
  fetchUser: function({ commit, state }, data) {
    commit('setLogin', data) // commit = call mutations
  },
  fetchInfo: async function ({ commit, state }) {
    try {
      const { data } = await this.$axios.get('member/')
      commit('setUserInfo', data)
    } catch (e) {
    }
  },
  previewJob: function ({ commit }, data) {
    commit('setPreview', data) // commit = call mutations
  },
  syncBookmark: async function ({ commit, state }) {
    if (this.$auth.loggedIn) {
      if (state.bookmarkSynced) {
        return
      }
      const bookmark = []
      const bookmarkCookie = this.$cpCookie.get('bookmark')
      if (bookmarkCookie !== undefined) {
        const bookmarkArr = bookmarkCookie.toString().split(',')
        bookmarkArr.forEach(function (item) {
          if (item !== '') {
            bookmark.push(item)
          }
        })
      }
      const { data } = await this.$axios.get('member/bookmark')
      data.result.forEach(function(item) {
        bookmark.push(item.positionid.toString())
      })
      const unique = Array.from(new Set(bookmark))
      // console.log(unique)
      this.$cpCookie.set('bookmark', unique.join(','), {
        path: '/',
        maxAge: 60 * 60 * 24 * 3
      })
      await this.$axios.post('member/bookmark/save', { posid: unique.join(',') })
      this.$auth.fetchUser()
      commit('setBookmarkSync', true)
    }
  },
  syncRecentSearch: async function ({ commit }) {
    const { data } = await this.$axios.get('member/recentsearch')
    commit('setRecentSearch', data) // commit = call mutations
  },
  register: async function ({ commit, state }, params) {
    const { data } = await this.$axios.post('candidate/register', params)
    commit('setLogin', data)
  },
  identify: async function ({ commit, state }, params) {
    const { data } = await this.$axios.post('candidate/identify', params)
    commit('setStatus', data)
  },
  resetPassword: async function ({ commit, state }, params) {
    const { data } = await this.$axios.post('candidate/resetpassword', params)
    // console.log(data)
    commit('setLogin', data)
  },
  login: async function ({ commit, state }, params) {
    const { data } = await this.$axios.post('candidate/login', params)
    if (params.keeplogin) {
      data.keepLogin = true
    }
    data['.devCanProfile'] = data.candidateFirstname
    if ((data.candidateLoginsuccess && data.candidateUsername && data.token)) {
      // set expiration time
      const expiredIn = data.keepLogin ? 3600 * 24 * 7 : 1800 // seconds
      this.$cpCookie.set('JWT_TOKEN', data.token, {
        path: '/',
        maxAge: expiredIn
      })
    }
    commit('setLogin', data)
    // if (data.sync) {
    //   // if user info cookie empty
    //   this.$cpCookie.set('.devCanProfile', data.candidateFirstname, {
    //     path: '/',
    //     maxAge: 60 * 30
    //   })
    //   this.$cpCookie.set('candidateLoginsuccess', 1, {
    //     path: '/',
    //     maxAge: 60 * 30
    //   })
    //   this.$cpCookie.set('candidateUsername', data.candidateUsername, {
    //     path: '/',
    //     maxAge: 60 * 30
    //   })
    //   this.$cpCookie.set('token', data.token, {
    //     path: '/',
    //     maxAge: 60 * 30
    //   })
    // }
  },
  saveRecentSearch: async function ({ commit }) {
    const recentSearchStr = this.$cpCookie.get('recent_search')
    const recentSearch = recentSearchStr === undefined ? [] : recentSearchStr.split(';')
    if (recentSearch.length === 0) {
      return
    }
    let apiRes = ''
    try {
      const params = []
      recentSearch.forEach(function (item, index) {
        const arr = item.split('@')
        if (arr.length === 2) {
          params.push({ value: arr[0], level: arr[1] })
        }
      })
      apiRes = await this.$axios.post('member/recentsearch/save', { list: params })
    } catch (err) {
      apiRes = err.response
    } finally {
      console.log(apiRes) // Could be success or error
    }
    // commit('setRecentSearch', data) // commit = call mutations
  },
  saveBookmark: async function ({ commit, state }, params) {
    await this.$axios.post('member/bookmark/save', params)
    this.$auth.fetchUser()
  },
  unsaveBookmark: async function ({ commit, state }, params) {
    await await this.$axios.delete('member/bookmark/unsave/' + params)
    this.$auth.fetchUser()
  }
}
