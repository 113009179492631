export default function(ctx) {
  // http://localhost:3000/hk/switch-lang/zhhk/job/educational-assistant,-year-2-bradbury-school-3836796
  const { redirect, req, app } = ctx
  const url = req.url
  const len = url.length
  const urlSp = url.split('/')
  const langcode = urlSp[2] // lang code
  const startForm = urlSp[3] // should except /switch-lang/:lang/
  const res = '/' + url.substring(url.indexOf(startForm), len)
  app.$cpCookie.set('revampLang', langcode)
  return redirect(res)
}
