import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _eb703982 = () => interopDefault(import('../pages/500error.vue' /* webpackChunkName: "pages/500error" */))
const _a666199e = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _ec71831a = () => interopDefault(import('../pages/freelance.vue' /* webpackChunkName: "pages/freelance" */))
const _6a66593e = () => interopDefault(import('../pages/job.vue' /* webpackChunkName: "pages/job" */))
const _57fc7260 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _88964470 = () => interopDefault(import('../pages/loginErr.vue' /* webpackChunkName: "pages/loginErr" */))
const _1a3aaa19 = () => interopDefault(import('../pages/logout/index.vue' /* webpackChunkName: "pages/logout/index" */))
const _00fcb810 = () => interopDefault(import('../pages/myjob/index.vue' /* webpackChunkName: "pages/myjob/index" */))
const _710b6ae4 = () => interopDefault(import('../pages/myprofile/index.vue' /* webpackChunkName: "pages/myprofile/index" */))
const _38eb577b = () => interopDefault(import('../pages/notFound.vue' /* webpackChunkName: "pages/notFound" */))
const _2bf8d1ba = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _37fcf766 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _3bb82a72 = () => interopDefault(import('../pages/privacy-policy/advertiser.vue' /* webpackChunkName: "pages/privacy-policy/advertiser" */))
const _20774788 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _1462a90e = () => interopDefault(import('../pages/selectlang/index.vue' /* webpackChunkName: "pages/selectlang/index" */))
const _518b8162 = () => interopDefault(import('../pages/site-map.vue' /* webpackChunkName: "pages/site-map" */))
const _07d87864 = () => interopDefault(import('../pages/switchLang.vue' /* webpackChunkName: "pages/switchLang" */))
const _3a10b14d = () => interopDefault(import('../pages/terms-conditions/index.vue' /* webpackChunkName: "pages/terms-conditions/index" */))
const _411d3c8a = () => interopDefault(import('../pages/debug/androidFunc.vue' /* webpackChunkName: "pages/debug/androidFunc" */))
const _060a3282 = () => interopDefault(import('../pages/debug/api.vue' /* webpackChunkName: "pages/debug/api" */))
const _a7f76036 = () => interopDefault(import('../pages/debug/component.vue' /* webpackChunkName: "pages/debug/component" */))
const _6cdd91e3 = () => interopDefault(import('../pages/debug/jobList.vue' /* webpackChunkName: "pages/debug/jobList" */))
const _607e6158 = () => interopDefault(import('../pages/debug/paging.vue' /* webpackChunkName: "pages/debug/paging" */))
const _55bbfccc = () => interopDefault(import('../pages/debug/ui-component.vue' /* webpackChunkName: "pages/debug/ui-component" */))
const _22c00fe5 = () => interopDefault(import('../pages/login/activate.vue' /* webpackChunkName: "pages/login/activate" */))
const _16703758 = () => interopDefault(import('../pages/login/forgot/index.vue' /* webpackChunkName: "pages/login/forgot/index" */))
const _13f224e8 = () => interopDefault(import('../pages/login/resetpassword.vue' /* webpackChunkName: "pages/login/resetpassword" */))
const _bfb9b02c = () => interopDefault(import('../pages/myjob/appliedjob.vue' /* webpackChunkName: "pages/myjob/appliedjob" */))
const _4df85dfb = () => interopDefault(import('../pages/myjob/follow.vue' /* webpackChunkName: "pages/myjob/follow" */))
const _8d1a063a = () => interopDefault(import('../pages/myjob/invitation.vue' /* webpackChunkName: "pages/myjob/invitation" */))
const _6a49e289 = () => interopDefault(import('../pages/myjob/jobalert.vue' /* webpackChunkName: "pages/myjob/jobalert" */))
const _95a1e1c0 = () => interopDefault(import('../pages/myjob/savedjob.vue' /* webpackChunkName: "pages/myjob/savedjob" */))
const _da1cd9dc = () => interopDefault(import('../pages/myprofile/export.vue' /* webpackChunkName: "pages/myprofile/export" */))
const _43742ae2 = () => interopDefault(import('../pages/myprofile/setting.vue' /* webpackChunkName: "pages/myprofile/setting" */))
const _019bd272 = () => interopDefault(import('../pages/position/search.vue' /* webpackChunkName: "pages/position/search" */))
const _e035d360 = () => interopDefault(import('../pages/registration/activation.vue' /* webpackChunkName: "pages/registration/activation" */))
const _8399956a = () => interopDefault(import('../pages/social/callback.vue' /* webpackChunkName: "pages/social/callback" */))
const _6eab4940 = () => interopDefault(import('../pages/terms-conditions/advertiser.vue' /* webpackChunkName: "pages/terms-conditions/advertiser" */))
const _02f39c3e = () => interopDefault(import('../pages/blog/cate/_slug/index.vue' /* webpackChunkName: "pages/blog/cate/_slug/index" */))
const _713b09c2 = () => interopDefault(import('../pages/blog/page/_page.vue' /* webpackChunkName: "pages/blog/page/_page" */))
const _0a4210e2 = () => interopDefault(import('../pages/blog/post/_slug/index.vue' /* webpackChunkName: "pages/blog/post/_slug/index" */))
const _1ae966bd = () => interopDefault(import('../pages/login/backend/_id.vue' /* webpackChunkName: "pages/login/backend/_id" */))
const _3e0d82c0 = () => interopDefault(import('../pages/myjob/applicationsubmitted/_id.vue' /* webpackChunkName: "pages/myjob/applicationsubmitted/_id" */))
const _6ead9782 = () => interopDefault(import('../pages/myjob/apply/_id.vue' /* webpackChunkName: "pages/myjob/apply/_id" */))
const _439723de = () => interopDefault(import('../pages/myjob/nonmemberapplyreg/_id.vue' /* webpackChunkName: "pages/myjob/nonmemberapplyreg/_id" */))
const _7f945dce = () => interopDefault(import('../pages/position/preview/_id.vue' /* webpackChunkName: "pages/position/preview/_id" */))
const _16631af6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _120ee236 = () => interopDefault(import('../pages/position/search.vue' /* webpackChunkName: "" */))
const _3da26454 = () => interopDefault(import('../pages/job.vue' /* webpackChunkName: "" */))
const _4b3dfb3a = () => interopDefault(import('../pages/switchLang' /* webpackChunkName: "" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/500error",
    component: _eb703982,
    name: "500error"
  }, {
    path: "/blog",
    component: _a666199e,
    name: "blog"
  }, {
    path: "/freelance",
    component: _ec71831a,
    name: "freelance"
  }, {
    path: "/job",
    component: _6a66593e,
    name: "job"
  }, {
    path: "/login",
    component: _57fc7260,
    name: "login"
  }, {
    path: "/loginErr",
    component: _88964470,
    name: "loginErr"
  }, {
    path: "/logout",
    component: _1a3aaa19,
    name: "logout"
  }, {
    path: "/myjob",
    component: _00fcb810,
    name: "myjob"
  }, {
    path: "/myprofile",
    component: _710b6ae4,
    name: "myprofile"
  }, {
    path: "/notFound",
    component: _38eb577b,
    name: "notFound"
  }, {
    path: "/privacy-policy",
    component: _2bf8d1ba,
    children: [{
      path: "",
      component: _37fcf766,
      name: "privacy-policy"
    }, {
      path: "advertiser",
      component: _3bb82a72,
      name: "privacy-policy-advertiser"
    }]
  }, {
    path: "/registration",
    component: _20774788,
    name: "registration"
  }, {
    path: "/selectlang",
    component: _1462a90e,
    name: "selectlang"
  }, {
    path: "/site-map",
    component: _518b8162,
    name: "site-map"
  }, {
    path: "/switchLang",
    component: _07d87864,
    name: "switchLang"
  }, {
    path: "/terms-conditions",
    component: _3a10b14d,
    name: "terms-conditions"
  }, {
    path: "/debug/androidFunc",
    component: _411d3c8a,
    name: "debug-androidFunc"
  }, {
    path: "/debug/api",
    component: _060a3282,
    name: "debug-api"
  }, {
    path: "/debug/component",
    component: _a7f76036,
    name: "debug-component"
  }, {
    path: "/debug/jobList",
    component: _6cdd91e3,
    name: "debug-jobList"
  }, {
    path: "/debug/paging",
    component: _607e6158,
    name: "debug-paging"
  }, {
    path: "/debug/ui-component",
    component: _55bbfccc,
    name: "debug-ui-component"
  }, {
    path: "/login/activate",
    component: _22c00fe5,
    name: "login-activate"
  }, {
    path: "/login/forgot",
    component: _16703758,
    name: "login-forgot"
  }, {
    path: "/login/resetpassword",
    component: _13f224e8,
    name: "login-resetpassword"
  }, {
    path: "/myjob/appliedjob",
    component: _bfb9b02c,
    name: "myjob-appliedjob"
  }, {
    path: "/myjob/follow",
    component: _4df85dfb,
    name: "myjob-follow"
  }, {
    path: "/myjob/invitation",
    component: _8d1a063a,
    name: "myjob-invitation"
  }, {
    path: "/myjob/jobalert",
    component: _6a49e289,
    name: "myjob-jobalert"
  }, {
    path: "/myjob/savedjob",
    component: _95a1e1c0,
    name: "myjob-savedjob"
  }, {
    path: "/myprofile/export",
    component: _da1cd9dc,
    name: "myprofile-export"
  }, {
    path: "/myprofile/setting",
    component: _43742ae2,
    name: "myprofile-setting"
  }, {
    path: "/position/search",
    component: _019bd272,
    name: "position-search"
  }, {
    path: "/registration/activation",
    component: _e035d360,
    name: "registration-activation"
  }, {
    path: "/social/callback",
    component: _8399956a,
    name: "social-callback"
  }, {
    path: "/terms-conditions/advertiser",
    component: _6eab4940,
    name: "terms-conditions-advertiser"
  }, {
    path: "/blog/cate/:slug",
    component: _02f39c3e,
    name: "blog-cate-slug"
  }, {
    path: "/blog/page/:page?",
    component: _713b09c2,
    name: "blog-page-page"
  }, {
    path: "/blog/post/:slug",
    component: _0a4210e2,
    name: "blog-post-slug"
  }, {
    path: "/login/backend/:id?",
    component: _1ae966bd,
    name: "login-backend-id"
  }, {
    path: "/myjob/applicationsubmitted/:id?",
    component: _3e0d82c0,
    name: "myjob-applicationsubmitted-id"
  }, {
    path: "/myjob/apply/:id?",
    component: _6ead9782,
    name: "myjob-apply-id"
  }, {
    path: "/myjob/nonmemberapplyreg/:id?",
    component: _439723de,
    name: "myjob-nonmemberapplyreg-id"
  }, {
    path: "/position/preview/:id?",
    component: _7f945dce,
    name: "position-preview-id"
  }, {
    path: "/",
    component: _16631af6,
    name: "index"
  }, {
    path: "/position/search",
    component: _120ee236,
    alias: "/industry/:industry-jobs/:page",
    name: "industry"
  }, {
    path: "/position/search",
    component: _120ee236,
    alias: "/search-:keyword-jobs/:page",
    name: "jobsearch"
  }, {
    path: "/position/search",
    component: _120ee236,
    alias: "/company-:keyword-jobs/:page",
    name: "companysearch"
  }, {
    path: "/position/search",
    component: _120ee236,
    alias: ["/jobs/:field/:subfield/:page","/jobs/:field/:page"],
    name: "jobfunction"
  }, {
    path: "/position/search",
    component: _120ee236,
    alias: "/location/:location-jobs/:page",
    name: "location"
  }, {
    path: "/position/search",
    component: _120ee236,
    alias: "/work-type/:worktype-jobs/:page",
    name: "worktype"
  }, {
    path: "/position/search",
    component: _120ee236,
    alias: "/campaign/:campaign",
    name: "campaign"
  }, {
    path: "/position/search",
    component: _120ee236,
    alias: "/job-level/:joblevel-level-jobs/:page",
    name: "joblevel"
  }, {
    path: "/position/search",
    component: _120ee236,
    alias: "/SearchJobs",
    name: "searchjobs"
  }, {
    path: "/position/search",
    component: _120ee236,
    alias: "/SearchJobs/:page",
    name: "searchjobs-page"
  }, {
    path: "/job/:id",
    component: _3da26454,
    name: "job-page"
  }, {
    path: "/job/preview/:id",
    component: _3da26454,
    name: "job-preview-page"
  }, {
    path: "/switchlang/*",
    component: _4b3dfb3a,
    name: "switchlang"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
